.input__group--search {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input--search {
  outline: none;

  font-weight: normal;

  padding: 8px 16px;
  width: calc(100% - 44px);

  background-color: color-for('search-input-background', $theme-colors);

  border: 1px solid color-for('search-border', $theme-colors);
  border-right-width: 1px;
  border-radius: 4px 0 0 4px;

  -webkit-appearance: none;

  &:focus {
    border: 1px solid color-for('search-focus-border', $theme-colors);
    border-right-width: 0;
  }
}

//noinspection CssOptimizeSimilarProperties
.input--search-button {
  cursor: pointer;
  color: color-for('search-icon-color', $theme-colors);
  flex: 1 0 auto;
  background: color-for('search-input-background-button', $theme-colors);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 44px;

  width: 44px;

  border: 0;
  border-radius: 0 4px 0 4px;

  outline: none;

  padding: 0;

  &:hover {
    background: color-for('search-input-background-button-hover', $theme-colors);
  }
}
