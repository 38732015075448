@for $i from 1 through 12 {
  .text-column-#{$i} {
    column-count: $i;
  }
  .text-column-gap-#{$i} {
    column-gap: $i * 4px;
  }

  @each $key, $value in $grid-breakpoints {
    @media screen and (min-width: $value) {
      .text-column-#{$key}-#{$i} {
        column-count: $i;
      }
      .text-column-gap-#{$key}-#{$i} {
        column-gap: $i * 4px;
      }
    }
  }
}
