$font-path: '../fonts/';

//
// SDX Font Faces
// --------------------------------------------------

// TheSans Regular
// -------------------------

@font-face {
  font-family: 'TheSans';
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_200_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_200_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_200_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_200_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_200_.svg#TheSansB_200_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_300_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_300_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_300_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_300_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_300_.svg#TheSansB_300_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_400_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_400_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_400_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_400_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_400_.svg#TheSansB_400_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_500_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_500_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_500_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_500_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_500_.svg#TheSansB_500_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_600_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_600_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_600_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_600_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_600_.svg#TheSansB_600_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_700_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_700_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_700_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_700_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_700_.svg#TheSansB_700_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_800_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_800_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_800_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_800_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_800_.svg#TheSansB_800_') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}TheSans/TheSansB_900_.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_900_.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_900_.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_900_.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_900_.svg#TheSansB_900_') format('svg');
}

// TheSans Italic
// -------------------------

@font-face {
  font-family: 'TheSans';
  font-weight: 200;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_200i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_200i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_200i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_200i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_200i.svg#TheSansB_200i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_300i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_300i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_300i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_300i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_300i.svg#TheSansB_300i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 400;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_400i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_400i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_400i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_400i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_400i.svg#TheSansB_400i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_500i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_500i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_500i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_500i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_500i.svg#TheSansB_500i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 600;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_600i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_600i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_600i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_600i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_600i.svg#TheSansB_600i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 700;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_700i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_700i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_700i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_700i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_700i.svg#TheSansB_700i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 800;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_800i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_800i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_800i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_800i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_800i.svg#TheSansB_800i') format('svg');
}

@font-face {
  font-family: 'TheSans';
  font-weight: 900;
  font-style: italic;
  src: url('#{$font-path}TheSans/TheSansB_900i.eot') format('eot'), url('#{$font-path}TheSans/TheSansB_900i.woff2') format('woff2'),
    url('#{$font-path}TheSans/TheSansB_900i.woff') format('woff'), url('#{$font-path}TheSans/TheSansB_900i.ttf') format('truetype'),
    url('#{$font-path}TheSans/TheSansB_900i.svg#TheSansB_900i') format('svg');
}

// TheSerif Normal
// -------------------------

@font-face {
  font-family: 'TheSerif';
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_200_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_200_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_200_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_200_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_200_.svg#TheSerifB_200_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_300_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_300_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_300_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_300_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_300_.svg#TheSerifB_300_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_400_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_400_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_400_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_400_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_400_.svg#TheSerifB_400_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_500_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_500_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_500_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_500_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_500_.svg#TheSerifB_500_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_600_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_600_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_600_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_600_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_600_.svg#TheSerifB_600_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_700_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_700_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_700_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_700_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_700_.svg#TheSerifB_700_') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-path}TheSerif/TheSerifB_800_.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_800_.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_800_.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_800_.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_800_.svg#TheSerifB_800_') format('svg');
}

// TheSerif Italic
// -------------------------

@font-face {
  font-family: 'TheSerif';
  font-weight: 200;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_200i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_200i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_200i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_200i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_200i.svg#TheSerifB_200i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_300i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_300i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_300i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_300i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_300i.svg#TheSerifB_300i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 400;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_400i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_400i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_400i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_400i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_400i.svg#TheSerifB_400i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_500i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_500i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_500i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_500i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_500i.svg#TheSerifB_500i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 600;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_600i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_600i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_600i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_600i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_600i.svg#TheSerifB_600i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 700;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_700i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_700i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_700i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_700i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_700i.svg#TheSerifB_700i') format('svg');
}

@font-face {
  font-family: 'TheSerif';
  font-weight: 800;
  font-style: italic;
  src: url('#{$font-path}TheSerif/TheSerifB_800i.eot') format('eot'), url('#{$font-path}TheSerif/TheSerifB_800i.woff2') format('woff2'),
    url('#{$font-path}TheSerif/TheSerifB_800i.woff') format('woff'), url('#{$font-path}TheSerif/TheSerifB_800i.ttf') format('truetype'),
    url('#{$font-path}TheSerif/TheSerifB_800i.svg#TheSerifB_800i') format('svg');
}
