$signature: '.signature-wrapper';
$app-space-base: 35px;
$app-space-big: 75px;
$app-space-bigger: 100px;

#{$signature} {
  margin-bottom: 48px;

  img {
    width: auto;
    height: 60px;
    margin-bottom: 24px;
  }

  @include media-breakpoint-up('md') {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    & > .signature:nth-child(n + 2) {
      margin-left: $app-space-base;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 72px;

    img {
      height: 120px;
    }

    & > .signature:nth-child(n + 2) {
      margin-left: $app-space-big;
    }
  }

  @include media-breakpoint-up(xl) {
    & > .signature:nth-child(n + 2) {
      margin-left: $app-space-bigger;
    }
  }

  .signature p:nth-child(n + 1) {
    margin-bottom: 0;
  }
  .signature p:nth-child(n + 2) {
    margin-top: 0;
  }
}
