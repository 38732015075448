@import '../color-palette';

@mixin generate-swisscom-sharing() {
  h3.webui-popover-title {
    padding: 0px 14px !important;
    font-size: 1rem !important;

    color: $grey !important;
    border: 0;
  }

  .webui-popover {
    padding: 0.8rem 1.2rem 1.5rem 0.2rem;
    border-width: 2px 0 0 0 !important;
    border-style: solid;
    border-color: $blue-light;
    box-shadow: none;

    .social-media-sharing {
      border-radius: 3px !important;
      border: 2px solid $blue-light !important;
      color: $blue-light !important;
      &:hover {
        background: $blue-light !important;
        border: 2px solid $blue-light !important;
        i {
          color: $white !important;
        }
      }
    }
  }
}
