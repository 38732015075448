@import '../color-palette';

.override.reader__content__wrapper {
  *[data-glossary],
  *[data-open-html-element-id-in-overlay],
  *[data-attachment],
  *[data-attachment-shortlinkid] {
    border-bottom: 2px dotted $blue-accessible;
    font-size: inherit;
  }

  .gb-table {
    *[data-attachment],
    *[data-glossary],
    *[data-attachment-shortlinkid] {
      font-weight: 300;
    }
  }

  .external-link {
    border-bottom: 1px solid $blue-accessible;
    &::after {
      display: none;
    }
  }

  [data-internallink],
  [data-shortlinkid],
  .internal-link {
    &:hover {
      text-decoration: underline !important;
    }
  }
}
