@import '../color-palette';

$lead-padding: 30px !default;
$lead-margin: 0 0 40px 0 !default;
$lead-color: $blue-accessible !default;
$lead-font-size: 22px !default;
$lead-font-weight: 400 !default;
$lead-line-height: 1.2 !default;
$lead-wide-border: 0 !default;
$lead-font-style: normal !default;
$content-max-width: 1400px !default;

.reader-lead {
  margin: $lead-margin !important;

  color: $lead-color !important;

  font-size: $lead-font-size !important;
  font-weight: $lead-font-weight !important;
  line-height: $lead-line-height !important;
  font-style: $lead-font-style !important;
}

@media screen and (min-width: ($content-max-width + (4 * $lead-padding))) {
  .reader-lead {
    border-left: $lead-wide-border !important;
    padding-left: $lead-padding !important;
    margin-left: -$lead-padding !important;
  }
}

@mixin generate-quotes() {
  .container > p.swisscom-AB-zitat {
    @include f((margin-left, margin-right), 32px, 88px);
    @include f((margin-top, margin-bottom), 32px, 64);
    @include f((font-size), 24px, 48px);
  }
}
