.reader__navigation__menu {
  background: color-for('navigation-background', $theme-colors);
  z-index: 95;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 80px;
  }
}

.reader__navigation__menu a {
  text-decoration: none !important;
  overflow: auto;
}

.level {
  display: block;
  min-height: 56px;
  transition: all 0.25s ease-in-out;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid color-for('navigation-default-border-bottom', $theme-colors);

  & > :nth-child(n) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .text {
    padding-left: 24px;
    color: color-for('navigation-color', $theme-colors);
    flex: 1 1 100%;
    width: 100%;
  }

  .arrow {
    margin-left: 16px;
    color: color-for('navigation-arrow', $theme-colors);
    padding-right: 24px;
  }

  .color {
    // legacy element from swisscom < 2018
    display: none;
  }

  &.selected {
    background-color: color-for('navigation-selected-background', $theme-colors);

    .text {
      color: color-for('navigation-selected-color', $theme-colors) !important;
    }
  }

  &.expanded:not(.selected) {
    background-color: color-for('navigation-expanded-background', $theme-colors);

    .text {
      color: color-for('navigation-expanded-color', $theme-colors) !important;
    }
  }

  &:hover {
    background-color: color-for('navigation-hover-background', $theme-colors);

    .text {
      color: color-for('navigation-hover-color', $theme-colors) !important;
    }
  }
}

.submenu {
  display: none;

  &.expanded {
    display: block;
  }
}
