*[data-is-open-button-toggle] {
  cursor: pointer;
  user-select: none;

  .open {
    display: block;
  }
  .close {
    display: none;
  }

  .icon-font {
    transition: all 0.25s ease-in-out;
  }
  &.is-open {
    .icon-font {
      transform: rotate(180deg);
    }

    .open {
      display: none;
    }
    .close {
      display: block;
    }
  }
}
