@import '../../../../../node_modules/tablesaw/src/tables.stack-mixin';

@mixin table-generate-shadows($parent-class) {
  @media only screen and (max-width: 1024px) {
    #{$parent-class} {
      background:
            /* Shadow covers */ linear-gradient(90deg, white 30%, rgba(white, 0)),
        /* Shadows */ linear-gradient(90deg, rgba(0, 0, 0, 0.1) 30%, rgba(white, 0)),
        linear-gradient(90deg, rgba(white, 0), rgba(0, 0, 0, 0.1) 100%) 100% 0;
      background-repeat: no-repeat;
      background-color: white;

      background-size: 40px 100%, 6px 100%, 6px 100%;
      @include bp-xl {
        background-size: 40px 100%, 8px 100%, 8px 100%;
      }

      /* Opera doesn't support this in the shorthand */
      background-attachment: local, scroll, scroll;
      border-radius: 0.1px;
      user-select: none;
    }

    #{$parent-class} > *:first-of-type {
      background: linear-gradient(90deg, rgba(white, 0), white 50%) calc(100% + 2px) 0;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px 100%;

      /* Opera doesn't support this in the shorthand */
      background-attachment: local;
    }

    #{$parent-class} {
      tr:hover td {
        background: color-for('table-row-hover-background', $theme-colors) !important;
      }
    }

    #{$parent-class}:focus {
      outline: none;
    }
  }
}

.reader__body {
  .gb-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      min-width: var(--fixed-width, unset);
    }

    img {
      display: inline-block;
      margin: 0;
    }

    th {
      vertical-align: bottom;
      color: color-for('table-headerrow', $theme-colors);

      &.highlight {
        border-top: 1px solid color-for('table-highlight-border-top', $theme-colors);
      }
    }

    th,
    tr {
      &.line-dark {
        border-bottom: 2px solid color-for('table-linedark-border', $theme-colors);
      }

      &.line-medium {
        border-bottom: 1px solid color-for('table-linemedium-border', $theme-colors);
      }

      &.line-light {
        border-bottom: 1px solid color-for('table-linelight-border', $theme-colors);
      }

      &.line-thin {
        border-bottom: 1px solid color-for('table-linethin-border', $theme-colors);
      }

      &.reader-table-noline {
        border-bottom: 0 !important;
      }
    }

    tr {
      &.row-spacing {
        @media only screen and (min-width: $bp-tablesaw-stacked) {
          height: 12px;
          & > td {
            padding: 0 !important;
            > * {
              display: none !important;
            }
          }
        }
      }
      &.line-title {
        td {
          padding-top: 12px !important;
          padding-bottom: 3px !important;
          border-bottom: 1px solid color-for('table-linetitle-border', $theme-colors) !important;
        }
      }

      &.line-footnote {
        border-bottom: 1px solid color-for('table-linefootnote-border', $theme-colors);
      }
    }

    th,
    td {
      padding: 5px 10px 5px 4px;
      color: color-for('table-stackable-thtd-color', $theme-colors);

      &.superscript {
        padding: 5px;
      }

      &.reader-table-noline {
        border-bottom: 0 !important;
      }
    }

    .footnote {
      padding-top: 4px !important;
      padding-bottom: 0 !important;

      .tablesaw-cell-content {
        & > div {
          text-align: left;
        }
      }
    }

    .footnote-title {
      display: block;
    }

    tr {
      &.suppress {
        display: none !important;
      }

      td:first-child {
        text-align: left;
      }
    }

    .bold {
      font-weight: bold;
      color: color-for('table-entry-bold', $theme-colors);
    }

    .desc {
      vertical-align: top;
      color: color-for('table-description', $theme-colors);
    }

    .desc-light {
      color: color-for('table-desclight-color', $theme-colors);
      text-align: left;
    }

    .number {
      text-align: right;
      color: color-for('table-number-color', $theme-colors);
    }

    .icon {
      text-align: center;
      padding: 5px 10px;
    }

    .highlight {
      color: color-for('table-highlight-color', $theme-colors);
      background: color-for('table-highlight-background', $theme-colors);
      border-right: 2px solid color-for('table-highlight-border', $theme-colors);
      border-left: 2px solid color-for('table-highlight-border', $theme-colors);

      &.line-thin {
        border-right: 0;
        border-right: none;
      }
    }

    th,
    td {
      $first: 2;
      $last: 15;
      @for $i from $first through $last {
        &.highlight.highlight-span-#{$i} {
          $selector: '';

          @for $x from 1 through $i {
            &#{$selector} {
              @if $x > 1 {
                border-left-style: none;
              }
              @if $x < $i {
                border-right-style: none;
              }
            }

            $selector: append($selector, ' + * + *.highlight');
          }
        }
      }
    }

    @for $i from 1 through 100 {
      .width#{$i} {
        width: unquote('#{$i}%');
      }
    }

    .firstnote {
      padding-top: 10px;
    }
  }

  .gb-table.gb-table-fixed-width {
    table-layout: fixed;
    min-width: 0px !important;
    width: auto !important;

    & th,
    & td {
      word-break: break-word;
      width: auto;
      padding-right: 4px;
    }

    & .highlight {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: $bp-tablesaw-stacked - 1px) {
    .gb-table {
      .desc--unit,
      .cell--empty {
        display: none;
      }

      .line--title {
        .tablesaw-cell-label {
          display: none;
        }
      }
    }
  }

  @include table-generate-shadows('.scroller');

  @include table-generate-shadows('.stackable');

  .scroller {
    overflow-x: auto;
    margin-bottom: 30px;

    .gb-table {
      min-width: 768px;
      width: calc(100% - 2px);

      th.desc {
        text-align: left;
      }
    }
  }

  .scroller.stackable,
  .stackable.scroller {
    .gb-table {
      min-width: 0;
      @include bp-md {
        min-width: 768px;
      }
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .scroller {
      overflow-x: auto;
    }
  }

  @-moz-document url-prefix() {
    .scroller {
      overflow-x: auto;
    }
  }

  .stackable {
    @include tablesaw-stack($bp-tablesaw-stacked);

    margin-bottom: 30px;
    padding: 0 1px 0 1px;

    overflow-x: auto;

    .gb-table {
      td:not(.superscript),
      th:not(.superscript) {
        padding: 16px 8px;
      }

      .tablesaw-cell-content {
        vertical-align: baseline;
      }

      tr {
        td:first-child {
          padding-top: 12px;

          .tablesaw-cell-label {
            display: none;
          }

          .tablesaw-cell-content {
            width: 100%;
            max-width: 100%;
          }
        }

        td:last-child {
          padding-bottom: 12px;
        }
      }

      .superscript {
        display: none;
      }

      .line-title {
        td:not(:first-child) {
          display: none;
        }
      }

      .highlight {
        border-right: none;
        color: color-for('table-highlight-color', $theme-colors);
      }
    }
  }

  @media screen and (min-width: $bp-tablesaw-stacked) {
    .stackable {
      .gb-table {
        td:not(.superscript),
        th:not(.superscript) {
          padding: 5px 10px 5px 4px;
        }

        tr {
          td:first-child {
            padding-top: 16px;
          }

          td:last-child {
            padding-bottom: 16px;
          }
        }

        th.desc {
          text-align: left;
        }

        .superscript {
          display: table-cell;
        }

        .highlight {
          border-right: 2px solid color-for('table-highlight-border', $theme-colors);
          color: color-for('table-highlight-color', $theme-colors);
        }

        .line-title {
          td:not(:first-child) {
            display: table-cell;
          }
        }
      }
    }
  }
}

@at-root .no-touchevents .v-#{$design-version} {
  .reader__body {
    .gb-table {
      tr {
        &:hover {
          td {
            background-color: color-for('table-row-hover-background', $theme-colors);
            transition: background-color 100ms linear;
          }
        }
      }
    }
  }
}

.reader__body {
  .gb-table {
    @for $font-size from 11 through 18 {
      &.gb-table-fixed-font-#{$font-size} {
        th,
        td {
          font-size: #{$font-size}px;
          line-height: 1.2;
        }
      }
    }
  }
}

.reader__body {
  .gb-table {
    th,
    td {
      &.aligned-bottom {
        vertical-align: bottom;
      }
      &.aligned-middle {
        vertical-align: middle;
      }
      &.aligned-top {
        vertical-align: top;
      }
    }
  }
}
