@import 'version/components/text-selection';
@import 'version/components/tabcontrol';
@import 'version/components/tablesaw';
@import 'version/components/header';
@import 'version/components/helper_classes';
@import 'version/components/images';
@import 'version/components/inputs';
@import 'version/components/mobile-language-overlay';
@import 'version/components/links';
@import 'version/components/navigation';
@import 'version/components/reader-body';
@import 'version/components/order-form';
@import 'version/components/overlay';
@import 'version/components/reader-tabs';
@import 'version/components/sharing-tools';
@import 'version/components/report-switcher';
@import 'version/components/search';
@import 'version/components/sidebar';
@import 'version/components/spinner';
@import 'version/components/tables';
@import 'version/components/dropdown';
@import 'version/components/utilities';
@import 'version/components/bg-box';
@import 'version/components/signature-wrapper';
@import 'version/components/version-number';
@import 'version/components/columns';
@import 'version/components/borders';
@import 'version/components/toggle-button';
@import 'version/components/medium-zoom';
@import 'version/components/carousel';
@import 'version/components/css-box';
@import 'version/components/placeholder-text';

@at-root {
  :root {
    @each $name, $color in $theme-colors {
      // @debug '#{$name}: #{$color}';
      #{"--#{$name}"}: #{$color};
    }
    @each $name, $color in $global-colors {
      // @debug '#{$name}: #{$color}';
      #{"--#{$name}"}: #{$color};
    }
  }
}
