@import '../color-palette';

.override.reader__content__wrapper,
.override.reader__overlay {
  .gb-table {
    th,
    tr {
      * {
        font-size: 16px;
        font-weight: 300;
      }

      sup,
      sub {
        font-size: 75%;
      }

      td:not(.footnote):not(.superscript) {
        padding: 5px 10px 4px 4px !important;
      }
      &.line-thin {
        border-bottom: 2px solid $grey-light;
      }
    }

    .superscript {
      padding: 5px 4px 4px;
    }

    .highlight {
      border-left: none !important;
      border-right: none !important;
    }

    .highlight,
    .bold {
      &,
      * {
        font-weight: bold;
      }
    }
    tr.line-title {
      td {
        padding-top: 24px !important;
      }
    }
  }

  th {
    text-align: revert;
  }

  .tablesaw {
    thead th[colspan] {
      text-align: revert;
    }
  }
  .stackable .gb-table .tablesaw-cell-content {
    vertical-align: bottom;
  }

  .stackable {
    overflow-y: hidden;

    @include media-breakpoint-up(md) {
      .gb-table tr td:last-child {
        padding-bottom: 4px;
      }
    }
  }

  .scroller {
    overflow-y: hidden;
  }
}

@mixin create-2023-table-highlights {
  .gb-table {
    :not(.highlight):has(+ .highlight) + .highlight {
      // first element of a group
      // border-left: 12px solid white !important;
      padding-left: 10px !important;
    }
  }
}

@media screen and (min-width: 1300px) {
  .scroller,
  .stackable {
    &.oversize {
      margin-right: -50px;
      // margin-left: -70px;
      // margin-right: -120px;
    }
  }
}

@media screen and (min-width: 1700px) {
  .scroller,
  .stackable {
    &.oversize {
      margin-right: -120px;
      // margin-right: -50px;
      // margin-left: -70px;
    }
  }
}
