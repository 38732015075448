.bg-box {
  background-color: color-for('bg-box-background', $theme-colors);

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  padding: 24px;
  @include media-breakpoint-up(lg) {
    padding: 32px;
  }
}
