@import '../color-palette';

.override.reader__navigation__menu {
  .level {
    padding-right: 6px;
    border-right: 1px solid $grey-another-one-13;
    border-bottom: 1px solid $grey-another-one-13;

    .text {
      &,
      span {
        font-size: 16px;
        font-weight: 300;
        justify-content: flex-start;
      }
    }
    .arrow {
      justify-content: flex-start;
    }

    &.level--1 {
      .arrow {
        font-size: 2rem;
      }

      &.level--1-1,
      &.level--1-2 {
        .arrow {
          color: $grey-another-one-12;
        }
        .text {
          color: $grey;
        }
      }
    }
    &.level--2 {
      .arrow {
        font-size: 2rem;
      }
      &.expanded {
        background-color: $grey-another-one-12;
      }
    }
    &.level--3 {
      font-size: 1.35rem;

      &.expanded {
        background-color: $grey-another-one-12;
      }
    }
    &.level--4 {
      font-size: 1.35rem;
    }
    &.level--5 {
      font-size: 1.35rem;
    }

    &:not(.level--1):not(.level--2).selected {
      background: $grey-another-one-12;
    }
  }

  .submenu--2 {
    padding: 0 0 20px 15px;

    .level .text {
      padding-left: 6px;
    }
  }

  .submenu--3 {
    &.expanded {
      background: $grey-another-one-14;
    }
  }
}
