$report-switcher--popup--padding: (
  md: 74px 120px 48px 120px,
) !default;

.report-switcher {
  // reset
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: color-for('report-switcher-li', $theme-colors);
      padding: 0;
      margin: 0;
      background: none;
    }
  }

  &__hint-text {
    padding: var(--report-switcher-hint-text-padding, 8px 10px);
    color: var(--report-switcher-hint-text-color, black);
  }

  .badge {
    margin: 0 20px;
    padding: 3px 8px;
    border-radius: 6px;

    font-size: 14px;
    color: color-for('report-switcher-badge', $theme-colors);

    &--violet {
      background-color: color-for('report-switcher-badge-background', $theme-colors);
    }
  }

  &__input {
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 280px;

    margin: 20px auto 0 auto;
    padding: 10px 20px;

    background: color-for('report-switcher-input-background', $theme-colors);
    border: 1px solid color-for('report-switcher-input-border', $theme-colors);

    color: color-for('report-switcher-input', $theme-colors);
    height: 44px;
    outline: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 8px;
    border-radius: 4px;

    &::-ms-expand {
      display: none;
    }

    @include bp-xl {
      display: none;
      margin-left: 10px;
      margin-right: 10px;
      width: auto;
    }

    &--show {
      @include bp-xl {
        display: flex;
      }
    }
  }

  &__popup-backdrop {
    display: none;
    width: 100%;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: color-for('modal-overlay-backdrop', $theme-colors);

    z-index: 1000;
  }

  &__popup {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: color-for('report-switcher-popup-background', $theme-colors);
    padding: 40px 0 0 0;
    @include multi-bp(padding, $report-switcher--popup--padding);
    z-index: 1001;

    grid-template-rows: auto 1fr auto;
    & > :nth-child(3) {
      align-self: flex-end;
    }

    @include bp-md {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      width: 100%;
      min-width: 700px;
      max-width: 700px;
    }
  }

  &--open {
    .report-switcher__popup,
    .report-switcher__popup-backdrop {
      display: block;
    }
  }

  &__popup-close {
    position: absolute;
    top: 20px;
    right: 20px;

    cursor: pointer;
    color: color-for('report-switcher-popup-close', $theme-colors);
    font-size: 24px;
  }

  .report-list {
    height: 100%;
    overflow: auto;

    @include bp-md {
      max-height: 45vh; // forces the scrollbar into the modal
    }

    li {
      .entry {
        display: block;
        color: color-for('report-switcher-popup-list-li', $theme-colors);
        text-decoration: none;
        padding: 8px 10px;

        &.entry-different-languages {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      .languages {
        margin: 0 2px;
      }

      .language-entry {
        color: color-for('report-switcher-popup-list-language', $theme-colors);
        text-decoration: none;
        padding: 0 2px;
      }

      &:hover {
        &,
        a {
          color: color-for('report-switcher-popup-list-li-hover', $theme-colors);
        }
        background-color: color-for('report-switcher-popup-list-li-hover-background', $theme-colors);
      }

      &.collapsable {
        &:hover {
          background-color: transparent;
        }

        & > .collapsable__title:hover {
          background-color: color-for('report-switcher-popup-list-li-hover-background', $theme-colors);
        }
      }
    }

    & > li {
      font-size: 16px;
      border-bottom: 1px solid color-for('report-switcher-popup-list-li-border-bottom', $theme-colors);

      & > a {
        color: color-for('report-switcher-popup-list-li-a', $theme-colors);
      }
    }
  }

  .collapsable {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      border-bottom: 1px solid color-for('report-switcher-popup-collapsable-title-border-bottom', $theme-colors);

      .icon-font {
        color: color-for('report-switcher-popup-collapsable-icon-font', $theme-colors);
      }
    }

    & > ul {
      display: none;
      border-top: none;
      margin: 0 0 32px 0;
    }

    & li {
      border-bottom: 1px solid color-for('report-switcher-popup-collapsable-li-border-bottom', $theme-colors);
      color: color-for('report-switcher-popup-collapsable-li-color', $theme-colors);
      padding: 8px 24px;

      &:last-child {
        border-bottom: none;
      }
    }

    &--open {
      & > .collapsable__title > .icon-font-expand {
        transform: rotate(180deg);
      }

      & > ul {
        display: block !important;
      }
    }

    .collapsable {
      padding-left: 0;
      padding-right: 0;

      & > .collapsable__title {
        padding-left: 8px;
        padding-right: 8px;
      }

      li a {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}
