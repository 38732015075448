$header-height: 80px !default;
$header--small-height: 60px !default;

.reader__header {
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid color-for('header-border-bottom', $global-colors);
  background: color-for('header-background', $global-colors);
  z-index: 91;

  height: $header-height;

  .left {
    grid-column: 1;
    height: 100%;

    .navigateToContentBox {
      &:focus {
        opacity: 1 !important;
      }
    }
  }

  .right {
    grid-column: 2;
    padding-right: 0;

    @include bp-md() {
      padding: 0px;
    }
  }

  &--with-tabs {
    @include bp-xl() {
      grid-template-columns: auto 1fr auto;

      .left {
        grid-column: 1;
      }

      .reader-tabs {
        grid-column: 2;
      }

      .right {
        grid-column: 3;
      }

      .reader-tabs,
      .reader-tabs__additional-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .left {
    justify-content: flex-start;

    @include bp-md() {
      width: 320px;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: color-for('header-button', $global-colors);

    height: 100%;

    @include bp-md {
      min-width: 100px;
    }

    padding-left: 16px;
    padding-right: 16px;

    text-decoration: none;
    cursor: pointer;
    user-select: none;

    .icon-font {
      font-size: 16px;
      width: 16px;
      height: 16px;
      display: inline-block;
    }

    p {
      color: color-for('header-button', $global-colors);
      margin: 10px 0 0 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 1;
      font-family: inherit;

      @include bp-md {
        font-size: 16px;
      }

      user-select: none;
    }
  }

  .button--menu,
  .button--close {
    background: color-for('header-button-background-menu-and-close', $global-colors);

    &,
    p {
      color: color-for('header-button-menu-and-close', $global-colors);
    }

    &:hover,
    &:active,
    &.active {
      background: color-for('header-button-active-background-menu-and-close', $global-colors);
    }

    box-shadow: none;
  }

  .button--menu {
    margin: 0 8px 0 0;

    @include bp-md {
      margin: 0 16px 0 0;
    }

    .icon-font {
      width: 16px;
      height: 16px;
      color: color-for('header-button-menu-icon', $global-colors);

      &::before {
        font-size: inherit;
      }
    }
  }

  .button--language,
  .button--downloads {
    position: relative;
    transition: background-color ease-in-out 0.15s;

    &:not(.active):hover {
      background-color: color-for('header-button-language-downloads-hover', $global-colors);
    }

    &:active {
      background-color: color-for('header-button-active-language-downloads-hover', $global-colors);
    }
  }

  .button--language {
    @include bp-md {
      display: flex;
    }

    .icon-font {
      color: color-for('header-button-language-icon', $global-colors);
    }
  }

  .button--close {
    .icon-font {
      color: color-for('header-button-close-icon', $global-colors);
    }
  }

  .button--downloads {
    .icon-font {
      color: color-for('header-button-downloads-icon', $global-colors);
    }
  }

  .logo-container {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      $width: 136px;

      width: $width * 0.5;
      height: 40px;

      background-image: url('../images/logo-2x.png');

      @include bp-md {
        width: $width * 0.75;
        height: 50px;
      }

      @include bp-lg {
        width: $width;
      }
    }
  }

  &.small {
    height: $header--small-height;

    @include bp-lg {
      height: $header-height;
    }

    @include bp-md {
      height: $header-height;
    }

    @include bp-max-md {
      .button {
        p {
          font-size: 12px;
          margin-top: 4px;
        }
      }

      .button--menu,
      .button--close {
        .icon-font {
          font-size: 16px;
          width: 16px;
          height: 16px;
        }
      }

      .button--downloads,
      .button--close,
      .button--language {
        min-width: auto;
        justify-content: center;

        p {
          display: none;
        }

        border-left: 1px solid color-for('header-small-button-downloads-close-language-border-left', $global-colors);

        @include bp-md {
          border-left: 0;
        }
      }
    }
  }
}