.reader__content {
  padding: 20px 20px 20px 44px;
  max-width: 1400px;

  @include media-breakpoint-up(md) {
    padding: 50px;
  }

  @media screen and (min-width: 1300px) {
    padding: 50px 80px;
    // padding: 50px 140px;
  }
  @media screen and (min-width: 1500px) {
    padding: 50px 200px;
  }
}

.container {
  padding: 1px 0;
  margin: 0 0 20px 0;
  max-width: unset;
}
