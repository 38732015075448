@import '../../../../node_modules/normalize.css/normalize.css';

html {
  height: 100vh;
}

body {
  overflow: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-focus-inner {
  border: 0;
}

.bold {
  font-weight: bold !important;
}

img {
  width: 100%;
  height: auto;
}
