@import '../../../../base/_mixins/breakpoints';

$dark-color: $materiality-color-orchid;
$light-color: $materiality-color-turquoise;
$text-color: #333333;
$link-color: #1781e3;

$circle-one: #0eaba9;
$circle-two: #0c847e;
$circle-three: #5944c6;
$circle-four: #42389e;

@mixin circle($color) {
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    margin: 0 auto;
    background-color: $color;

    @include bp(tablet_landscape) {
      display: none;
    }
  }
}

.essential-topics-grid {
  width: 100%;
  margin: 48px 0;

  display: grid;
  grid-template-columns: 40px 12px 1fr 8px 1fr;
  grid-template-rows: 1px 1fr 8px 1fr 12px 40px auto auto auto auto auto;

  @include bp(tablet_landscape) {
    grid-template-rows: 1px 1fr 8px 1fr 12px 40px auto;
  }

  ul {
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 0 !important;
    padding-left: 0;

    @include bp(tablet_landscape) {
      margin-left: 16px;
    }

    &,
    li {
      list-style-position: outside !important;
      list-style-type: disc !important;
      padding-left: 0;
      background: none !important;
    }
    a,
    a:visited,
    a:active,
    a:hover {
      color: $link-color !important;
    }
  }

  &-label-left,
  &-label-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  &-label-left {
    grid-row: 1/7;
    grid-column: 1/2;

    transform-origin: center;
    transform: rotate(-90deg);
    line-height: 0.8;

    width: 188px;
    padding-bottom: 160px;
    padding-left: 39px;
    @include bp(tablet_landscape) {
      width: auto;
      padding-bottom: 0;
      padding-left: 0;
      white-space: nowrap;
    }
  }

  &-label-bottom {
    grid-row: 6/7;
    grid-column: 1/6;
  }

  &-left-top-color,
  &-right-bottom-color {
    background-color: $light-color;
  }
  &-left-top-color {
    grid-row: 1/3;
    grid-column: 2/3;
  }
  &-right-bottom-color {
    grid-row: 5/6;
    grid-column: 5/6;
  }

  &-left-bottom-color,
  &-bottom-left-color {
    background-color: $dark-color;
  }
  &-left-bottom-color {
    grid-row: 4/5;
    grid-column: 2/3;
  }
  &-bottom-left-color {
    grid-row: 5/6;
    grid-column: 2/4;
  }

  &-vertical-spacing,
  &-horizontal-center-spacing,
  &-horizontal-top-spacing {
    background-color: transparent;
  }
  &-vertical-spacing {
    grid-row: 1/5;
    grid-column: 4/5;
    margin: 8px 0;
  }
  &-horizontal-center-spacing {
    grid-row: 3/4;
    grid-column: 3/6;
    margin: 0 8px;
  }
  &-horizontal-top-spacing {
    grid-row: 1/2;
    grid-column: 3/6;
    margin: 0 8px;
  }

  &-circle {
    padding: 48px 40px;
    grid-column: 1/2;

    @include bp(tablet_landscape) {
      display: none;
    }
  }

  &-circle-top-left {
    grid-row: 8/9;
    margin-top: 43px;
    padding: 0;
    @include circle($circle-one);
    &::before {
      margin-left: 0;
    }
  }
  &-circle-top-right {
    grid-row: 9/10;
    margin-top: 3px;
    padding: 0;
    @include circle($circle-two);
    &::before {
      margin-left: 0;
    }
  }
  &-circle-bottom-left {
    grid-row: 10/11;
    margin-top: 3px;
    padding: 0;
    @include circle($circle-three);
    &::before {
      margin-left: 0;
    }
  }
  &-circle-bottom-right {
    grid-row: 11/12;
    margin-top: 3px;
    padding: 0;
    @include circle($circle-four);
    &::before {
      margin-left: 0;
    }
  }

  &-top-left-circle {
    grid-column: 3/4;
    grid-row: 2/3;
    @include circle($circle-one);
    background-color: $materiality-color-turquoise-light;
  }
  &-top-right-circle {
    grid-column: 5/6;
    grid-row: 2/3;
    @include circle($circle-two);
    background-color: $materiality-color-turquoise-light;
  }
  &-bottom-left-circle {
    grid-column: 3/4;
    grid-row: 4/5;
    @include circle($circle-three);
    background-color: $materiality-color-orchid-light;
  }
  &-bottom-right-circle {
    grid-column: 5/6;
    grid-row: 4/5;
    @include circle($circle-four);
    background-color: $materiality-color-turquoise-light;
  }

  &-container {
    grid-column: 2/6;

    @include bp(tablet_landscape) {
      padding: 48px 40px;
    }
  }

  &-top-left-container {
    grid-row: 8/9;
    margin-top: 40px;
    
    @include bp(tablet_landscape) {
      background-color: $materiality-color-turquoise-light;
      margin-top: 0;

      grid-column: 3/4;
      grid-row: 2/3;
    }
  }
  &-top-right-container {
    grid-row: 9/10;
    
    @include bp(tablet_landscape) {
      background-color: $materiality-color-turquoise-light;
      grid-column: 5/6;
      grid-row: 2/3;
    }
  }
  &-bottom-left-container {
    grid-row: 10/11;
    
    @include bp(tablet_landscape) {
      background-color: $materiality-color-orchid-light;
      grid-column: 3/4;
      grid-row: 4/5;
    }
  }
  &-bottom-right-container {
    grid-row: 11/12;
    
    @include bp(tablet_landscape) {
      background-color: $materiality-color-turquoise-light;
      grid-column: 5/6;
      grid-row: 4/5;
    }
  }

  &-legend {
    grid-row: 7/8;
    grid-column: 2/6;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-top: 24px;

    @include bp(tablet_landscape) {
      grid-column: 1/6;
    }
  }

  &-legend-item {
    font-size: 16px;
    @include bp(tablet_landscape) {
      font-size: inherit;
    }

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 10px;
      margin-right: 16px;
    }

    margin-right: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-child::before {
      background-color: $dark-color;
    }

    &:last-child::before {
      background-color: $light-color;
    }
  }
}
