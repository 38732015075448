.reader__search__menu {
  padding: 16px 0;
  border-bottom: 1px solid color-for('search-border-bottom', $theme-colors);
  border-right: 1px solid color-for('search-border-right', $theme-colors);

  a {
    text-decoration: none;
  }

  .input__group--search {
    margin: 0 15px 5px;
  }

  .search-results-title {
    padding: 24px 16px;
    color: color-for('search-title-color', $theme-colors);

    h3 {
      margin: 0;
      text-align: center;
    }
  }

  .results {
    .result {
      padding: 16px;
      border-bottom: 1px solid color-for('search-result-border-bottom', $theme-colors);
      cursor: pointer;
      margin-left: 1px;
      word-break: break-word;
      margin-right: 1px;

      h4 {
        color: color-for('search-result-title', $theme-colors);
      }

      &:hover {
        transition: background 0.25s ease-in-out;
        background-color: color-for('search-result-hover-background', $theme-colors);
      }

      p {
        color: color-for('search-result-text', $theme-colors);
        margin-bottom: 0;
      }
    }
  }
}

.search-highlight {
  color: color-for('search-highlight', $theme-colors);
  padding: 0 3px;
  background: color-for('search-highlight-background', $theme-colors);
}
