$reader-tabs-align-self: flex-end !default;
$reader-tabs--tab--max-width: 200px !default;
$reader-tabs--tab--height: 50px !default;
$reader-tabs--border-width: 1px !default;
$reader-tabs--border-bottom-width: 1px !default;

.reader-tabs {
  display: none;
  align-self: $reader-tabs-align-self;
  overflow: visible;
  padding-left: 0;
  margin-left: -1px;
  margin-bottom: -1px;
  width: 100%;
  height: 100%;

  &__wrapper {
    display: inline-flex;
    flex-direction: row;
  }

  &__additional-buttons {
    display: none;
    flex-direction: row;
    align-items: flex-end;
    align-self: $reader-tabs-align-self;
  }

  &__init-render {
    visibility: hidden;
    position: fixed;
    top: -2000px;
    display: flex;
  }

  &__reports {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: $reader-tabs--tab--max-width;
    height: $reader-tabs--tab--height;

    background-color: color-for('reader-tabs-tab-background', $global-colors);
    padding: 0 20px;
    border: $reader-tabs--border-width solid color-for('reader-tabs-border-color', $global-colors);
    border-bottom: $reader-tabs--border-bottom-width solid color-for('reader-tabs-border-bottom-color', $global-colors);
    border-width: $reader-tabs--border-width 0 $reader-tabs--border-width $reader-tabs--border-width;

    cursor: pointer;
    text-decoration: none;

    color: color-for('reader-tabs-tab-color', $global-colors);

    &:hover {
      text-decoration: none;
      color: color-for('reader-tabs-tab-color', $global-colors);
    }

    & > .icon-font-content-close {
      display: flex;
      align-items: center;

      height: 100%;
      margin-right: -20px;
      padding: 0 10px;
      font-size: 12px;
    }

    & > span:first-of-type {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:nth-child(n + 2) {
      margin-left: 0;
    }

    &:last-child {
      border-right-width: 1px;
    }

    &:hover {
      background-color: color-for('reader-tabs-tab-background-hover', $global-colors);
    }

    &.selected {
      color: color-for('reader-tabs-tab-selected-color', $global-colors);
      background-color: color-for('reader-tabs-tab-background-selected', $global-colors);
      border-bottom: transparent;
      border-bottom: $reader-tabs--border-bottom-width solid color-for('reader-tabs-selected-border-bottom-color', $global-colors);
    }
  }

  .reader-tabs__additional-buttons {
    margin: 0;
    border-bottom: $reader-tabs--border-bottom-width solid transparent;

    .reader-tabs__tab {
      color: color-for('reader-tabs-add-new-color', $global-colors);
      font-weight: inherit;
    }

    .dropdown--show-all-tabs {
      top: 100%;
      left: 50%;
      padding: 0;

      width: 300px;
      margin-top: 18px;
      margin-left: 0;

      transform: translateX(-50%);

      a {
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
      }
    }

    .show-all-button {
      position: relative;
      color: color-for('reader-tabs-add-new-color', $global-colors);
    }

    .add-new-button {
      min-width: 150px;
      background-color: color-for('reader-tabs-add-new-background', $global-colors);
      border: 1px solid color-for('reader-tabs-add-new-border', $global-colors);
      border-bottom: color-for('reader-tabs-border-color', $global-colors);
      color: color-for('reader-tabs-add-new-color', $global-colors);
      white-space: nowrap;

      .icon-font {
        display: flex;
        justify-content: center;
        align-items: center;

        color: color-for('reader-tabs-add-new-color', $global-colors);
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;

        font-size: 14px;
      }
    }
  }
}
