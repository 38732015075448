.carousel-outer-container {
  margin: 0 auto;
  position: relative;
}

.carousel-arrow {
  display: var(--carousel-arrow-display, block);
  position: var(--carousel-arrow-position, absolute);
  cursor: pointer;
  z-index: 10;

  &.left {
    // top: var(--carousel-arrow-left-top, 50%);
    transform: var(--carousel-arrow-left-transform-mobile, unset);
    left: var(--carousel-arrow-left-left, 0);
    bottom: var(--carousel-arrow-left-bottom-mobile, 0);
  }
  &.right {
    // top: var(--carousel-arrow-right-top, 50%);
    transform: var(--carousel-arrow-right-transform-mobile, unset);
    right: var(--carousel-arrow-right-right, 0);
    bottom: var(--carousel-arrow-right-bottom-mobile, 0);
  }
  @media screen and (min-width: 1280px) {
    &.left {
      top: var(--carousel-arrow-left-top, 50%);
      transform: var(--carousel-arrow-left-transform, translate(-50%, -50%));
      left: var(--carousel-arrow-left-left, 0);
      bottom: var(--carousel-arrow-right-bottom, unset);
    }
    &.right {
      top: var(--carousel-arrow-right-top, 50%);
      transform: var(--carousel-arrow-right-transform, translate(50%, -50%));
      right: var(--carousel-arrow-right-right, 0);
      bottom: var(--carousel-arrow-right-bottom, unset);
    }
  }
}

.carousel-container {
  display: flex;
  align-items: var(--carousel-align, center);
  gap: var(--carousel-gap, 8px);
  max-height: var(--carousel-max-height, 400px);
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  container-type: inline-size;
  container-name: carousel;

  @media screen and (min-width: 1280px) {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
    }
  }
}

.carousel-container-image {
  display: block;
  object-fit: contain;
  width: auto;
  max-width: var(--carousel-img-max-width, 100%);
  height: 100%;
  max-height: var(--carousel-max-height, 400px);
  scroll-snap-align: var(--carousel-img-snap-align, start);
  scroll-snap-stop: var(--carousel-img-snap-stop, always);

  @container carousel (inline-size > 768px) {
    max-width: var(--carousel-img-max-width-container, 80cqw) !important;
  }
}

*[hidden] {
  display: none !important;
}
