$dropdown--show-arrow: false !default;

@at-root {
  @mixin drop-down($name, $background-color, $border-color, $link-color, $link-color-selected, $link-hover-background-color) {
    .#{$name} {
      display: none;
      margin: 10px 0 0 0;
      padding: 10px 0;
      position: absolute;
      width: 180px;
      z-index: 100000;

      left: 0;
      right: 0;
      top: 80px;

      border: 1px solid $border-color;
      border-width: 1px;

      background-color: $background-color;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);

      a {
        display: block;
        &,
        &:hover {
          text-decoration: none;
        }

        padding: 16px;

        color: $link-color;

        text-align: center;
        text-decoration: none;

        &:hover {
          background-color: $link-hover-background-color;
        }

        &.selected {
          position: relative;
        }
      }

      &.show {
        display: block;
      }

      @if ($dropdown--show-arrow) {
        &:after,
        &:before {
          position: absolute;

          content: ' ';
          line-height: 15px;

          bottom: 100%;
          left: 50%;

          width: 0;
          height: 0;

          border: solid transparent;

          pointer-events: none;
        }

        &:after {
          border-bottom-color: $background-color;
          border-width: 16px;
          margin-left: -16px;
        }

        &:before {
          border-bottom-color: $border-color;
          border-width: 18px;
          margin-left: -18px;
        }
      }
    }
  }

  @include drop-down(
    'dropdown',
    color-for('dropdown-background', $global-colors),
    color-for('dropdown-border', $global-colors),
    color-for('dropdown-link', $global-colors),
    color-for('dropdown-link--selected', $global-colors),
    color-for('dropdown-hover-link-background', $global-colors)
  );

  .dropdown--language {
    left: 50%;
    transform: translateX(-50%);
  }
}
