$reader--tools-enabled: true !default;
$reader--tools-width: 44px !default;
$reader--tools--share--button-right-spacer: 0px !default;
$reader--tools--share--box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15) !default;
$reader--tools--share-width: 304px !default;

@if ($reader--tools-enabled) {
  *[data-reader-social-media-enabled] {
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      text-align: center;
      cursor: pointer;
      content: $icon-font-share / "";
      font-family: '#{$icon-font}';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -#{$reader--tools-width + $reader--tools--share--button-right-spacer};
      font-size: 14px;
      width: $reader--tools-width;
      color: color-for('share-button-color', $theme-colors);

      &:hover {
        color: color-for('share-button-hover-color', $theme-colors);
      }
    }
  }

  .webui-popover {
    z-index: 95;
    box-shadow: $reader--tools--share--box-shadow;
    border: 1px solid color-for('share-border', $theme-colors);
    border-top: color-for('share-border-top', $theme-colors);
    padding: 0;
    background-color: 0;
    background: color-for('share-background', $theme-colors);
    border-radius: 0;

    // these three properties are only used because the calculation of the popover is wrong in case
    margin-top: 88px;
    margin-left: $reader--tools--share-width - $reader--tools-width + 8px;
    width: $reader--tools--share-width;

    .webui-arrow {
      display: none;
    }

    a::after {
      display: none !important;
    }

    .webui-popover-title,
    .webui-popover-content {
      background: color-for('share-background', $theme-colors);
      color: color-for('share-font-color', $theme-colors);
      border: 0;
    }

    .webui-popover-title {
      font-size: inherit;
      font-weight: bold;
    }

    .webui-popover-content {
      .reader-sharing-tools {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .social-media-sharing {
          outline: none;
          text-decoration: none;
          display: block;
          height: 44px;
          width: 44px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          border: 1px solid color-for('share-popover-border', $theme-colors);
          border-radius: 50%;

          transition: all 0.25s ease-out;

          &:hover {
            background: color-for('share-icon-icon-hover-background', $theme-colors);
            border-color: color-for('share-icon-icon-hover-border', $theme-colors);
          }

          &:active {
            border-color: color-for('share-icon-icon-active-border', $theme-colors);
            & > i {
              color: color-for('share-icon-icon-active-border', $theme-colors);
            }
          }

          &:not(:last-of-type) {
            margin: 0 20px 0 0;
          }

          & > i {
            font-size: 24px;
            color: color-for('share-icon', $theme-colors);

            &.icon-font-linkedin {
              margin: -2px 0 0 2px;
            }

            &.icon-font-facebook {
              margin: 0 0 0 -2px;
            }

            &.icon-font-twitter {
              margin: 2px 0 0 2px;
            }
          }
        }
      }
    }
  }
}
