.override.reader__content__wrapper {
  h1 {
    margin: 0 0 24px;
    @include media-breakpoint-up(md) {
      margin: 0 0 56px;
    }
    color: var(--heading-1);
  }
  h2 {
    margin: 0 0 32px;
    @include media-breakpoint-up(md) {
      margin: 0 0 48px;
    }
    color: var(--heading-2);
  }
  h3 {
    margin: 0 0 16px 0;
    @include media-breakpoint-up(md) {
      margin: 0 0 24px 0;
    }
    color: var(--heading-3);
  }
  h4 {
    margin: 0 0 8px 0;
    @include media-breakpoint-up(md) {
      margin: 0 0 8px 0;
    }
    color: var(--heading-4);
  }
  h5 {
    margin: 0 0 24px;
    @include media-breakpoint-up(md) {
      margin: 0 0 56px;
    }
    color: var(--heading-5);
  }
  h6 {
    margin: 0 0 24px;
    @include media-breakpoint-up(md) {
      margin: 0 0 56px;
    }
    color: var(--heading-6);
  }

  p {
    margin: 0 0 40px 0;
    @include media-breakpoint-up(md) {
      margin: 0 0 40px 0;
    }
  }
}

.override.reader__body {
  p {
    margin-bottom: 40px;
  }
}
