@import '../color-palette';

.override.input--search {
  border: 0.1rem solid $grey-another-one-3;
  border-radius: 0.4rem 0 0 0.4rem;
  padding: 1rem 1.2rem 0.8rem;

  &:focus {
    border: 0.1rem solid $blue-accessible;
  }
}

.override .input--search-button {
  border-radius: 0 0.4rem 0.4rem 0;
}
