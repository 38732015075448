@for $i from 1 through 12 {
  .border-#{$i} {
    border: #{$i * 1px} solid gray;
  }
  .border-t-#{$i} {
    border-top: #{$i * 1px} solid gray;
  }
  .border-r-#{$i} {
    border-right: #{$i * 1px} solid gray;
  }
  .border-b-#{$i} {
    border-bottom: #{$i * 1px} solid gray;
  }
  .border-l-#{$i} {
    border-left: #{$i * 1px} solid gray;
  }
  @each $key, $value in $grid-breakpoints {
    @media screen and (min-width: $value) {
      .border-#{$key}-#{$i} {
        border: #{$i * 1px} solid gray;
      }
      .border-t-#{$key}-#{$i} {
        border-top: #{$i * 1px} solid gray;
      }
      .border-r-#{$key}-#{$i} {
        border-right: #{$i * 1px} solid gray;
      }
      .border-b-#{$key}-#{$i} {
        border-bottom: #{$i * 1px} solid gray;
      }
      .border-l-#{$key}-#{$i} {
        border-left: #{$i * 1px} solid gray;
      }
    }
  }
}
