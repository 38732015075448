@charset "UTF-8";
/*
 |--------------------------------------------------------------------------
 |  Fluid Type
 |--------------------------------------------------------------------------
 */
/**
    More Infos: http://madebymike.com.au/writing/fluid-type-calc-examples/

    Single property
    html {
      @include fluid(14px, 18px, font-size);
    }

    Multiple properties with same values
    h1 {
      @include fluid(20px, 100px, padding-bottom padding-top);
    }
 */
@import '../../../../node_modules/normalize.css/normalize.css';
.red {
  color: #d82236 !important;
}

.blue {
  color: #001155 !important;
}

.blue-dark {
  color: #015 !important;
}

.blue-1 {
  color: #1f4485 !important;
}

.blue-1-5 {
  color: #19376b !important;
}

.blue-2 {
  color: #002a74 !important;
}

.blue-2-5 {
  color: #1b438d !important;
}

.blue-3 {
  color: #1a171b !important;
}

.blue-light {
  color: #1781e3 !important;
}

.blue-light-1 {
  color: #268def !important;
}

.blue-light-2 {
  color: #00a3fd !important;
}

.blue-light-2-2 {
  color: #dde3e7 !important;
}

.blue-light-2-5 {
  color: #d1e6f9 !important;
}

.blue-light-3 {
  color: #bcf0ff !important;
}

.blue-light-3-5 {
  color: #a2cdf4 !important;
}

.blue-light-4 {
  color: #0082ea !important;
}

.blue-light-5 {
  color: #0050e2 !important;
}

.blue-ultra-light {
  color: #cceeff !important;
}

.blue-accessible {
  color: #086adb !important;
}

.grey {
  color: #333333 !important;
}

.grey-light {
  color: #6c6c6c !important;
}

.grey-another-one {
  color: #999999 !important;
}

.grey-medium {
  color: #bbbbbb !important;
}

.grey-dark {
  color: #1a171b !important;
}

.grey-ultra-light {
  color: #e1e2e3 !important;
}

.grey-ultra-light-2 {
  color: #f3f3f3 !important;
}

.grey-another-one-2 {
  color: #666666 !important;
}

.grey-another-one-3 {
  color: #d6d6d6 !important;
}

.grey-another-one-4 {
  color: #f0f0f0 !important;
}

.grey-another-one-5 {
  color: #f5f5f5 !important;
}

.grey-another-one-6 {
  color: #666666 !important;
}

.grey-another-one-7 {
  color: #f1f1f1 !important;
}

.grey-another-one-8 {
  color: #d7d7d7 !important;
}

.grey-another-one-9 {
  color: #edf3f6 !important;
}

.grey-another-one-10 {
  color: #dce3e7 !important;
}

.grey-another-one-11 {
  color: #cfd5d9 !important;
}

.grey-another-one-12 {
  color: #eef3f6 !important;
}

.grey-another-one-13 {
  color: #e4e9ec !important;
}

.grey-another-one-14 {
  color: #f8fafb !important;
}

.white {
  color: #ffffff !important;
}

.black {
  color: #000000 !important;
}

.hover-color {
  color: #f5f5f5 !important;
}

.active-color {
  color: #eef3f6 !important;
}

.kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}

.kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}

.kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}

.kpis-card-separator-color {
  color: #000000 !important;
}

.kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}

.kpis-background-color-hove {
  color: #0082ea !important;
}

.materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}

.materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}

.materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}

.materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}

html {
  height: 100vh;
}

body {
  overflow: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-focus-inner {
  border: 0;
}

.bold {
  font-weight: bold !important;
}

img {
  width: 100%;
  height: auto;
}

@font-face {
  font-family: "icon-font";
  src: url("../fonts//icon-font.woff2?1b1q3y") format("woff2"), url("../fonts//icon-font.ttf?1b1q3y") format("truetype"), url("../fonts//icon-font.woff?1b1q3y") format("woff"), url("../fonts//icon-font.svg?1b1q3y#icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font-home,
.icon-font-content-close,
.icon-font-content {
  font-family: "icon-font";
}

.icon-font-home:before {
  content: "h";
}

.icon-font-content-close:before {
  content: "z";
}

.icon-font-content:before {
  content: "b";
}

.icon-font-share:before {
  content: "8";
}

.icon-font-download:before {
  content: "d";
}

.icon-font-add:before {
  content: "+";
}

.icon-font-navigation:before {
  content: "n";
}

.icon-font-navigation-close:before {
  content: "w";
}

.icon-font-close:before {
  content: "x";
}

.icon-font-calendar:before {
  content: "c";
}

.icon-font-checkmark:before {
  content: "a";
}

.icon-font-dropdown:before {
  content: "j";
}

.icon-font-language:before {
  content: "k";
}

.icon-font-mail:before {
  content: "m";
}

.icon-font-play:before {
  content: "p";
}

.icon-font-previous-chevron:before {
  content: "<";
}

.icon-font-next-chevron:before {
  content: ">";
}

.icon-font-left:before {
  content: "o";
}

.icon-font-right:before {
  content: "q";
}

.icon-font-expand:before {
  content: "r";
}

.icon-font-collapse:before {
  content: "u";
}

.icon-font-search:before {
  content: "s";
}

.icon-font-search-line:before {
  content: "e";
}

.icon-font-twitter:before {
  content: "t";
}

.icon-font-xing:before {
  content: "v";
}

.icon-font-youtube:before {
  content: "y";
}

.icon-font-instagram:before {
  content: "i";
}

.icon-font-linkedin:before {
  content: "l";
}

.icon-font-facebook:before {
  content: "f";
}

.icon-font-googleplus:before {
  content: "g";
}

.icon-font-document:before {
  content: "-";
}

.icon-font-link-ext:before {
  content: "1";
}

.icon-font-link-int:before {
  content: "2";
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.v-v1 {
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  /* rtl:begin:remove */
  /* rtl:end:remove */
  /**
    Generates base abstract style classes that can be extended.
    */
  /* Tablesaw Sass Mixins */
  /* reader 2018 */
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
@font-face {
  font-family: "TheSans";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_200_.eot") format("eot"), url("../fonts/TheSans/TheSansB_200_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_200_.woff") format("woff"), url("../fonts/TheSans/TheSansB_200_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_200_.svg#TheSansB_200_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_300_.eot") format("eot"), url("../fonts/TheSans/TheSansB_300_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_300_.woff") format("woff"), url("../fonts/TheSans/TheSansB_300_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_300_.svg#TheSansB_300_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_400_.eot") format("eot"), url("../fonts/TheSans/TheSansB_400_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_400_.woff") format("woff"), url("../fonts/TheSans/TheSansB_400_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_400_.svg#TheSansB_400_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_500_.eot") format("eot"), url("../fonts/TheSans/TheSansB_500_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_500_.woff") format("woff"), url("../fonts/TheSans/TheSansB_500_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_500_.svg#TheSansB_500_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_600_.eot") format("eot"), url("../fonts/TheSans/TheSansB_600_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_600_.woff") format("woff"), url("../fonts/TheSans/TheSansB_600_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_600_.svg#TheSansB_600_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_700_.eot") format("eot"), url("../fonts/TheSans/TheSansB_700_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_700_.woff") format("woff"), url("../fonts/TheSans/TheSansB_700_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_700_.svg#TheSansB_700_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_800_.eot") format("eot"), url("../fonts/TheSans/TheSansB_800_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_800_.woff") format("woff"), url("../fonts/TheSans/TheSansB_800_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_800_.svg#TheSansB_800_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/TheSans/TheSansB_900_.eot") format("eot"), url("../fonts/TheSans/TheSansB_900_.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_900_.woff") format("woff"), url("../fonts/TheSans/TheSansB_900_.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_900_.svg#TheSansB_900_") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_200i.eot") format("eot"), url("../fonts/TheSans/TheSansB_200i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_200i.woff") format("woff"), url("../fonts/TheSans/TheSansB_200i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_200i.svg#TheSansB_200i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_300i.eot") format("eot"), url("../fonts/TheSans/TheSansB_300i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_300i.woff") format("woff"), url("../fonts/TheSans/TheSansB_300i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_300i.svg#TheSansB_300i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_400i.eot") format("eot"), url("../fonts/TheSans/TheSansB_400i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_400i.woff") format("woff"), url("../fonts/TheSans/TheSansB_400i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_400i.svg#TheSansB_400i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_500i.eot") format("eot"), url("../fonts/TheSans/TheSansB_500i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_500i.woff") format("woff"), url("../fonts/TheSans/TheSansB_500i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_500i.svg#TheSansB_500i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_600i.eot") format("eot"), url("../fonts/TheSans/TheSansB_600i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_600i.woff") format("woff"), url("../fonts/TheSans/TheSansB_600i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_600i.svg#TheSansB_600i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_700i.eot") format("eot"), url("../fonts/TheSans/TheSansB_700i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_700i.woff") format("woff"), url("../fonts/TheSans/TheSansB_700i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_700i.svg#TheSansB_700i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_800i.eot") format("eot"), url("../fonts/TheSans/TheSansB_800i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_800i.woff") format("woff"), url("../fonts/TheSans/TheSansB_800i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_800i.svg#TheSansB_800i") format("svg");
}
@font-face {
  font-family: "TheSans";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/TheSans/TheSansB_900i.eot") format("eot"), url("../fonts/TheSans/TheSansB_900i.woff2") format("woff2"), url("../fonts/TheSans/TheSansB_900i.woff") format("woff"), url("../fonts/TheSans/TheSansB_900i.ttf") format("truetype"), url("../fonts/TheSans/TheSansB_900i.svg#TheSansB_900i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_200_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_200_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_200_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_200_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_200_.svg#TheSerifB_200_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_300_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_300_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_300_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_300_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_300_.svg#TheSerifB_300_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_400_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_400_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_400_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_400_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_400_.svg#TheSerifB_400_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_500_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_500_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_500_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_500_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_500_.svg#TheSerifB_500_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_600_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_600_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_600_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_600_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_600_.svg#TheSerifB_600_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_700_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_700_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_700_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_700_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_700_.svg#TheSerifB_700_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/TheSerif/TheSerifB_800_.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_800_.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_800_.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_800_.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_800_.svg#TheSerifB_800_") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_200i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_200i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_200i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_200i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_200i.svg#TheSerifB_200i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_300i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_300i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_300i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_300i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_300i.svg#TheSerifB_300i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_400i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_400i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_400i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_400i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_400i.svg#TheSerifB_400i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_500i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_500i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_500i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_500i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_500i.svg#TheSerifB_500i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_600i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_600i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_600i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_600i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_600i.svg#TheSerifB_600i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_700i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_700i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_700i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_700i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_700i.svg#TheSerifB_700i") format("svg");
}
@font-face {
  font-family: "TheSerif";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/TheSerif/TheSerifB_800i.eot") format("eot"), url("../fonts/TheSerif/TheSerifB_800i.woff2") format("woff2"), url("../fonts/TheSerif/TheSerifB_800i.woff") format("woff"), url("../fonts/TheSerif/TheSerifB_800i.ttf") format("truetype"), url("../fonts/TheSerif/TheSerifB_800i.svg#TheSerifB_800i") format("svg");
}
.v-v1 *,
.v-v1 *::before,
.v-v1 *::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  .v-v1 :root {
    scroll-behavior: smooth;
  }
}
.v-v1 body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.v-v1 hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}
.v-v1 h6, .v-v1 .h6, .v-v1 h5, .v-v1 .swisscom-about-box-subtitle, .v-v1 .swisscom-corporate-governance-name, .v-v1 .h5, .v-v1 h4, .v-v1 .h4, .v-v1 h3, .v-v1 .swisscom-about-box-title-1zeile,
.v-v1 .swisscom-about-box-title-2zeilen, .v-v1 .swisscom-about-value-navy-36pt,
.v-v1 .swisscom-about-value-navy-30pt,
.v-v1 .swisscom-about-value-grey-36pt,
.v-v1 .swisscom-about-value-grey-30pt,
.v-v1 .swisscom-about-value-grey-29pt,
.v-v1 .swisscom-about-value-navy-25pt,
.v-v1 .swisscom-about-value-grey-25pt,
.v-v1 .swisscom-about-value-navy-21pt,
.v-v1 .swisscom-about-value-navy-30pt_small,
.v-v1 .swisscom-about-value, .v-v1 .h3, .v-v1 h2, .v-v1 .h2, .v-v1 h1, .v-v1 .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}
.v-v1 h1, .v-v1 .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .v-v1 h1, .v-v1 .h1 {
    font-size: 2.5rem;
  }
}
.v-v1 h2, .v-v1 .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .v-v1 h2, .v-v1 .h2 {
    font-size: 2rem;
  }
}
.v-v1 h3, .v-v1 .swisscom-about-box-title-1zeile,
.v-v1 .swisscom-about-box-title-2zeilen, .v-v1 .swisscom-about-value-navy-36pt,
.v-v1 .swisscom-about-value-navy-30pt,
.v-v1 .swisscom-about-value-grey-36pt,
.v-v1 .swisscom-about-value-grey-30pt,
.v-v1 .swisscom-about-value-grey-29pt,
.v-v1 .swisscom-about-value-navy-25pt,
.v-v1 .swisscom-about-value-grey-25pt,
.v-v1 .swisscom-about-value-navy-21pt,
.v-v1 .swisscom-about-value-navy-30pt_small,
.v-v1 .swisscom-about-value, .v-v1 .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .v-v1 h3, .v-v1 .swisscom-about-box-title-1zeile,
  .v-v1 .swisscom-about-box-title-2zeilen, .v-v1 .swisscom-about-value-navy-36pt,
  .v-v1 .swisscom-about-value-navy-30pt,
  .v-v1 .swisscom-about-value-grey-36pt,
  .v-v1 .swisscom-about-value-grey-30pt,
  .v-v1 .swisscom-about-value-grey-29pt,
  .v-v1 .swisscom-about-value-navy-25pt,
  .v-v1 .swisscom-about-value-grey-25pt,
  .v-v1 .swisscom-about-value-navy-21pt,
  .v-v1 .swisscom-about-value-navy-30pt_small,
  .v-v1 .swisscom-about-value, .v-v1 .h3 {
    font-size: 1.75rem;
  }
}
.v-v1 h4, .v-v1 .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .v-v1 h4, .v-v1 .h4 {
    font-size: 1.5rem;
  }
}
.v-v1 h5, .v-v1 .swisscom-about-box-subtitle, .v-v1 .swisscom-corporate-governance-name, .v-v1 .h5 {
  font-size: 1.25rem;
}
.v-v1 h6, .v-v1 .h6 {
  font-size: 1rem;
}
.v-v1 p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.v-v1 abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
.v-v1 address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.v-v1 ol,
.v-v1 ul {
  padding-left: 2rem;
}
.v-v1 ol,
.v-v1 ul,
.v-v1 dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.v-v1 ol ol,
.v-v1 ul ul,
.v-v1 ol ul,
.v-v1 ul ol {
  margin-bottom: 0;
}
.v-v1 dt {
  font-weight: 700;
}
.v-v1 dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.v-v1 blockquote {
  margin: 0 0 1rem;
}
.v-v1 b,
.v-v1 strong {
  font-weight: bolder;
}
.v-v1 small, .v-v1 .small {
  font-size: 0.875em;
}
.v-v1 mark, .v-v1 .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}
.v-v1 sub,
.v-v1 sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.v-v1 sub {
  bottom: -0.25em;
}
.v-v1 sup {
  top: -0.5em;
}
.v-v1 a, .v-v1 *[data-shortlinkid],
.v-v1 *[data-shortlinkurl] {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
.v-v1 a:hover, .v-v1 [data-shortlinkid]:hover,
.v-v1 [data-shortlinkurl]:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}
.v-v1 a:not([href]):not([class]), .v-v1 [data-shortlinkid]:not([href]):not([class]),
.v-v1 [data-shortlinkurl]:not([href]):not([class]), .v-v1 a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.v-v1 pre,
.v-v1 code,
.v-v1 kbd,
.v-v1 samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
.v-v1 pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
.v-v1 pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.v-v1 code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > .v-v1 code, .v-v1 *[data-shortlinkid] > .v-v1 code,
.v-v1 *[data-shortlinkurl] > .v-v1 code {
  color: inherit;
}
.v-v1 kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
.v-v1 kbd kbd {
  padding: 0;
  font-size: 1em;
}
.v-v1 figure {
  margin: 0 0 1rem;
}
.v-v1 img,
.v-v1 svg {
  vertical-align: middle;
}
.v-v1 table {
  caption-side: bottom;
  border-collapse: collapse;
}
.v-v1 caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}
.v-v1 th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.v-v1 thead,
.v-v1 tbody,
.v-v1 tfoot,
.v-v1 tr,
.v-v1 td,
.v-v1 th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.v-v1 label {
  display: inline-block;
}
.v-v1 button {
  border-radius: 0;
}
.v-v1 button:focus:not(:focus-visible) {
  outline: 0;
}
.v-v1 input,
.v-v1 button,
.v-v1 select,
.v-v1 optgroup,
.v-v1 textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.v-v1 button,
.v-v1 select {
  text-transform: none;
}
.v-v1 [role=button] {
  cursor: pointer;
}
.v-v1 select {
  word-wrap: normal;
}
.v-v1 select:disabled {
  opacity: 1;
}
.v-v1 [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}
.v-v1 button,
.v-v1 [type=button],
.v-v1 [type=reset],
.v-v1 [type=submit] {
  -webkit-appearance: button;
}
.v-v1 button:not(:disabled),
.v-v1 [type=button]:not(:disabled),
.v-v1 [type=reset]:not(:disabled),
.v-v1 [type=submit]:not(:disabled) {
  cursor: pointer;
}
.v-v1 ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.v-v1 textarea {
  resize: vertical;
}
.v-v1 fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.v-v1 legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  .v-v1 legend {
    font-size: 1.5rem;
  }
}
.v-v1 legend + * {
  clear: left;
}
.v-v1 ::-webkit-datetime-edit-fields-wrapper,
.v-v1 ::-webkit-datetime-edit-text,
.v-v1 ::-webkit-datetime-edit-minute,
.v-v1 ::-webkit-datetime-edit-hour-field,
.v-v1 ::-webkit-datetime-edit-day-field,
.v-v1 ::-webkit-datetime-edit-month-field,
.v-v1 ::-webkit-datetime-edit-year-field {
  padding: 0;
}
.v-v1 ::-webkit-inner-spin-button {
  height: auto;
}
.v-v1 [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.v-v1 ::-webkit-search-decoration {
  -webkit-appearance: none;
}
.v-v1 ::-webkit-color-swatch-wrapper {
  padding: 0;
}
.v-v1 ::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
.v-v1 output {
  display: inline-block;
}
.v-v1 iframe {
  border: 0;
}
.v-v1 summary {
  display: list-item;
  cursor: pointer;
}
.v-v1 progress {
  vertical-align: baseline;
}
.v-v1 [hidden] {
  display: none !important;
}
.v-v1 .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.v-v1 .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-1 {
    font-size: 5rem;
  }
}
.v-v1 .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-2 {
    font-size: 4.5rem;
  }
}
.v-v1 .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-3 {
    font-size: 4rem;
  }
}
.v-v1 .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-4 {
    font-size: 3.5rem;
  }
}
.v-v1 .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-5 {
    font-size: 3rem;
  }
}
.v-v1 .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .v-v1 .display-6 {
    font-size: 2.5rem;
  }
}
.v-v1 .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.v-v1 .list-inline {
  padding-left: 0;
  list-style: none;
}
.v-v1 .list-inline-item {
  display: inline-block;
}
.v-v1 .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.v-v1 .initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.v-v1 .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.v-v1 .blockquote > :last-child {
  margin-bottom: 0;
}
.v-v1 .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.v-v1 .blockquote-footer::before {
  content: "— ";
}
.v-v1 .img-fluid {
  max-width: 100%;
  height: auto;
}
.v-v1 .img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}
.v-v1 .figure {
  display: inline-block;
}
.v-v1 .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.v-v1 .figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}
.v-v1 .container, .v-v1 .order-form .content,
.v-v1 .container-fluid,
.v-v1 .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .v-v1 .container-sm, .v-v1 .container, .v-v1 .order-form .content {
    max-width: 1400px;
  }
}
.v-v1 :root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}
.v-v1 .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.v-v1 .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.v-v1 .col {
  flex: 1 0 0%;
}
.v-v1 .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.v-v1 .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.v-v1 .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.v-v1 .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.v-v1 .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.v-v1 .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.v-v1 .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.v-v1 .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.v-v1 .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.v-v1 .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.v-v1 .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.v-v1 .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.v-v1 .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.v-v1 .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.v-v1 .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.v-v1 .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.v-v1 .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.v-v1 .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.v-v1 .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.v-v1 .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.v-v1 .offset-1 {
  margin-left: 8.33333333%;
}
.v-v1 .offset-2 {
  margin-left: 16.66666667%;
}
.v-v1 .offset-3 {
  margin-left: 25%;
}
.v-v1 .offset-4 {
  margin-left: 33.33333333%;
}
.v-v1 .offset-5 {
  margin-left: 41.66666667%;
}
.v-v1 .offset-6 {
  margin-left: 50%;
}
.v-v1 .offset-7 {
  margin-left: 58.33333333%;
}
.v-v1 .offset-8 {
  margin-left: 66.66666667%;
}
.v-v1 .offset-9 {
  margin-left: 75%;
}
.v-v1 .offset-10 {
  margin-left: 83.33333333%;
}
.v-v1 .offset-11 {
  margin-left: 91.66666667%;
}
.v-v1 .g-0,
.v-v1 .gx-0 {
  --bs-gutter-x: 0;
}
.v-v1 .g-0,
.v-v1 .gy-0 {
  --bs-gutter-y: 0;
}
.v-v1 .g-1,
.v-v1 .gx-1 {
  --bs-gutter-x: 0.25rem;
}
.v-v1 .g-1,
.v-v1 .gy-1 {
  --bs-gutter-y: 0.25rem;
}
.v-v1 .g-2,
.v-v1 .gx-2 {
  --bs-gutter-x: 0.5rem;
}
.v-v1 .g-2,
.v-v1 .gy-2 {
  --bs-gutter-y: 0.5rem;
}
.v-v1 .g-3,
.v-v1 .gx-3 {
  --bs-gutter-x: 1rem;
}
.v-v1 .g-3,
.v-v1 .gy-3 {
  --bs-gutter-y: 1rem;
}
.v-v1 .g-4,
.v-v1 .gx-4 {
  --bs-gutter-x: 1.5rem;
}
.v-v1 .g-4,
.v-v1 .gy-4 {
  --bs-gutter-y: 1.5rem;
}
.v-v1 .g-5,
.v-v1 .gx-5 {
  --bs-gutter-x: 3rem;
}
.v-v1 .g-5,
.v-v1 .gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .v-v1 .col-sm {
    flex: 1 0 0%;
  }
  .v-v1 .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .v-v1 .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .v-v1 .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .v-v1 .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .v-v1 .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .v-v1 .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .v-v1 .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .v-v1 .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .v-v1 .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .offset-sm-0 {
    margin-left: 0;
  }
  .v-v1 .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .v-v1 .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .v-v1 .offset-sm-3 {
    margin-left: 25%;
  }
  .v-v1 .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .v-v1 .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .v-v1 .offset-sm-6 {
    margin-left: 50%;
  }
  .v-v1 .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .v-v1 .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .v-v1 .offset-sm-9 {
    margin-left: 75%;
  }
  .v-v1 .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .v-v1 .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .v-v1 .g-sm-0,
  .v-v1 .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .v-v1 .g-sm-0,
  .v-v1 .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .v-v1 .g-sm-1,
  .v-v1 .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .v-v1 .g-sm-1,
  .v-v1 .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .v-v1 .g-sm-2,
  .v-v1 .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .v-v1 .g-sm-2,
  .v-v1 .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .v-v1 .g-sm-3,
  .v-v1 .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .v-v1 .g-sm-3,
  .v-v1 .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .v-v1 .g-sm-4,
  .v-v1 .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .v-v1 .g-sm-4,
  .v-v1 .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .v-v1 .g-sm-5,
  .v-v1 .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .v-v1 .g-sm-5,
  .v-v1 .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .v-v1 .col-md {
    flex: 1 0 0%;
  }
  .v-v1 .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .v-v1 .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .v-v1 .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .v-v1 .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .v-v1 .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .v-v1 .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .v-v1 .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .v-v1 .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .v-v1 .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .offset-md-0 {
    margin-left: 0;
  }
  .v-v1 .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .v-v1 .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .v-v1 .offset-md-3 {
    margin-left: 25%;
  }
  .v-v1 .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .v-v1 .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .v-v1 .offset-md-6 {
    margin-left: 50%;
  }
  .v-v1 .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .v-v1 .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .v-v1 .offset-md-9 {
    margin-left: 75%;
  }
  .v-v1 .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .v-v1 .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .v-v1 .g-md-0,
  .v-v1 .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .v-v1 .g-md-0,
  .v-v1 .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .v-v1 .g-md-1,
  .v-v1 .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .v-v1 .g-md-1,
  .v-v1 .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .v-v1 .g-md-2,
  .v-v1 .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .v-v1 .g-md-2,
  .v-v1 .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .v-v1 .g-md-3,
  .v-v1 .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .v-v1 .g-md-3,
  .v-v1 .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .v-v1 .g-md-4,
  .v-v1 .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .v-v1 .g-md-4,
  .v-v1 .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .v-v1 .g-md-5,
  .v-v1 .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .v-v1 .g-md-5,
  .v-v1 .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .v-v1 .col-lg {
    flex: 1 0 0%;
  }
  .v-v1 .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .v-v1 .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .v-v1 .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .v-v1 .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .v-v1 .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .v-v1 .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .v-v1 .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .v-v1 .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .v-v1 .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .offset-lg-0 {
    margin-left: 0;
  }
  .v-v1 .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .v-v1 .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .v-v1 .offset-lg-3 {
    margin-left: 25%;
  }
  .v-v1 .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .v-v1 .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .v-v1 .offset-lg-6 {
    margin-left: 50%;
  }
  .v-v1 .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .v-v1 .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .v-v1 .offset-lg-9 {
    margin-left: 75%;
  }
  .v-v1 .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .v-v1 .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .v-v1 .g-lg-0,
  .v-v1 .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .v-v1 .g-lg-0,
  .v-v1 .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .v-v1 .g-lg-1,
  .v-v1 .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .v-v1 .g-lg-1,
  .v-v1 .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .v-v1 .g-lg-2,
  .v-v1 .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .v-v1 .g-lg-2,
  .v-v1 .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .v-v1 .g-lg-3,
  .v-v1 .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .v-v1 .g-lg-3,
  .v-v1 .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .v-v1 .g-lg-4,
  .v-v1 .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .v-v1 .g-lg-4,
  .v-v1 .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .v-v1 .g-lg-5,
  .v-v1 .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .v-v1 .g-lg-5,
  .v-v1 .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .v-v1 .col-xl {
    flex: 1 0 0%;
  }
  .v-v1 .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .v-v1 .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .v-v1 .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .v-v1 .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .v-v1 .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .v-v1 .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .v-v1 .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .v-v1 .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .v-v1 .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .offset-xl-0 {
    margin-left: 0;
  }
  .v-v1 .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .v-v1 .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .v-v1 .offset-xl-3 {
    margin-left: 25%;
  }
  .v-v1 .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .v-v1 .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .v-v1 .offset-xl-6 {
    margin-left: 50%;
  }
  .v-v1 .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .v-v1 .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .v-v1 .offset-xl-9 {
    margin-left: 75%;
  }
  .v-v1 .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .v-v1 .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .v-v1 .g-xl-0,
  .v-v1 .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .v-v1 .g-xl-0,
  .v-v1 .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .v-v1 .g-xl-1,
  .v-v1 .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .v-v1 .g-xl-1,
  .v-v1 .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .v-v1 .g-xl-2,
  .v-v1 .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .v-v1 .g-xl-2,
  .v-v1 .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .v-v1 .g-xl-3,
  .v-v1 .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .v-v1 .g-xl-3,
  .v-v1 .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .v-v1 .g-xl-4,
  .v-v1 .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .v-v1 .g-xl-4,
  .v-v1 .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .v-v1 .g-xl-5,
  .v-v1 .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .v-v1 .g-xl-5,
  .v-v1 .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .v-v1 .col-xxl {
    flex: 1 0 0%;
  }
  .v-v1 .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .v-v1 .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .v-v1 .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .v-v1 .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .v-v1 .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .v-v1 .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .v-v1 .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .v-v1 .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .v-v1 .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .v-v1 .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .v-v1 .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .v-v1 .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .v-v1 .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .v-v1 .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .v-v1 .offset-xxl-0 {
    margin-left: 0;
  }
  .v-v1 .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .v-v1 .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .v-v1 .offset-xxl-3 {
    margin-left: 25%;
  }
  .v-v1 .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .v-v1 .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .v-v1 .offset-xxl-6 {
    margin-left: 50%;
  }
  .v-v1 .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .v-v1 .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .v-v1 .offset-xxl-9 {
    margin-left: 75%;
  }
  .v-v1 .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .v-v1 .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .v-v1 .g-xxl-0,
  .v-v1 .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .v-v1 .g-xxl-0,
  .v-v1 .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .v-v1 .g-xxl-1,
  .v-v1 .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .v-v1 .g-xxl-1,
  .v-v1 .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .v-v1 .g-xxl-2,
  .v-v1 .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .v-v1 .g-xxl-2,
  .v-v1 .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .v-v1 .g-xxl-3,
  .v-v1 .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .v-v1 .g-xxl-3,
  .v-v1 .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .v-v1 .g-xxl-4,
  .v-v1 .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .v-v1 .g-xxl-4,
  .v-v1 .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .v-v1 .g-xxl-5,
  .v-v1 .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .v-v1 .g-xxl-5,
  .v-v1 .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.v-v1 .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.v-v1 .text-bg-heading-1 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-1-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-heading-2 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-2-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-heading-3 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-3-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-heading-4 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-4-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-heading-5 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-5-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-heading-6 {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-heading-6-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-p-lead {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-p-lead-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-p-title {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-p-title-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-p {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-p-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-caption {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-caption-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-glossary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-glossary-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-modal-overlay-backdrop {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-modal-overlay-backdrop-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-modal-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-modal-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-modal-close-icon {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-modal-close-icon-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-arrow {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-arrow-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-navigation-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-default-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-default-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-hover-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-hover-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-navigation-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-selected-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-selected-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-selected-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-navigation-selected-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-expanded-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-navigation-expanded-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-navigation-expanded-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-navigation-expanded-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-tab-selected {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tab-selected-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-tab-deselected {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tab-deselected-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-tab-icon {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-tab-icon-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-tab-active-border-bottom {
  color: #000000 !important;
  background-color: RGBA(var(--bs-tab-active-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-language-overlay-background {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-language-overlay-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-language-overlay-link {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-language-overlay-link-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-language-overlay-link-selected {
  color: #000000 !important;
  background-color: RGBA(var(--bs-language-overlay-link-selected-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-language-overlay-link-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-link-internal {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-link-internal-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-link-external {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-link-external-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-text-selection {
  color: #000000 !important;
  background-color: RGBA(var(--bs-text-selection-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-text-selection-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-text-selection-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-white {
  color: #000000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-black {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-header-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-header-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-content-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-icon-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-title-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-active-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-active-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-active-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-active-title-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-tabcontrol-tab-active-icon-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-link {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-link-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-label {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-label-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-input-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-input-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-input-background {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-input-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-input {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-input-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-input-active-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-input-active-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-submit-background {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-submit-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-submit {
  color: #000000 !important;
  background-color: RGBA(var(--bs-order-form-submit-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-submit-hover-background {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-submit-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-error-label {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-error-label-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-error-input-background {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-error-input-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-error-input-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-error-input-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-order-form-error-message {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-order-form-error-message-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-button-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-share-button-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-button-hover-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-share-button-hover-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-border-top {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-border-top-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-font-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-share-font-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-icon {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-icon-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-popover-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-share-popover-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-icon-icon-hover {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-icon-icon-hover-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-icon-icon-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-icon-icon-hover-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-share-icon-icon-active-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-share-icon-icon-active-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-li {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-li-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-badge {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-badge-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-badge-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-badge-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-collapsable-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-list-item-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-list-item-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-input-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-input-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-input-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-input-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-input {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-input-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-popup-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-close {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-popup-close-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-li {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-li-border-bottom {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-li-hover {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-li-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-li-a {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-list-language {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-collapsable-title-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-collapsable-icon-font {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-collapsable-li-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-report-switcher-popup-collapsable-li-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-input-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-input-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-focus-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-focus-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-icon-color {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-icon-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-title-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-title-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-message-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-message-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-input-background-button {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-input-background-button-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-input-background-button-hover {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-input-background-button-hover-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-highlight-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-highlight-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-highlight {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-highlight-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-border-bottom {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-border-right {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-search-border-right-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-result-border-bottom {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-result-border-bottom-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-search-result-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-search-result-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-sidebar-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-sidebar-border-right {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-sidebar-border-right-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-spinner-background-1 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-spinner-background-1-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-spinner-background-2 {
  color: #000000 !important;
  background-color: RGBA(var(--bs-spinner-background-2-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-spinner-after-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-spinner-after-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-highlight-border-top {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-highlight-border-top-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linedark-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-linedark-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linemedium-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-linemedium-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linelight-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-linelight-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linethin-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-linethin-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linetitle-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-linetitle-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-linefootnote-border {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-linefootnote-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-stackable-thtd-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-stackable-thtd-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-color-class {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-color-class-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-desclight-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-desclight-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-number-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-number-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-highlight-color {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-highlight-color-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-highlight-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-highlight-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-highlight-border {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-highlight-border-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-row-hover-background {
  color: #000000 !important;
  background-color: RGBA(var(--bs-table-row-hover-background-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-headerrow {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-headerrow-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-description {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-description-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-entry-bold {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-entry-bold-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .text-bg-table-entry {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-table-entry-rgb), var(--bs-bg-opacity, 1)) !important;
}
.v-v1 .link-heading-1 {
  color: RGBA(var(--bs-heading-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-1:hover, .v-v1 .link-heading-1:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-2 {
  color: RGBA(var(--bs-heading-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-2:hover, .v-v1 .link-heading-2:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-3 {
  color: RGBA(var(--bs-heading-3-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-3-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-3:hover, .v-v1 .link-heading-3:focus {
  color: RGBA(6, 85, 175, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(6, 85, 175, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-4 {
  color: RGBA(var(--bs-heading-4-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-4-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-4:hover, .v-v1 .link-heading-4:focus {
  color: RGBA(6, 85, 175, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(6, 85, 175, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-5 {
  color: RGBA(var(--bs-heading-5-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-5-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-5:hover, .v-v1 .link-heading-5:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-6 {
  color: RGBA(var(--bs-heading-6-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-heading-6-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-heading-6:hover, .v-v1 .link-heading-6:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p-lead {
  color: RGBA(var(--bs-p-lead-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-p-lead-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p-lead:hover, .v-v1 .link-p-lead:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p-title {
  color: RGBA(var(--bs-p-title-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-p-title-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p-title:hover, .v-v1 .link-p-title:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p {
  color: RGBA(var(--bs-p-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-p-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-p:hover, .v-v1 .link-p:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-caption {
  color: RGBA(var(--bs-caption-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-caption-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-caption:hover, .v-v1 .link-caption:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-glossary {
  color: RGBA(var(--bs-glossary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-glossary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-glossary:hover, .v-v1 .link-glossary:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-overlay-backdrop {
  color: RGBA(var(--bs-modal-overlay-backdrop-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-modal-overlay-backdrop-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-overlay-backdrop:hover, .v-v1 .link-modal-overlay-backdrop:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-background {
  color: RGBA(var(--bs-modal-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-modal-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-background:hover, .v-v1 .link-modal-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-close-icon {
  color: RGBA(var(--bs-modal-close-icon-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-modal-close-icon-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-modal-close-icon:hover, .v-v1 .link-modal-close-icon:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-arrow {
  color: RGBA(var(--bs-navigation-arrow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-arrow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-arrow:hover, .v-v1 .link-navigation-arrow:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-background {
  color: RGBA(var(--bs-navigation-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-background:hover, .v-v1 .link-navigation-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-default-border-bottom {
  color: RGBA(var(--bs-navigation-default-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-default-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-default-border-bottom:hover, .v-v1 .link-navigation-default-border-bottom:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-color {
  color: RGBA(var(--bs-navigation-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-color:hover, .v-v1 .link-navigation-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-hover-color {
  color: RGBA(var(--bs-navigation-hover-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-hover-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-hover-color:hover, .v-v1 .link-navigation-hover-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-hover-background {
  color: RGBA(var(--bs-navigation-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-hover-background:hover, .v-v1 .link-navigation-hover-background:focus {
  color: RGBA(249, 251, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 251, 252, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-selected-color {
  color: RGBA(var(--bs-navigation-selected-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-selected-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-selected-color:hover, .v-v1 .link-navigation-selected-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-selected-background {
  color: RGBA(var(--bs-navigation-selected-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-selected-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-selected-background:hover, .v-v1 .link-navigation-selected-background:focus {
  color: RGBA(241, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-expanded-color {
  color: RGBA(var(--bs-navigation-expanded-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-expanded-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-expanded-color:hover, .v-v1 .link-navigation-expanded-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-expanded-background {
  color: RGBA(var(--bs-navigation-expanded-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-navigation-expanded-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-navigation-expanded-background:hover, .v-v1 .link-navigation-expanded-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-selected {
  color: RGBA(var(--bs-tab-selected-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tab-selected-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-selected:hover, .v-v1 .link-tab-selected:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-deselected {
  color: RGBA(var(--bs-tab-deselected-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tab-deselected-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-deselected:hover, .v-v1 .link-tab-deselected:focus {
  color: RGBA(249, 251, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 251, 252, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-icon {
  color: RGBA(var(--bs-tab-icon-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tab-icon-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-icon:hover, .v-v1 .link-tab-icon:focus {
  color: RGBA(0, 14, 68, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 14, 68, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-active-border-bottom {
  color: RGBA(var(--bs-tab-active-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tab-active-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-tab-active-border-bottom:hover, .v-v1 .link-tab-active-border-bottom:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-background {
  color: RGBA(var(--bs-language-overlay-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-language-overlay-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-background:hover, .v-v1 .link-language-overlay-background:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link {
  color: RGBA(var(--bs-language-overlay-link-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-language-overlay-link-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link:hover, .v-v1 .link-language-overlay-link:focus {
  color: RGBA(0, 14, 68, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 14, 68, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link-selected {
  color: RGBA(var(--bs-language-overlay-link-selected-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-language-overlay-link-selected-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link-selected:hover, .v-v1 .link-language-overlay-link-selected:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link-hover-background {
  color: RGBA(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-language-overlay-link-hover-background:hover, .v-v1 .link-language-overlay-link-hover-background:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-link-internal {
  color: RGBA(var(--bs-link-internal-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-link-internal-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-link-internal:hover, .v-v1 .link-link-internal:focus {
  color: RGBA(6, 85, 175, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(6, 85, 175, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-link-external {
  color: RGBA(var(--bs-link-external-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-link-external-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-link-external:hover, .v-v1 .link-link-external:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-text-selection {
  color: RGBA(var(--bs-text-selection-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-text-selection-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-text-selection:hover, .v-v1 .link-text-selection:focus {
  color: RGBA(202, 230, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(202, 230, 245, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-text-selection-color {
  color: RGBA(var(--bs-text-selection-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-text-selection-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-text-selection-color:hover, .v-v1 .link-text-selection-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-primary:hover, .v-v1 .link-primary:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-secondary:hover, .v-v1 .link-secondary:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-white:hover, .v-v1 .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-black:hover, .v-v1 .link-black:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-background {
  color: RGBA(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-background:hover, .v-v1 .link-sidebar-tabcontrol-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-header-background {
  color: RGBA(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-header-background:hover, .v-v1 .link-sidebar-tabcontrol-header-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-header-border-bottom {
  color: RGBA(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-header-border-bottom:hover, .v-v1 .link-sidebar-tabcontrol-header-border-bottom:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-content-background {
  color: RGBA(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-content-background:hover, .v-v1 .link-sidebar-tabcontrol-content-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-border {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-border:hover, .v-v1 .link-sidebar-tabcontrol-tab-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-border-bottom {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-border-bottom:hover, .v-v1 .link-sidebar-tabcontrol-tab-border-bottom:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-background {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-background:hover, .v-v1 .link-sidebar-tabcontrol-tab-background:focus {
  color: RGBA(249, 251, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 251, 252, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-icon-color {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-icon-color:hover, .v-v1 .link-sidebar-tabcontrol-tab-icon-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-title-color {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-title-color:hover, .v-v1 .link-sidebar-tabcontrol-tab-title-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-border {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-border:hover, .v-v1 .link-sidebar-tabcontrol-tab-active-border:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-border-bottom {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-border-bottom:hover, .v-v1 .link-sidebar-tabcontrol-tab-active-border-bottom:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-background {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-background:hover, .v-v1 .link-sidebar-tabcontrol-tab-active-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-title-color {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-title-color:hover, .v-v1 .link-sidebar-tabcontrol-tab-active-title-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-icon-color {
  color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-tabcontrol-tab-active-icon-color:hover, .v-v1 .link-sidebar-tabcontrol-tab-active-icon-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form {
  color: RGBA(var(--bs-order-form-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form:hover, .v-v1 .link-order-form:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-link {
  color: RGBA(var(--bs-order-form-link-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-link-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-link:hover, .v-v1 .link-order-form-link:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-label {
  color: RGBA(var(--bs-order-form-label-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-label-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-label:hover, .v-v1 .link-order-form-label:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-border {
  color: RGBA(var(--bs-order-form-input-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-input-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-border:hover, .v-v1 .link-order-form-input-border:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-background {
  color: RGBA(var(--bs-order-form-input-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-input-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-background:hover, .v-v1 .link-order-form-input-background:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input {
  color: RGBA(var(--bs-order-form-input-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-input-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input:hover, .v-v1 .link-order-form-input:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-active-border {
  color: RGBA(var(--bs-order-form-input-active-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-input-active-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-input-active-border:hover, .v-v1 .link-order-form-input-active-border:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit-background {
  color: RGBA(var(--bs-order-form-submit-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-submit-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit-background:hover, .v-v1 .link-order-form-submit-background:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit {
  color: RGBA(var(--bs-order-form-submit-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-submit-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit:hover, .v-v1 .link-order-form-submit:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit-hover-background {
  color: RGBA(var(--bs-order-form-submit-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-submit-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-submit-hover-background:hover, .v-v1 .link-order-form-submit-hover-background:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-label {
  color: RGBA(var(--bs-order-form-error-label-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-error-label-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-label:hover, .v-v1 .link-order-form-error-label:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-input-background {
  color: RGBA(var(--bs-order-form-error-input-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-error-input-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-input-background:hover, .v-v1 .link-order-form-error-input-background:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-input-border {
  color: RGBA(var(--bs-order-form-error-input-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-error-input-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-input-border:hover, .v-v1 .link-order-form-error-input-border:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-message {
  color: RGBA(var(--bs-order-form-error-message-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-order-form-error-message-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-order-form-error-message:hover, .v-v1 .link-order-form-error-message:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-button-color {
  color: RGBA(var(--bs-share-button-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-button-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-button-color:hover, .v-v1 .link-share-button-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-button-hover-color {
  color: RGBA(var(--bs-share-button-hover-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-button-hover-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-button-hover-color:hover, .v-v1 .link-share-button-hover-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-border {
  color: RGBA(var(--bs-share-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-border:hover, .v-v1 .link-share-border:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-border-top {
  color: RGBA(var(--bs-share-border-top-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-border-top-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-border-top:hover, .v-v1 .link-share-border-top:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-background {
  color: RGBA(var(--bs-share-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-background:hover, .v-v1 .link-share-background:focus {
  color: RGBA(243, 243, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(243, 243, 243, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-font-color {
  color: RGBA(var(--bs-share-font-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-font-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-font-color:hover, .v-v1 .link-share-font-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon {
  color: RGBA(var(--bs-share-icon-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-icon-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon:hover, .v-v1 .link-share-icon:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-popover-border {
  color: RGBA(var(--bs-share-popover-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-popover-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-popover-border:hover, .v-v1 .link-share-popover-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover {
  color: RGBA(var(--bs-share-icon-icon-hover-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-icon-icon-hover-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover:hover, .v-v1 .link-share-icon-icon-hover:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover-background {
  color: RGBA(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover-background:hover, .v-v1 .link-share-icon-icon-hover-background:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover-border {
  color: RGBA(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-hover-border:hover, .v-v1 .link-share-icon-icon-hover-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-active-border {
  color: RGBA(var(--bs-share-icon-icon-active-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-share-icon-icon-active-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-share-icon-icon-active-border:hover, .v-v1 .link-share-icon-icon-active-border:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-li {
  color: RGBA(var(--bs-report-switcher-li-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-li-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-li:hover, .v-v1 .link-report-switcher-li:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-badge {
  color: RGBA(var(--bs-report-switcher-badge-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-badge-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-badge:hover, .v-v1 .link-report-switcher-badge:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-badge-background {
  color: RGBA(var(--bs-report-switcher-badge-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-badge-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-badge-background:hover, .v-v1 .link-report-switcher-badge-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-collapsable-border-bottom {
  color: RGBA(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-collapsable-border-bottom:hover, .v-v1 .link-report-switcher-collapsable-border-bottom:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-list-item-color {
  color: RGBA(var(--bs-report-switcher-list-item-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-list-item-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-list-item-color:hover, .v-v1 .link-report-switcher-list-item-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input-background {
  color: RGBA(var(--bs-report-switcher-input-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-input-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input-background:hover, .v-v1 .link-report-switcher-input-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input-border {
  color: RGBA(var(--bs-report-switcher-input-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-input-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input-border:hover, .v-v1 .link-report-switcher-input-border:focus {
  color: RGBA(233, 237, 240, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 237, 240, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input {
  color: RGBA(var(--bs-report-switcher-input-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-input-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-input:hover, .v-v1 .link-report-switcher-input:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-background {
  color: RGBA(var(--bs-report-switcher-popup-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-background:hover, .v-v1 .link-report-switcher-popup-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-close {
  color: RGBA(var(--bs-report-switcher-popup-close-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-close-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-close:hover, .v-v1 .link-report-switcher-popup-close:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li {
  color: RGBA(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li:hover, .v-v1 .link-report-switcher-popup-list-li:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-border-bottom {
  color: RGBA(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-border-bottom:hover, .v-v1 .link-report-switcher-popup-list-li-border-bottom:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-hover {
  color: RGBA(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-hover:hover, .v-v1 .link-report-switcher-popup-list-li-hover:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-hover-background {
  color: RGBA(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-hover-background:hover, .v-v1 .link-report-switcher-popup-list-li-hover-background:focus {
  color: RGBA(218, 235, 250, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(218, 235, 250, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-a {
  color: RGBA(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-li-a:hover, .v-v1 .link-report-switcher-popup-list-li-a:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-language {
  color: RGBA(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-list-language:hover, .v-v1 .link-report-switcher-popup-list-language:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-title-border-bottom {
  color: RGBA(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-title-border-bottom:hover, .v-v1 .link-report-switcher-popup-collapsable-title-border-bottom:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-icon-font {
  color: RGBA(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-icon-font:hover, .v-v1 .link-report-switcher-popup-collapsable-icon-font:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-li-border-bottom {
  color: RGBA(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-li-border-bottom:hover, .v-v1 .link-report-switcher-popup-collapsable-li-border-bottom:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-li-color {
  color: RGBA(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-report-switcher-popup-collapsable-li-color:hover, .v-v1 .link-report-switcher-popup-collapsable-li-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background {
  color: RGBA(var(--bs-search-input-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-input-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background:hover, .v-v1 .link-search-input-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border {
  color: RGBA(var(--bs-search-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border:hover, .v-v1 .link-search-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-focus-border {
  color: RGBA(var(--bs-search-focus-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-focus-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-focus-border:hover, .v-v1 .link-search-focus-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-icon-color {
  color: RGBA(var(--bs-search-icon-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-icon-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-icon-color:hover, .v-v1 .link-search-icon-color:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-title-color {
  color: RGBA(var(--bs-search-title-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-title-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-title-color:hover, .v-v1 .link-search-title-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-message-color {
  color: RGBA(var(--bs-search-message-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-message-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-message-color:hover, .v-v1 .link-search-message-color:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background-button {
  color: RGBA(var(--bs-search-input-background-button-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-input-background-button-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background-button:hover, .v-v1 .link-search-input-background-button:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background-button-hover {
  color: RGBA(var(--bs-search-input-background-button-hover-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-input-background-button-hover-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-input-background-button-hover:hover, .v-v1 .link-search-input-background-button-hover:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-highlight-background {
  color: RGBA(var(--bs-search-highlight-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-highlight-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-highlight-background:hover, .v-v1 .link-search-highlight-background:focus {
  color: RGBA(218, 235, 250, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(218, 235, 250, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-highlight {
  color: RGBA(var(--bs-search-highlight-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-highlight-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-highlight:hover, .v-v1 .link-search-highlight:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border-bottom {
  color: RGBA(var(--bs-search-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border-bottom:hover, .v-v1 .link-search-border-bottom:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border-right {
  color: RGBA(var(--bs-search-border-right-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-border-right-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-border-right:hover, .v-v1 .link-search-border-right:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-result-border-bottom {
  color: RGBA(var(--bs-search-result-border-bottom-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-result-border-bottom-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-result-border-bottom:hover, .v-v1 .link-search-result-border-bottom:focus {
  color: RGBA(249, 251, 252, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 251, 252, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-result-hover-background {
  color: RGBA(var(--bs-search-result-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-search-result-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-search-result-hover-background:hover, .v-v1 .link-search-result-hover-background:focus {
  color: RGBA(241, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-background {
  color: RGBA(var(--bs-sidebar-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-background:hover, .v-v1 .link-sidebar-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-border-right {
  color: RGBA(var(--bs-sidebar-border-right-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-sidebar-border-right-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-sidebar-border-right:hover, .v-v1 .link-sidebar-border-right:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-background-1 {
  color: RGBA(var(--bs-spinner-background-1-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-spinner-background-1-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-background-1:hover, .v-v1 .link-spinner-background-1:focus {
  color: RGBA(69, 154, 233, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(69, 154, 233, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-background-2 {
  color: RGBA(var(--bs-spinner-background-2-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-spinner-background-2-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-background-2:hover, .v-v1 .link-spinner-background-2:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-after-background {
  color: RGBA(var(--bs-spinner-after-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-spinner-after-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-spinner-after-background:hover, .v-v1 .link-spinner-after-background:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-border-top {
  color: RGBA(var(--bs-table-highlight-border-top-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-highlight-border-top-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-border-top:hover, .v-v1 .link-table-highlight-border-top:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linedark-border {
  color: RGBA(var(--bs-table-linedark-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linedark-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linedark-border:hover, .v-v1 .link-table-linedark-border:focus {
  color: RGBA(173, 173, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(173, 173, 173, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linemedium-border {
  color: RGBA(var(--bs-table-linemedium-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linemedium-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linemedium-border:hover, .v-v1 .link-table-linemedium-border:focus {
  color: RGBA(173, 173, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(173, 173, 173, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linelight-border {
  color: RGBA(var(--bs-table-linelight-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linelight-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linelight-border:hover, .v-v1 .link-table-linelight-border:focus {
  color: RGBA(201, 201, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(201, 201, 201, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linethin-border {
  color: RGBA(var(--bs-table-linethin-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linethin-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linethin-border:hover, .v-v1 .link-table-linethin-border:focus {
  color: RGBA(86, 86, 86, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 86, 86, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linetitle-border {
  color: RGBA(var(--bs-table-linetitle-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linetitle-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linetitle-border:hover, .v-v1 .link-table-linetitle-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linefootnote-border {
  color: RGBA(var(--bs-table-linefootnote-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-linefootnote-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-linefootnote-border:hover, .v-v1 .link-table-linefootnote-border:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-stackable-thtd-color {
  color: RGBA(var(--bs-table-stackable-thtd-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-stackable-thtd-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-stackable-thtd-color:hover, .v-v1 .link-table-stackable-thtd-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-color-class {
  color: RGBA(var(--bs-table-color-class-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-color-class-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-color-class:hover, .v-v1 .link-table-color-class:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-desclight-color {
  color: RGBA(var(--bs-table-desclight-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-desclight-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-desclight-color:hover, .v-v1 .link-table-desclight-color:focus {
  color: RGBA(86, 86, 86, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 86, 86, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-number-color {
  color: RGBA(var(--bs-table-number-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-number-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-number-color:hover, .v-v1 .link-table-number-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-color {
  color: RGBA(var(--bs-table-highlight-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-highlight-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-color:hover, .v-v1 .link-table-highlight-color:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-background {
  color: RGBA(var(--bs-table-highlight-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-highlight-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-background:hover, .v-v1 .link-table-highlight-background:focus {
  color: RGBA(241, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-border {
  color: RGBA(var(--bs-table-highlight-border-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-highlight-border-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-highlight-border:hover, .v-v1 .link-table-highlight-border:focus {
  color: RGBA(241, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-row-hover-background {
  color: RGBA(var(--bs-table-row-hover-background-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-row-hover-background-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-row-hover-background:hover, .v-v1 .link-table-row-hover-background:focus {
  color: RGBA(241, 245, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 248, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-headerrow {
  color: RGBA(var(--bs-table-headerrow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-headerrow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-headerrow:hover, .v-v1 .link-table-headerrow:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-description {
  color: RGBA(var(--bs-table-description-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-description-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-description:hover, .v-v1 .link-table-description:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-entry-bold {
  color: RGBA(var(--bs-table-entry-bold-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-entry-bold-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-entry-bold:hover, .v-v1 .link-table-entry-bold:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-entry {
  color: RGBA(var(--bs-table-entry-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-table-entry-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-table-entry:hover, .v-v1 .link-table-entry:focus {
  color: RGBA(41, 41, 41, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(41, 41, 41, var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-body-emphasis:hover, .v-v1 .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}
.v-v1 .focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}
.v-v1 .icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.v-v1 .icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .v-v1 .icon-link > .bi {
    transition: none;
  }
}
.v-v1 .icon-link-hover:hover > .bi, .v-v1 .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}
.v-v1 .ratio {
  position: relative;
  width: 100%;
}
.v-v1 .ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.v-v1 .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-v1 .ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.v-v1 .ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.v-v1 .ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.v-v1 .ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.v-v1 .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.v-v1 .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.v-v1 .sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.v-v1 .sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .v-v1 .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .v-v1 .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .v-v1 .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .v-v1 .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .v-v1 .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .v-v1 .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .v-v1 .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .v-v1 .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .v-v1 .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .v-v1 .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.v-v1 .hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.v-v1 .vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.v-v1 .visually-hidden,
.v-v1 .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.v-v1 .visually-hidden:not(caption),
.v-v1 .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.v-v1 .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.v-v1 .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-v1 .vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.v-v1 .align-baseline {
  vertical-align: baseline !important;
}
.v-v1 .align-top {
  vertical-align: top !important;
}
.v-v1 .align-middle {
  vertical-align: middle !important;
}
.v-v1 .align-bottom {
  vertical-align: bottom !important;
}
.v-v1 .align-text-bottom {
  vertical-align: text-bottom !important;
}
.v-v1 .align-text-top {
  vertical-align: text-top !important;
}
.v-v1 .float-start {
  float: left !important;
}
.v-v1 .float-end {
  float: right !important;
}
.v-v1 .float-none {
  float: none !important;
}
.v-v1 .object-fit-contain {
  object-fit: contain !important;
}
.v-v1 .object-fit-cover {
  object-fit: cover !important;
}
.v-v1 .object-fit-fill {
  object-fit: fill !important;
}
.v-v1 .object-fit-scale {
  object-fit: scale-down !important;
}
.v-v1 .object-fit-none {
  object-fit: none !important;
}
.v-v1 .opacity-0 {
  opacity: 0 !important;
}
.v-v1 .opacity-25 {
  opacity: 0.25 !important;
}
.v-v1 .opacity-50 {
  opacity: 0.5 !important;
}
.v-v1 .opacity-75 {
  opacity: 0.75 !important;
}
.v-v1 .opacity-100 {
  opacity: 1 !important;
}
.v-v1 .overflow-auto {
  overflow: auto !important;
}
.v-v1 .overflow-hidden {
  overflow: hidden !important;
}
.v-v1 .overflow-visible {
  overflow: visible !important;
}
.v-v1 .overflow-scroll {
  overflow: scroll !important;
}
.v-v1 .overflow-x-auto {
  overflow-x: auto !important;
}
.v-v1 .overflow-x-hidden {
  overflow-x: hidden !important;
}
.v-v1 .overflow-x-visible {
  overflow-x: visible !important;
}
.v-v1 .overflow-x-scroll {
  overflow-x: scroll !important;
}
.v-v1 .overflow-y-auto {
  overflow-y: auto !important;
}
.v-v1 .overflow-y-hidden {
  overflow-y: hidden !important;
}
.v-v1 .overflow-y-visible {
  overflow-y: visible !important;
}
.v-v1 .overflow-y-scroll {
  overflow-y: scroll !important;
}
.v-v1 .d-inline {
  display: inline !important;
}
.v-v1 .d-inline-block {
  display: inline-block !important;
}
.v-v1 .d-block {
  display: block !important;
}
.v-v1 .d-grid {
  display: grid !important;
}
.v-v1 .d-inline-grid {
  display: inline-grid !important;
}
.v-v1 .d-table {
  display: table !important;
}
.v-v1 .d-table-row {
  display: table-row !important;
}
.v-v1 .d-table-cell {
  display: table-cell !important;
}
.v-v1 .d-flex {
  display: flex !important;
}
.v-v1 .d-inline-flex {
  display: inline-flex !important;
}
.v-v1 .d-none {
  display: none !important;
}
.v-v1 .shadow {
  box-shadow: var(--bs-box-shadow) !important;
}
.v-v1 .shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}
.v-v1 .shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}
.v-v1 .shadow-none {
  box-shadow: none !important;
}
.v-v1 .focus-ring-heading-1 {
  --bs-focus-ring-color: rgba(var(--bs-heading-1-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-heading-2 {
  --bs-focus-ring-color: rgba(var(--bs-heading-2-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-heading-3 {
  --bs-focus-ring-color: rgba(var(--bs-heading-3-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-heading-4 {
  --bs-focus-ring-color: rgba(var(--bs-heading-4-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-heading-5 {
  --bs-focus-ring-color: rgba(var(--bs-heading-5-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-heading-6 {
  --bs-focus-ring-color: rgba(var(--bs-heading-6-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-p-lead {
  --bs-focus-ring-color: rgba(var(--bs-p-lead-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-p-title {
  --bs-focus-ring-color: rgba(var(--bs-p-title-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-p {
  --bs-focus-ring-color: rgba(var(--bs-p-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-caption {
  --bs-focus-ring-color: rgba(var(--bs-caption-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-glossary {
  --bs-focus-ring-color: rgba(var(--bs-glossary-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-modal-overlay-backdrop {
  --bs-focus-ring-color: rgba(var(--bs-modal-overlay-backdrop-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-modal-background {
  --bs-focus-ring-color: rgba(var(--bs-modal-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-modal-close-icon {
  --bs-focus-ring-color: rgba(var(--bs-modal-close-icon-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-arrow {
  --bs-focus-ring-color: rgba(var(--bs-navigation-arrow-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-background {
  --bs-focus-ring-color: rgba(var(--bs-navigation-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-default-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-navigation-default-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-color {
  --bs-focus-ring-color: rgba(var(--bs-navigation-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-hover-color {
  --bs-focus-ring-color: rgba(var(--bs-navigation-hover-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-navigation-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-selected-color {
  --bs-focus-ring-color: rgba(var(--bs-navigation-selected-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-selected-background {
  --bs-focus-ring-color: rgba(var(--bs-navigation-selected-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-expanded-color {
  --bs-focus-ring-color: rgba(var(--bs-navigation-expanded-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-navigation-expanded-background {
  --bs-focus-ring-color: rgba(var(--bs-navigation-expanded-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-tab-selected {
  --bs-focus-ring-color: rgba(var(--bs-tab-selected-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-tab-deselected {
  --bs-focus-ring-color: rgba(var(--bs-tab-deselected-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-tab-icon {
  --bs-focus-ring-color: rgba(var(--bs-tab-icon-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-tab-active-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-tab-active-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-language-overlay-background {
  --bs-focus-ring-color: rgba(var(--bs-language-overlay-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-language-overlay-link {
  --bs-focus-ring-color: rgba(var(--bs-language-overlay-link-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-language-overlay-link-selected {
  --bs-focus-ring-color: rgba(var(--bs-language-overlay-link-selected-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-language-overlay-link-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-link-internal {
  --bs-focus-ring-color: rgba(var(--bs-link-internal-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-link-external {
  --bs-focus-ring-color: rgba(var(--bs-link-external-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-text-selection {
  --bs-focus-ring-color: rgba(var(--bs-text-selection-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-text-selection-color {
  --bs-focus-ring-color: rgba(var(--bs-text-selection-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-header-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-header-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-content-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-border {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-icon-color {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-title-color {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-active-border {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-active-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-active-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-active-title-color {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-tabcontrol-tab-active-icon-color {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form {
  --bs-focus-ring-color: rgba(var(--bs-order-form-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-link {
  --bs-focus-ring-color: rgba(var(--bs-order-form-link-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-label {
  --bs-focus-ring-color: rgba(var(--bs-order-form-label-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-input-border {
  --bs-focus-ring-color: rgba(var(--bs-order-form-input-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-input-background {
  --bs-focus-ring-color: rgba(var(--bs-order-form-input-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-input {
  --bs-focus-ring-color: rgba(var(--bs-order-form-input-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-input-active-border {
  --bs-focus-ring-color: rgba(var(--bs-order-form-input-active-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-submit-background {
  --bs-focus-ring-color: rgba(var(--bs-order-form-submit-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-submit {
  --bs-focus-ring-color: rgba(var(--bs-order-form-submit-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-submit-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-order-form-submit-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-error-label {
  --bs-focus-ring-color: rgba(var(--bs-order-form-error-label-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-error-input-background {
  --bs-focus-ring-color: rgba(var(--bs-order-form-error-input-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-error-input-border {
  --bs-focus-ring-color: rgba(var(--bs-order-form-error-input-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-order-form-error-message {
  --bs-focus-ring-color: rgba(var(--bs-order-form-error-message-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-button-color {
  --bs-focus-ring-color: rgba(var(--bs-share-button-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-button-hover-color {
  --bs-focus-ring-color: rgba(var(--bs-share-button-hover-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-border {
  --bs-focus-ring-color: rgba(var(--bs-share-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-border-top {
  --bs-focus-ring-color: rgba(var(--bs-share-border-top-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-background {
  --bs-focus-ring-color: rgba(var(--bs-share-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-font-color {
  --bs-focus-ring-color: rgba(var(--bs-share-font-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-icon {
  --bs-focus-ring-color: rgba(var(--bs-share-icon-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-popover-border {
  --bs-focus-ring-color: rgba(var(--bs-share-popover-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-icon-icon-hover {
  --bs-focus-ring-color: rgba(var(--bs-share-icon-icon-hover-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-icon-icon-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-icon-icon-hover-border {
  --bs-focus-ring-color: rgba(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-share-icon-icon-active-border {
  --bs-focus-ring-color: rgba(var(--bs-share-icon-icon-active-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-li {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-li-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-badge {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-badge-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-badge-background {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-badge-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-collapsable-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-list-item-color {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-list-item-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-input-background {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-input-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-input-border {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-input-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-input {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-input-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-background {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-close {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-close-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-li {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-li-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-li-hover {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-li-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-li-a {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-list-language {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-collapsable-title-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-collapsable-icon-font {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-collapsable-li-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-report-switcher-popup-collapsable-li-color {
  --bs-focus-ring-color: rgba(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-input-background {
  --bs-focus-ring-color: rgba(var(--bs-search-input-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-border {
  --bs-focus-ring-color: rgba(var(--bs-search-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-focus-border {
  --bs-focus-ring-color: rgba(var(--bs-search-focus-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-icon-color {
  --bs-focus-ring-color: rgba(var(--bs-search-icon-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-title-color {
  --bs-focus-ring-color: rgba(var(--bs-search-title-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-message-color {
  --bs-focus-ring-color: rgba(var(--bs-search-message-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-input-background-button {
  --bs-focus-ring-color: rgba(var(--bs-search-input-background-button-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-input-background-button-hover {
  --bs-focus-ring-color: rgba(var(--bs-search-input-background-button-hover-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-highlight-background {
  --bs-focus-ring-color: rgba(var(--bs-search-highlight-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-highlight {
  --bs-focus-ring-color: rgba(var(--bs-search-highlight-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-search-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-border-right {
  --bs-focus-ring-color: rgba(var(--bs-search-border-right-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-result-border-bottom {
  --bs-focus-ring-color: rgba(var(--bs-search-result-border-bottom-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-search-result-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-search-result-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-background {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-sidebar-border-right {
  --bs-focus-ring-color: rgba(var(--bs-sidebar-border-right-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-spinner-background-1 {
  --bs-focus-ring-color: rgba(var(--bs-spinner-background-1-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-spinner-background-2 {
  --bs-focus-ring-color: rgba(var(--bs-spinner-background-2-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-spinner-after-background {
  --bs-focus-ring-color: rgba(var(--bs-spinner-after-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-highlight-border-top {
  --bs-focus-ring-color: rgba(var(--bs-table-highlight-border-top-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linedark-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linedark-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linemedium-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linemedium-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linelight-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linelight-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linethin-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linethin-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linetitle-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linetitle-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-linefootnote-border {
  --bs-focus-ring-color: rgba(var(--bs-table-linefootnote-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-stackable-thtd-color {
  --bs-focus-ring-color: rgba(var(--bs-table-stackable-thtd-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-color-class {
  --bs-focus-ring-color: rgba(var(--bs-table-color-class-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-desclight-color {
  --bs-focus-ring-color: rgba(var(--bs-table-desclight-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-number-color {
  --bs-focus-ring-color: rgba(var(--bs-table-number-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-highlight-color {
  --bs-focus-ring-color: rgba(var(--bs-table-highlight-color-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-highlight-background {
  --bs-focus-ring-color: rgba(var(--bs-table-highlight-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-highlight-border {
  --bs-focus-ring-color: rgba(var(--bs-table-highlight-border-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-row-hover-background {
  --bs-focus-ring-color: rgba(var(--bs-table-row-hover-background-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-headerrow {
  --bs-focus-ring-color: rgba(var(--bs-table-headerrow-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-description {
  --bs-focus-ring-color: rgba(var(--bs-table-description-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-entry-bold {
  --bs-focus-ring-color: rgba(var(--bs-table-entry-bold-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .focus-ring-table-entry {
  --bs-focus-ring-color: rgba(var(--bs-table-entry-rgb), var(--bs-focus-ring-opacity));
}
.v-v1 .position-static {
  position: static !important;
}
.v-v1 .position-relative {
  position: relative !important;
}
.v-v1 .position-absolute {
  position: absolute !important;
}
.v-v1 .position-fixed {
  position: fixed !important;
}
.v-v1 .position-sticky {
  position: sticky !important;
}
.v-v1 .top-0 {
  top: 0 !important;
}
.v-v1 .top-50 {
  top: 50% !important;
}
.v-v1 .top-100 {
  top: 100% !important;
}
.v-v1 .bottom-0 {
  bottom: 0 !important;
}
.v-v1 .bottom-50 {
  bottom: 50% !important;
}
.v-v1 .bottom-100 {
  bottom: 100% !important;
}
.v-v1 .start-0 {
  left: 0 !important;
}
.v-v1 .start-50 {
  left: 50% !important;
}
.v-v1 .start-100 {
  left: 100% !important;
}
.v-v1 .end-0 {
  right: 0 !important;
}
.v-v1 .end-50 {
  right: 50% !important;
}
.v-v1 .end-100 {
  right: 100% !important;
}
.v-v1 .translate-middle {
  transform: translate(-50%, -50%) !important;
}
.v-v1 .translate-middle-x {
  transform: translateX(-50%) !important;
}
.v-v1 .translate-middle-y {
  transform: translateY(-50%) !important;
}
.v-v1 .border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.v-v1 .border-0 {
  border: 0 !important;
}
.v-v1 .border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.v-v1 .border-top-0 {
  border-top: 0 !important;
}
.v-v1 .border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.v-v1 .border-end-0 {
  border-right: 0 !important;
}
.v-v1 .border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.v-v1 .border-bottom-0 {
  border-bottom: 0 !important;
}
.v-v1 .border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.v-v1 .border-start-0 {
  border-left: 0 !important;
}
.v-v1 .border-heading-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-1-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-heading-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-2-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-heading-3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-3-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-heading-4 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-4-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-heading-5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-5-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-heading-6 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-heading-6-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-p-lead {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-p-lead-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-p-title {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-p-title-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-p {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-p-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-caption {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-caption-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-glossary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-glossary-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-modal-overlay-backdrop {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-modal-overlay-backdrop-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-modal-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-modal-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-modal-close-icon {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-modal-close-icon-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-arrow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-arrow-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-default-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-default-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-hover-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-hover-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-selected-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-selected-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-selected-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-selected-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-expanded-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-expanded-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-navigation-expanded-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-navigation-expanded-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-tab-selected {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tab-selected-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-tab-deselected {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tab-deselected-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-tab-icon {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tab-icon-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-tab-active-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tab-active-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-language-overlay-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-language-overlay-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-language-overlay-link {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-language-overlay-link-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-language-overlay-link-selected {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-language-overlay-link-selected-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-language-overlay-link-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-link-internal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-link-internal-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-link-external {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-link-external-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-text-selection {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-text-selection-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-text-selection-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-text-selection-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-header-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-header-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-content-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-icon-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-title-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-active-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-active-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-active-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-active-title-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-tabcontrol-tab-active-icon-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-link {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-link-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-label {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-label-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-input-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-input-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-input-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-input-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-input {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-input-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-input-active-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-input-active-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-submit-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-submit-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-submit {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-submit-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-submit-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-submit-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-error-label {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-error-label-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-error-input-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-error-input-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-error-input-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-error-input-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-order-form-error-message {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-order-form-error-message-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-button-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-button-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-button-hover-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-button-hover-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-border-top {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-border-top-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-font-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-font-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-icon {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-icon-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-popover-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-popover-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-icon-icon-hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-icon-icon-hover-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-icon-icon-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-icon-icon-hover-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-share-icon-icon-active-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-share-icon-icon-active-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-li {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-li-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-badge {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-badge-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-badge-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-badge-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-collapsable-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-list-item-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-list-item-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-input-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-input-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-input-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-input-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-input {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-input-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-close {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-close-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-li {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-li-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-li-hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-li-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-li-a {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-list-language {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-collapsable-title-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-collapsable-icon-font {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-collapsable-li-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-report-switcher-popup-collapsable-li-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-input-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-input-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-focus-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-focus-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-icon-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-icon-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-title-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-title-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-message-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-message-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-input-background-button {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-input-background-button-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-input-background-button-hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-input-background-button-hover-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-highlight-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-highlight-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-highlight {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-highlight-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-border-right {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-border-right-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-result-border-bottom {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-result-border-bottom-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-search-result-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-search-result-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-sidebar-border-right {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-sidebar-border-right-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-spinner-background-1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-spinner-background-1-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-spinner-background-2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-spinner-background-2-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-spinner-after-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-spinner-after-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-highlight-border-top {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-highlight-border-top-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linedark-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linedark-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linemedium-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linemedium-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linelight-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linelight-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linethin-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linethin-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linetitle-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linetitle-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-linefootnote-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-linefootnote-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-stackable-thtd-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-stackable-thtd-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-color-class {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-color-class-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-desclight-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-desclight-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-number-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-number-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-highlight-color {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-highlight-color-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-highlight-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-highlight-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-highlight-border {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-highlight-border-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-row-hover-background {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-row-hover-background-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-headerrow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-headerrow-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-description {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-description-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-entry-bold {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-entry-bold-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-table-entry {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-table-entry-rgb), var(--bs-border-opacity)) !important;
}
.v-v1 .border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}
.v-v1 .border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}
.v-v1 .border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}
.v-v1 .border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}
.v-v1 .border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}
.v-v1 .border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}
.v-v1 .border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}
.v-v1 .border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}
.v-v1 .border-1 {
  border-width: 1px !important;
}
.v-v1 .border-2 {
  border-width: 2px !important;
}
.v-v1 .border-3 {
  border-width: 3px !important;
}
.v-v1 .border-4 {
  border-width: 4px !important;
}
.v-v1 .border-5 {
  border-width: 5px !important;
}
.v-v1 .border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.v-v1 .border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.v-v1 .border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.v-v1 .border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.v-v1 .border-opacity-100 {
  --bs-border-opacity: 1;
}
.v-v1 .w-25 {
  width: 25% !important;
}
.v-v1 .w-50 {
  width: 50% !important;
}
.v-v1 .w-75 {
  width: 75% !important;
}
.v-v1 .w-100 {
  width: 100% !important;
}
.v-v1 .w-auto {
  width: auto !important;
}
.v-v1 .mw-100 {
  max-width: 100% !important;
}
.v-v1 .vw-100 {
  width: 100vw !important;
}
.v-v1 .min-vw-100 {
  min-width: 100vw !important;
}
.v-v1 .h-25 {
  height: 25% !important;
}
.v-v1 .h-50 {
  height: 50% !important;
}
.v-v1 .h-75 {
  height: 75% !important;
}
.v-v1 .h-100 {
  height: 100% !important;
}
.v-v1 .h-auto {
  height: auto !important;
}
.v-v1 .mh-100 {
  max-height: 100% !important;
}
.v-v1 .vh-100 {
  height: 100vh !important;
}
.v-v1 .min-vh-100 {
  min-height: 100vh !important;
}
.v-v1 .flex-fill {
  flex: 1 1 auto !important;
}
.v-v1 .flex-row {
  flex-direction: row !important;
}
.v-v1 .flex-column {
  flex-direction: column !important;
}
.v-v1 .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.v-v1 .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.v-v1 .flex-grow-0 {
  flex-grow: 0 !important;
}
.v-v1 .flex-grow-1 {
  flex-grow: 1 !important;
}
.v-v1 .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.v-v1 .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.v-v1 .flex-wrap {
  flex-wrap: wrap !important;
}
.v-v1 .flex-nowrap {
  flex-wrap: nowrap !important;
}
.v-v1 .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.v-v1 .justify-content-start {
  justify-content: flex-start !important;
}
.v-v1 .justify-content-end {
  justify-content: flex-end !important;
}
.v-v1 .justify-content-center {
  justify-content: center !important;
}
.v-v1 .justify-content-between {
  justify-content: space-between !important;
}
.v-v1 .justify-content-around {
  justify-content: space-around !important;
}
.v-v1 .justify-content-evenly {
  justify-content: space-evenly !important;
}
.v-v1 .align-items-start {
  align-items: flex-start !important;
}
.v-v1 .align-items-end {
  align-items: flex-end !important;
}
.v-v1 .align-items-center {
  align-items: center !important;
}
.v-v1 .align-items-baseline {
  align-items: baseline !important;
}
.v-v1 .align-items-stretch {
  align-items: stretch !important;
}
.v-v1 .align-content-start {
  align-content: flex-start !important;
}
.v-v1 .align-content-end {
  align-content: flex-end !important;
}
.v-v1 .align-content-center {
  align-content: center !important;
}
.v-v1 .align-content-between {
  align-content: space-between !important;
}
.v-v1 .align-content-around {
  align-content: space-around !important;
}
.v-v1 .align-content-stretch {
  align-content: stretch !important;
}
.v-v1 .align-self-auto {
  align-self: auto !important;
}
.v-v1 .align-self-start {
  align-self: flex-start !important;
}
.v-v1 .align-self-end {
  align-self: flex-end !important;
}
.v-v1 .align-self-center {
  align-self: center !important;
}
.v-v1 .align-self-baseline {
  align-self: baseline !important;
}
.v-v1 .align-self-stretch {
  align-self: stretch !important;
}
.v-v1 .order-first {
  order: -1 !important;
}
.v-v1 .order-0 {
  order: 0 !important;
}
.v-v1 .order-1 {
  order: 1 !important;
}
.v-v1 .order-2 {
  order: 2 !important;
}
.v-v1 .order-3 {
  order: 3 !important;
}
.v-v1 .order-4 {
  order: 4 !important;
}
.v-v1 .order-5 {
  order: 5 !important;
}
.v-v1 .order-last {
  order: 6 !important;
}
.v-v1 .m-0 {
  margin: 0 !important;
}
.v-v1 .m-1 {
  margin: 0.25rem !important;
}
.v-v1 .m-2 {
  margin: 0.5rem !important;
}
.v-v1 .m-3 {
  margin: 1rem !important;
}
.v-v1 .m-4 {
  margin: 1.5rem !important;
}
.v-v1 .m-5 {
  margin: 3rem !important;
}
.v-v1 .m-auto {
  margin: auto !important;
}
.v-v1 .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.v-v1 .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.v-v1 .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.v-v1 .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.v-v1 .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.v-v1 .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.v-v1 .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.v-v1 .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.v-v1 .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.v-v1 .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.v-v1 .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.v-v1 .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.v-v1 .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.v-v1 .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.v-v1 .mt-0 {
  margin-top: 0 !important;
}
.v-v1 .mt-1 {
  margin-top: 0.25rem !important;
}
.v-v1 .mt-2 {
  margin-top: 0.5rem !important;
}
.v-v1 .mt-3 {
  margin-top: 1rem !important;
}
.v-v1 .mt-4 {
  margin-top: 1.5rem !important;
}
.v-v1 .mt-5 {
  margin-top: 3rem !important;
}
.v-v1 .mt-auto {
  margin-top: auto !important;
}
.v-v1 .me-0 {
  margin-right: 0 !important;
}
.v-v1 .me-1 {
  margin-right: 0.25rem !important;
}
.v-v1 .me-2 {
  margin-right: 0.5rem !important;
}
.v-v1 .me-3 {
  margin-right: 1rem !important;
}
.v-v1 .me-4 {
  margin-right: 1.5rem !important;
}
.v-v1 .me-5 {
  margin-right: 3rem !important;
}
.v-v1 .me-auto {
  margin-right: auto !important;
}
.v-v1 .mb-0 {
  margin-bottom: 0 !important;
}
.v-v1 .mb-1 {
  margin-bottom: 0.25rem !important;
}
.v-v1 .mb-2 {
  margin-bottom: 0.5rem !important;
}
.v-v1 .mb-3 {
  margin-bottom: 1rem !important;
}
.v-v1 .mb-4 {
  margin-bottom: 1.5rem !important;
}
.v-v1 .mb-5 {
  margin-bottom: 3rem !important;
}
.v-v1 .mb-auto {
  margin-bottom: auto !important;
}
.v-v1 .ms-0 {
  margin-left: 0 !important;
}
.v-v1 .ms-1 {
  margin-left: 0.25rem !important;
}
.v-v1 .ms-2 {
  margin-left: 0.5rem !important;
}
.v-v1 .ms-3 {
  margin-left: 1rem !important;
}
.v-v1 .ms-4 {
  margin-left: 1.5rem !important;
}
.v-v1 .ms-5 {
  margin-left: 3rem !important;
}
.v-v1 .ms-auto {
  margin-left: auto !important;
}
.v-v1 .p-0 {
  padding: 0 !important;
}
.v-v1 .p-1 {
  padding: 0.25rem !important;
}
.v-v1 .p-2 {
  padding: 0.5rem !important;
}
.v-v1 .p-3 {
  padding: 1rem !important;
}
.v-v1 .p-4 {
  padding: 1.5rem !important;
}
.v-v1 .p-5 {
  padding: 3rem !important;
}
.v-v1 .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.v-v1 .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.v-v1 .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.v-v1 .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.v-v1 .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.v-v1 .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.v-v1 .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.v-v1 .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.v-v1 .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.v-v1 .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.v-v1 .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.v-v1 .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.v-v1 .pt-0 {
  padding-top: 0 !important;
}
.v-v1 .pt-1 {
  padding-top: 0.25rem !important;
}
.v-v1 .pt-2 {
  padding-top: 0.5rem !important;
}
.v-v1 .pt-3 {
  padding-top: 1rem !important;
}
.v-v1 .pt-4 {
  padding-top: 1.5rem !important;
}
.v-v1 .pt-5 {
  padding-top: 3rem !important;
}
.v-v1 .pe-0 {
  padding-right: 0 !important;
}
.v-v1 .pe-1 {
  padding-right: 0.25rem !important;
}
.v-v1 .pe-2 {
  padding-right: 0.5rem !important;
}
.v-v1 .pe-3 {
  padding-right: 1rem !important;
}
.v-v1 .pe-4 {
  padding-right: 1.5rem !important;
}
.v-v1 .pe-5 {
  padding-right: 3rem !important;
}
.v-v1 .pb-0 {
  padding-bottom: 0 !important;
}
.v-v1 .pb-1 {
  padding-bottom: 0.25rem !important;
}
.v-v1 .pb-2 {
  padding-bottom: 0.5rem !important;
}
.v-v1 .pb-3 {
  padding-bottom: 1rem !important;
}
.v-v1 .pb-4 {
  padding-bottom: 1.5rem !important;
}
.v-v1 .pb-5 {
  padding-bottom: 3rem !important;
}
.v-v1 .ps-0 {
  padding-left: 0 !important;
}
.v-v1 .ps-1 {
  padding-left: 0.25rem !important;
}
.v-v1 .ps-2 {
  padding-left: 0.5rem !important;
}
.v-v1 .ps-3 {
  padding-left: 1rem !important;
}
.v-v1 .ps-4 {
  padding-left: 1.5rem !important;
}
.v-v1 .ps-5 {
  padding-left: 3rem !important;
}
.v-v1 .gap-0 {
  gap: 0 !important;
}
.v-v1 .gap-1 {
  gap: 0.25rem !important;
}
.v-v1 .gap-2 {
  gap: 0.5rem !important;
}
.v-v1 .gap-3 {
  gap: 1rem !important;
}
.v-v1 .gap-4 {
  gap: 1.5rem !important;
}
.v-v1 .gap-5 {
  gap: 3rem !important;
}
.v-v1 .row-gap-0 {
  row-gap: 0 !important;
}
.v-v1 .row-gap-1 {
  row-gap: 0.25rem !important;
}
.v-v1 .row-gap-2 {
  row-gap: 0.5rem !important;
}
.v-v1 .row-gap-3 {
  row-gap: 1rem !important;
}
.v-v1 .row-gap-4 {
  row-gap: 1.5rem !important;
}
.v-v1 .row-gap-5 {
  row-gap: 3rem !important;
}
.v-v1 .column-gap-0 {
  column-gap: 0 !important;
}
.v-v1 .column-gap-1 {
  column-gap: 0.25rem !important;
}
.v-v1 .column-gap-2 {
  column-gap: 0.5rem !important;
}
.v-v1 .column-gap-3 {
  column-gap: 1rem !important;
}
.v-v1 .column-gap-4 {
  column-gap: 1.5rem !important;
}
.v-v1 .column-gap-5 {
  column-gap: 3rem !important;
}
.v-v1 .font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.v-v1 .fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.v-v1 .fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.v-v1 .fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.v-v1 .fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.v-v1 .fs-5 {
  font-size: 1.25rem !important;
}
.v-v1 .fs-6 {
  font-size: 1rem !important;
}
.v-v1 .fst-italic {
  font-style: italic !important;
}
.v-v1 .fst-normal {
  font-style: normal !important;
}
.v-v1 .fw-lighter {
  font-weight: lighter !important;
}
.v-v1 .fw-light {
  font-weight: 300 !important;
}
.v-v1 .fw-normal {
  font-weight: 400 !important;
}
.v-v1 .fw-medium {
  font-weight: 500 !important;
}
.v-v1 .fw-semibold {
  font-weight: 600 !important;
}
.v-v1 .fw-bold {
  font-weight: 700 !important;
}
.v-v1 .fw-bolder {
  font-weight: bolder !important;
}
.v-v1 .lh-1 {
  line-height: 1 !important;
}
.v-v1 .lh-sm {
  line-height: 1.25 !important;
}
.v-v1 .lh-base {
  line-height: 1.5 !important;
}
.v-v1 .lh-lg {
  line-height: 2 !important;
}
.v-v1 .text-start {
  text-align: left !important;
}
.v-v1 .text-end {
  text-align: right !important;
}
.v-v1 .text-center {
  text-align: center !important;
}
.v-v1 .text-decoration-none {
  text-decoration: none !important;
}
.v-v1 .text-decoration-underline {
  text-decoration: underline !important;
}
.v-v1 .text-decoration-line-through {
  text-decoration: line-through !important;
}
.v-v1 .text-lowercase {
  text-transform: lowercase !important;
}
.v-v1 .text-uppercase {
  text-transform: uppercase !important;
}
.v-v1 .text-capitalize {
  text-transform: capitalize !important;
}
.v-v1 .text-wrap {
  white-space: normal !important;
}
.v-v1 .text-nowrap {
  white-space: nowrap !important;
}
.v-v1 .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.v-v1 .text-heading-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-1-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-heading-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-2-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-heading-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-3-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-heading-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-4-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-heading-5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-5-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-heading-6 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-heading-6-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-p-lead {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-p-lead-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-p-title {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-p-title-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-p {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-p-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-caption {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-caption-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-glossary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-glossary-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-modal-overlay-backdrop {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-modal-overlay-backdrop-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-modal-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-modal-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-modal-close-icon {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-modal-close-icon-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-arrow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-arrow-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-default-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-default-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-hover-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-hover-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-selected-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-selected-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-selected-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-selected-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-expanded-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-expanded-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-navigation-expanded-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-navigation-expanded-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-tab-selected {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tab-selected-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-tab-deselected {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tab-deselected-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-tab-icon {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tab-icon-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-tab-active-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tab-active-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-language-overlay-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-language-overlay-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-language-overlay-link {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-language-overlay-link-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-language-overlay-link-selected {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-language-overlay-link-selected-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-language-overlay-link-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-link-internal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-link-internal-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-link-external {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-link-external-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-text-selection {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-text-selection-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-text-selection-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-text-selection-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-header-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-header-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-content-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-icon-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-title-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-active-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-active-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-active-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-active-title-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-tabcontrol-tab-active-icon-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-link {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-link-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-label {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-label-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-input-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-input-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-input-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-input-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-input {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-input-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-input-active-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-input-active-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-submit-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-submit-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-submit {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-submit-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-submit-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-submit-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-error-label {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-error-label-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-error-input-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-error-input-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-error-input-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-error-input-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-order-form-error-message {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-order-form-error-message-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-button-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-button-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-button-hover-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-button-hover-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-border-top {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-border-top-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-font-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-font-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-icon {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-icon-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-popover-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-popover-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-icon-icon-hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-icon-icon-hover-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-icon-icon-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-icon-icon-hover-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-share-icon-icon-active-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-share-icon-icon-active-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-li {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-li-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-badge {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-badge-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-badge-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-badge-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-collapsable-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-list-item-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-list-item-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-input-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-input-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-input-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-input-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-input {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-input-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-close {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-close-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-li {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-li-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-li-hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-li-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-li-a {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-list-language {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-collapsable-title-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-collapsable-icon-font {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-collapsable-li-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-report-switcher-popup-collapsable-li-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-input-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-input-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-focus-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-focus-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-icon-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-icon-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-title-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-title-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-message-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-message-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-input-background-button {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-input-background-button-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-input-background-button-hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-input-background-button-hover-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-highlight-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-highlight-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-highlight {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-highlight-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-border-right {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-border-right-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-result-border-bottom {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-result-border-bottom-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-search-result-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-search-result-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-sidebar-border-right {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-sidebar-border-right-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-spinner-background-1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-spinner-background-1-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-spinner-background-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-spinner-background-2-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-spinner-after-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-spinner-after-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-highlight-border-top {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-highlight-border-top-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linedark-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linedark-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linemedium-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linemedium-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linelight-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linelight-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linethin-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linethin-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linetitle-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linetitle-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-linefootnote-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-linefootnote-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-stackable-thtd-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-stackable-thtd-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-color-class {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-color-class-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-desclight-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-desclight-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-number-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-number-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-highlight-color {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-highlight-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-highlight-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-highlight-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-highlight-border {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-highlight-border-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-row-hover-background {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-row-hover-background-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-headerrow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-headerrow-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-description {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-description-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-entry-bold {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-entry-bold-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-table-entry {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-table-entry-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.v-v1 .text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.v-v1 .text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.v-v1 .text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.v-v1 .text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.v-v1 .text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}
.v-v1 .text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}
.v-v1 .text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.v-v1 .text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.v-v1 .text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.v-v1 .text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.v-v1 .text-opacity-100 {
  --bs-text-opacity: 1;
}
.v-v1 .text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}
.v-v1 .text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}
.v-v1 .text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}
.v-v1 .text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}
.v-v1 .text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}
.v-v1 .text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}
.v-v1 .text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}
.v-v1 .text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}
.v-v1 .link-opacity-10 {
  --bs-link-opacity: 0.1;
}
.v-v1 .link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}
.v-v1 .link-opacity-25 {
  --bs-link-opacity: 0.25;
}
.v-v1 .link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}
.v-v1 .link-opacity-50 {
  --bs-link-opacity: 0.5;
}
.v-v1 .link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}
.v-v1 .link-opacity-75 {
  --bs-link-opacity: 0.75;
}
.v-v1 .link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}
.v-v1 .link-opacity-100 {
  --bs-link-opacity: 1;
}
.v-v1 .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}
.v-v1 .link-offset-1 {
  text-underline-offset: 0.125em !important;
}
.v-v1 .link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
.v-v1 .link-offset-2 {
  text-underline-offset: 0.25em !important;
}
.v-v1 .link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
.v-v1 .link-offset-3 {
  text-underline-offset: 0.375em !important;
}
.v-v1 .link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
.v-v1 .link-underline-heading-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-1-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-heading-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-2-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-heading-3 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-3-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-heading-4 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-4-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-heading-5 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-5-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-heading-6 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-heading-6-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-p-lead {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-p-lead-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-p-title {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-p-title-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-p {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-p-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-caption {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-caption-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-glossary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-glossary-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-modal-overlay-backdrop {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-modal-overlay-backdrop-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-modal-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-modal-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-modal-close-icon {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-modal-close-icon-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-arrow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-arrow-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-default-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-default-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-hover-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-hover-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-selected-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-selected-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-selected-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-selected-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-expanded-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-expanded-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-navigation-expanded-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-navigation-expanded-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-tab-selected {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tab-selected-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-tab-deselected {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tab-deselected-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-tab-icon {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tab-icon-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-tab-active-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tab-active-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-language-overlay-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-language-overlay-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-language-overlay-link {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-language-overlay-link-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-language-overlay-link-selected {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-language-overlay-link-selected-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-language-overlay-link-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-link-internal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-internal-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-link-external {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-external-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-text-selection {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-text-selection-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-text-selection-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-text-selection-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-header-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-header-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-content-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-icon-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-title-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-active-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-active-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-active-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-active-title-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-tabcontrol-tab-active-icon-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-link {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-link-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-label {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-label-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-input-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-input-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-input-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-input-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-input {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-input-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-input-active-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-input-active-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-submit-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-submit-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-submit {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-submit-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-submit-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-submit-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-error-label {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-error-label-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-error-input-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-error-input-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-error-input-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-error-input-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-order-form-error-message {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-order-form-error-message-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-button-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-button-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-button-hover-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-button-hover-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-border-top {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-border-top-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-font-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-font-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-icon {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-icon-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-popover-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-popover-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-icon-icon-hover {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-icon-icon-hover-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-icon-icon-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-icon-icon-hover-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-share-icon-icon-active-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-share-icon-icon-active-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-li {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-li-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-badge {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-badge-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-badge-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-badge-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-collapsable-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-list-item-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-list-item-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-input-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-input-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-input-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-input-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-input {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-input-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-close {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-close-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-li {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-li-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-li-hover {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-li-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-li-a {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-list-language {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-collapsable-title-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-collapsable-icon-font {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-collapsable-li-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-report-switcher-popup-collapsable-li-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-input-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-input-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-focus-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-focus-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-icon-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-icon-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-title-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-title-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-message-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-message-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-input-background-button {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-input-background-button-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-input-background-button-hover {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-input-background-button-hover-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-highlight-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-highlight-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-highlight {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-highlight-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-border-right {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-border-right-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-result-border-bottom {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-result-border-bottom-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-search-result-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-search-result-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-sidebar-border-right {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-sidebar-border-right-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-spinner-background-1 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-spinner-background-1-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-spinner-background-2 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-spinner-background-2-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-spinner-after-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-spinner-after-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-highlight-border-top {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-highlight-border-top-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linedark-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linedark-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linemedium-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linemedium-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linelight-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linelight-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linethin-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linethin-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linetitle-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linetitle-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-linefootnote-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-linefootnote-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-stackable-thtd-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-stackable-thtd-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-color-class {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-color-class-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-desclight-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-desclight-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-number-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-number-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-highlight-color {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-highlight-color-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-highlight-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-highlight-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-highlight-border {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-highlight-border-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-row-hover-background {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-row-hover-background-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-headerrow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-headerrow-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-description {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-description-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-entry-bold {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-entry-bold-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline-table-entry {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-table-entry-rgb), var(--bs-link-underline-opacity)) !important;
}
.v-v1 .link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.v-v1 .link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}
.v-v1 .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}
.v-v1 .link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}
.v-v1 .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}
.v-v1 .link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}
.v-v1 .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}
.v-v1 .link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}
.v-v1 .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}
.v-v1 .link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}
.v-v1 .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}
.v-v1 .link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}
.v-v1 .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}
.v-v1 .bg-heading-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-1-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-heading-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-2-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-heading-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-3-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-heading-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-4-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-heading-5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-5-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-heading-6 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-heading-6-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-p-lead {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-p-lead-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-p-title {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-p-title-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-p {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-p-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-caption {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-caption-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-glossary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-glossary-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-modal-overlay-backdrop {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-modal-overlay-backdrop-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-modal-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-modal-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-modal-close-icon {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-modal-close-icon-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-arrow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-arrow-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-default-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-default-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-hover-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-hover-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-selected-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-selected-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-selected-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-selected-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-expanded-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-expanded-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-navigation-expanded-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-navigation-expanded-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-tab-selected {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tab-selected-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-tab-deselected {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tab-deselected-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-tab-icon {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tab-icon-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-tab-active-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tab-active-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-language-overlay-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-language-overlay-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-language-overlay-link {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-language-overlay-link-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-language-overlay-link-selected {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-language-overlay-link-selected-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-language-overlay-link-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-language-overlay-link-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-link-internal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-link-internal-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-link-external {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-link-external-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-text-selection {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-text-selection-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-text-selection-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-text-selection-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-header-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-header-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-header-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-header-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-content-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-content-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-icon-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-icon-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-title-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-title-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-active-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-active-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-active-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-active-title-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-title-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-tabcontrol-tab-active-icon-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-tabcontrol-tab-active-icon-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-link {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-link-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-label {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-label-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-input-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-input-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-input-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-input-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-input {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-input-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-input-active-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-input-active-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-submit-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-submit-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-submit {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-submit-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-submit-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-submit-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-error-label {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-error-label-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-error-input-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-error-input-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-error-input-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-error-input-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-order-form-error-message {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-order-form-error-message-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-button-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-button-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-button-hover-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-button-hover-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-border-top {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-border-top-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-font-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-font-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-icon {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-icon-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-popover-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-popover-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-icon-icon-hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-icon-icon-hover-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-icon-icon-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-icon-icon-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-icon-icon-hover-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-icon-icon-hover-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-share-icon-icon-active-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-share-icon-icon-active-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-li {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-li-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-badge {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-badge-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-badge-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-badge-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-collapsable-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-collapsable-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-list-item-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-list-item-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-input-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-input-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-input-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-input-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-input {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-input-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-close {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-close-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-li {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-li-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-li-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-li-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-li-hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-li-hover-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-li-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-li-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-li-a {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-li-a-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-list-language {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-list-language-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-collapsable-title-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-collapsable-title-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-collapsable-icon-font {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-collapsable-icon-font-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-collapsable-li-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-collapsable-li-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-report-switcher-popup-collapsable-li-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-report-switcher-popup-collapsable-li-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-input-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-input-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-focus-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-focus-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-icon-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-icon-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-title-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-title-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-message-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-message-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-input-background-button {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-input-background-button-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-input-background-button-hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-input-background-button-hover-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-highlight-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-highlight-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-highlight {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-highlight-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-border-right {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-border-right-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-result-border-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-result-border-bottom-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-search-result-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-search-result-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-sidebar-border-right {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-sidebar-border-right-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-spinner-background-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-spinner-background-1-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-spinner-background-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-spinner-background-2-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-spinner-after-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-spinner-after-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-highlight-border-top {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-highlight-border-top-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linedark-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linedark-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linemedium-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linemedium-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linelight-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linelight-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linethin-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linethin-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linetitle-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linetitle-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-linefootnote-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-linefootnote-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-stackable-thtd-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-stackable-thtd-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-color-class {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-color-class-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-desclight-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-desclight-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-number-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-number-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-highlight-color {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-highlight-color-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-highlight-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-highlight-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-highlight-border {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-highlight-border-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-row-hover-background {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-row-hover-background-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-headerrow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-headerrow-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-description {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-description-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-entry-bold {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-entry-bold-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-table-entry {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-table-entry-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.v-v1 .bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}
.v-v1 .bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.v-v1 .bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.v-v1 .bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.v-v1 .bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.v-v1 .bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.v-v1 .bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}
.v-v1 .bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}
.v-v1 .bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
.v-v1 .bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}
.v-v1 .bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}
.v-v1 .bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}
.v-v1 .bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}
.v-v1 .bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}
.v-v1 .bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.v-v1 .user-select-all {
  user-select: all !important;
}
.v-v1 .user-select-auto {
  user-select: auto !important;
}
.v-v1 .user-select-none {
  user-select: none !important;
}
.v-v1 .pe-none {
  pointer-events: none !important;
}
.v-v1 .pe-auto {
  pointer-events: auto !important;
}
.v-v1 .rounded {
  border-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-0 {
  border-radius: 0 !important;
}
.v-v1 .rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.v-v1 .rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.v-v1 .rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.v-v1 .rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}
.v-v1 .rounded-circle {
  border-radius: 50% !important;
}
.v-v1 .rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.v-v1 .rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.v-v1 .rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.v-v1 .rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
.v-v1 .rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
.v-v1 .rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}
.v-v1 .rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.v-v1 .rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
.v-v1 .rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-v1 .rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
.v-v1 .rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
.v-v1 .rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
.v-v1 .rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}
.v-v1 .rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.v-v1 .rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
.v-v1 .rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.v-v1 .rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
.v-v1 .rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
.v-v1 .rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
.v-v1 .rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}
.v-v1 .rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.v-v1 .rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
.v-v1 .rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.v-v1 .rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.v-v1 .rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.v-v1 .rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
.v-v1 .rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
.v-v1 .rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}
.v-v1 .rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.v-v1 .rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
.v-v1 .visible {
  visibility: visible !important;
}
.v-v1 .invisible {
  visibility: hidden !important;
}
.v-v1 .z-n1 {
  z-index: -1 !important;
}
.v-v1 .z-0 {
  z-index: 0 !important;
}
.v-v1 .z-1 {
  z-index: 1 !important;
}
.v-v1 .z-2 {
  z-index: 2 !important;
}
.v-v1 .z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  .v-v1 .float-sm-start {
    float: left !important;
  }
  .v-v1 .float-sm-end {
    float: right !important;
  }
  .v-v1 .float-sm-none {
    float: none !important;
  }
  .v-v1 .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .v-v1 .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .v-v1 .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .v-v1 .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .v-v1 .object-fit-sm-none {
    object-fit: none !important;
  }
  .v-v1 .d-sm-inline {
    display: inline !important;
  }
  .v-v1 .d-sm-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-sm-block {
    display: block !important;
  }
  .v-v1 .d-sm-grid {
    display: grid !important;
  }
  .v-v1 .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-sm-table {
    display: table !important;
  }
  .v-v1 .d-sm-table-row {
    display: table-row !important;
  }
  .v-v1 .d-sm-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-sm-flex {
    display: flex !important;
  }
  .v-v1 .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-sm-none {
    display: none !important;
  }
  .v-v1 .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .v-v1 .flex-sm-row {
    flex-direction: row !important;
  }
  .v-v1 .flex-sm-column {
    flex-direction: column !important;
  }
  .v-v1 .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .v-v1 .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .v-v1 .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .v-v1 .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .v-v1 .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .v-v1 .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .v-v1 .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .v-v1 .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .v-v1 .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .v-v1 .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .v-v1 .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .v-v1 .justify-content-sm-center {
    justify-content: center !important;
  }
  .v-v1 .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .v-v1 .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .v-v1 .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .v-v1 .align-items-sm-start {
    align-items: flex-start !important;
  }
  .v-v1 .align-items-sm-end {
    align-items: flex-end !important;
  }
  .v-v1 .align-items-sm-center {
    align-items: center !important;
  }
  .v-v1 .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .v-v1 .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .v-v1 .align-content-sm-start {
    align-content: flex-start !important;
  }
  .v-v1 .align-content-sm-end {
    align-content: flex-end !important;
  }
  .v-v1 .align-content-sm-center {
    align-content: center !important;
  }
  .v-v1 .align-content-sm-between {
    align-content: space-between !important;
  }
  .v-v1 .align-content-sm-around {
    align-content: space-around !important;
  }
  .v-v1 .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .v-v1 .align-self-sm-auto {
    align-self: auto !important;
  }
  .v-v1 .align-self-sm-start {
    align-self: flex-start !important;
  }
  .v-v1 .align-self-sm-end {
    align-self: flex-end !important;
  }
  .v-v1 .align-self-sm-center {
    align-self: center !important;
  }
  .v-v1 .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .v-v1 .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .v-v1 .order-sm-first {
    order: -1 !important;
  }
  .v-v1 .order-sm-0 {
    order: 0 !important;
  }
  .v-v1 .order-sm-1 {
    order: 1 !important;
  }
  .v-v1 .order-sm-2 {
    order: 2 !important;
  }
  .v-v1 .order-sm-3 {
    order: 3 !important;
  }
  .v-v1 .order-sm-4 {
    order: 4 !important;
  }
  .v-v1 .order-sm-5 {
    order: 5 !important;
  }
  .v-v1 .order-sm-last {
    order: 6 !important;
  }
  .v-v1 .m-sm-0 {
    margin: 0 !important;
  }
  .v-v1 .m-sm-1 {
    margin: 0.25rem !important;
  }
  .v-v1 .m-sm-2 {
    margin: 0.5rem !important;
  }
  .v-v1 .m-sm-3 {
    margin: 1rem !important;
  }
  .v-v1 .m-sm-4 {
    margin: 1.5rem !important;
  }
  .v-v1 .m-sm-5 {
    margin: 3rem !important;
  }
  .v-v1 .m-sm-auto {
    margin: auto !important;
  }
  .v-v1 .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-v1 .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .v-v1 .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .v-v1 .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .v-v1 .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .v-v1 .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .v-v1 .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .v-v1 .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-v1 .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .v-v1 .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .v-v1 .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .v-v1 .mt-sm-0 {
    margin-top: 0 !important;
  }
  .v-v1 .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .v-v1 .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .v-v1 .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .v-v1 .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .v-v1 .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .v-v1 .mt-sm-auto {
    margin-top: auto !important;
  }
  .v-v1 .me-sm-0 {
    margin-right: 0 !important;
  }
  .v-v1 .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .v-v1 .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .v-v1 .me-sm-3 {
    margin-right: 1rem !important;
  }
  .v-v1 .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .v-v1 .me-sm-5 {
    margin-right: 3rem !important;
  }
  .v-v1 .me-sm-auto {
    margin-right: auto !important;
  }
  .v-v1 .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .v-v1 .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .v-v1 .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .v-v1 .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .v-v1 .ms-sm-0 {
    margin-left: 0 !important;
  }
  .v-v1 .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .v-v1 .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .v-v1 .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .v-v1 .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .v-v1 .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .v-v1 .ms-sm-auto {
    margin-left: auto !important;
  }
  .v-v1 .p-sm-0 {
    padding: 0 !important;
  }
  .v-v1 .p-sm-1 {
    padding: 0.25rem !important;
  }
  .v-v1 .p-sm-2 {
    padding: 0.5rem !important;
  }
  .v-v1 .p-sm-3 {
    padding: 1rem !important;
  }
  .v-v1 .p-sm-4 {
    padding: 1.5rem !important;
  }
  .v-v1 .p-sm-5 {
    padding: 3rem !important;
  }
  .v-v1 .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-v1 .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .v-v1 .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .v-v1 .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .v-v1 .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .v-v1 .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .v-v1 .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-v1 .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .v-v1 .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .v-v1 .pt-sm-0 {
    padding-top: 0 !important;
  }
  .v-v1 .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .v-v1 .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .v-v1 .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .v-v1 .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .v-v1 .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .v-v1 .pe-sm-0 {
    padding-right: 0 !important;
  }
  .v-v1 .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .v-v1 .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .v-v1 .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .v-v1 .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .v-v1 .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .v-v1 .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .v-v1 .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .v-v1 .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .v-v1 .ps-sm-0 {
    padding-left: 0 !important;
  }
  .v-v1 .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .v-v1 .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .v-v1 .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .v-v1 .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .v-v1 .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .v-v1 .gap-sm-0 {
    gap: 0 !important;
  }
  .v-v1 .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .v-v1 .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .v-v1 .gap-sm-3 {
    gap: 1rem !important;
  }
  .v-v1 .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .v-v1 .gap-sm-5 {
    gap: 3rem !important;
  }
  .v-v1 .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .v-v1 .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .v-v1 .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .v-v1 .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .v-v1 .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .v-v1 .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .v-v1 .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .v-v1 .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .v-v1 .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .v-v1 .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .v-v1 .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .v-v1 .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .v-v1 .text-sm-start {
    text-align: left !important;
  }
  .v-v1 .text-sm-end {
    text-align: right !important;
  }
  .v-v1 .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .v-v1 .float-md-start {
    float: left !important;
  }
  .v-v1 .float-md-end {
    float: right !important;
  }
  .v-v1 .float-md-none {
    float: none !important;
  }
  .v-v1 .object-fit-md-contain {
    object-fit: contain !important;
  }
  .v-v1 .object-fit-md-cover {
    object-fit: cover !important;
  }
  .v-v1 .object-fit-md-fill {
    object-fit: fill !important;
  }
  .v-v1 .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .v-v1 .object-fit-md-none {
    object-fit: none !important;
  }
  .v-v1 .d-md-inline {
    display: inline !important;
  }
  .v-v1 .d-md-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-md-block {
    display: block !important;
  }
  .v-v1 .d-md-grid {
    display: grid !important;
  }
  .v-v1 .d-md-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-md-table {
    display: table !important;
  }
  .v-v1 .d-md-table-row {
    display: table-row !important;
  }
  .v-v1 .d-md-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-md-flex {
    display: flex !important;
  }
  .v-v1 .d-md-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-md-none {
    display: none !important;
  }
  .v-v1 .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .v-v1 .flex-md-row {
    flex-direction: row !important;
  }
  .v-v1 .flex-md-column {
    flex-direction: column !important;
  }
  .v-v1 .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .v-v1 .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .v-v1 .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .v-v1 .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .v-v1 .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .v-v1 .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .v-v1 .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .v-v1 .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .v-v1 .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .v-v1 .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .v-v1 .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .v-v1 .justify-content-md-center {
    justify-content: center !important;
  }
  .v-v1 .justify-content-md-between {
    justify-content: space-between !important;
  }
  .v-v1 .justify-content-md-around {
    justify-content: space-around !important;
  }
  .v-v1 .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .v-v1 .align-items-md-start {
    align-items: flex-start !important;
  }
  .v-v1 .align-items-md-end {
    align-items: flex-end !important;
  }
  .v-v1 .align-items-md-center {
    align-items: center !important;
  }
  .v-v1 .align-items-md-baseline {
    align-items: baseline !important;
  }
  .v-v1 .align-items-md-stretch {
    align-items: stretch !important;
  }
  .v-v1 .align-content-md-start {
    align-content: flex-start !important;
  }
  .v-v1 .align-content-md-end {
    align-content: flex-end !important;
  }
  .v-v1 .align-content-md-center {
    align-content: center !important;
  }
  .v-v1 .align-content-md-between {
    align-content: space-between !important;
  }
  .v-v1 .align-content-md-around {
    align-content: space-around !important;
  }
  .v-v1 .align-content-md-stretch {
    align-content: stretch !important;
  }
  .v-v1 .align-self-md-auto {
    align-self: auto !important;
  }
  .v-v1 .align-self-md-start {
    align-self: flex-start !important;
  }
  .v-v1 .align-self-md-end {
    align-self: flex-end !important;
  }
  .v-v1 .align-self-md-center {
    align-self: center !important;
  }
  .v-v1 .align-self-md-baseline {
    align-self: baseline !important;
  }
  .v-v1 .align-self-md-stretch {
    align-self: stretch !important;
  }
  .v-v1 .order-md-first {
    order: -1 !important;
  }
  .v-v1 .order-md-0 {
    order: 0 !important;
  }
  .v-v1 .order-md-1 {
    order: 1 !important;
  }
  .v-v1 .order-md-2 {
    order: 2 !important;
  }
  .v-v1 .order-md-3 {
    order: 3 !important;
  }
  .v-v1 .order-md-4 {
    order: 4 !important;
  }
  .v-v1 .order-md-5 {
    order: 5 !important;
  }
  .v-v1 .order-md-last {
    order: 6 !important;
  }
  .v-v1 .m-md-0 {
    margin: 0 !important;
  }
  .v-v1 .m-md-1 {
    margin: 0.25rem !important;
  }
  .v-v1 .m-md-2 {
    margin: 0.5rem !important;
  }
  .v-v1 .m-md-3 {
    margin: 1rem !important;
  }
  .v-v1 .m-md-4 {
    margin: 1.5rem !important;
  }
  .v-v1 .m-md-5 {
    margin: 3rem !important;
  }
  .v-v1 .m-md-auto {
    margin: auto !important;
  }
  .v-v1 .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-v1 .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .v-v1 .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .v-v1 .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .v-v1 .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .v-v1 .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .v-v1 .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .v-v1 .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-v1 .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .v-v1 .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .v-v1 .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .v-v1 .mt-md-0 {
    margin-top: 0 !important;
  }
  .v-v1 .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .v-v1 .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .v-v1 .mt-md-3 {
    margin-top: 1rem !important;
  }
  .v-v1 .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .v-v1 .mt-md-5 {
    margin-top: 3rem !important;
  }
  .v-v1 .mt-md-auto {
    margin-top: auto !important;
  }
  .v-v1 .me-md-0 {
    margin-right: 0 !important;
  }
  .v-v1 .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .v-v1 .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .v-v1 .me-md-3 {
    margin-right: 1rem !important;
  }
  .v-v1 .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .v-v1 .me-md-5 {
    margin-right: 3rem !important;
  }
  .v-v1 .me-md-auto {
    margin-right: auto !important;
  }
  .v-v1 .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .v-v1 .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .v-v1 .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .v-v1 .mb-md-auto {
    margin-bottom: auto !important;
  }
  .v-v1 .ms-md-0 {
    margin-left: 0 !important;
  }
  .v-v1 .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .v-v1 .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .v-v1 .ms-md-3 {
    margin-left: 1rem !important;
  }
  .v-v1 .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .v-v1 .ms-md-5 {
    margin-left: 3rem !important;
  }
  .v-v1 .ms-md-auto {
    margin-left: auto !important;
  }
  .v-v1 .p-md-0 {
    padding: 0 !important;
  }
  .v-v1 .p-md-1 {
    padding: 0.25rem !important;
  }
  .v-v1 .p-md-2 {
    padding: 0.5rem !important;
  }
  .v-v1 .p-md-3 {
    padding: 1rem !important;
  }
  .v-v1 .p-md-4 {
    padding: 1.5rem !important;
  }
  .v-v1 .p-md-5 {
    padding: 3rem !important;
  }
  .v-v1 .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-v1 .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .v-v1 .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .v-v1 .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .v-v1 .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .v-v1 .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .v-v1 .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-v1 .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .v-v1 .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .v-v1 .pt-md-0 {
    padding-top: 0 !important;
  }
  .v-v1 .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .v-v1 .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .v-v1 .pt-md-3 {
    padding-top: 1rem !important;
  }
  .v-v1 .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .v-v1 .pt-md-5 {
    padding-top: 3rem !important;
  }
  .v-v1 .pe-md-0 {
    padding-right: 0 !important;
  }
  .v-v1 .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .v-v1 .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .v-v1 .pe-md-3 {
    padding-right: 1rem !important;
  }
  .v-v1 .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .v-v1 .pe-md-5 {
    padding-right: 3rem !important;
  }
  .v-v1 .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .v-v1 .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .v-v1 .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .v-v1 .ps-md-0 {
    padding-left: 0 !important;
  }
  .v-v1 .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .v-v1 .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .v-v1 .ps-md-3 {
    padding-left: 1rem !important;
  }
  .v-v1 .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .v-v1 .ps-md-5 {
    padding-left: 3rem !important;
  }
  .v-v1 .gap-md-0 {
    gap: 0 !important;
  }
  .v-v1 .gap-md-1 {
    gap: 0.25rem !important;
  }
  .v-v1 .gap-md-2 {
    gap: 0.5rem !important;
  }
  .v-v1 .gap-md-3 {
    gap: 1rem !important;
  }
  .v-v1 .gap-md-4 {
    gap: 1.5rem !important;
  }
  .v-v1 .gap-md-5 {
    gap: 3rem !important;
  }
  .v-v1 .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .v-v1 .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .v-v1 .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .v-v1 .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .v-v1 .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .v-v1 .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .v-v1 .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .v-v1 .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .v-v1 .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .v-v1 .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .v-v1 .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .v-v1 .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .v-v1 .text-md-start {
    text-align: left !important;
  }
  .v-v1 .text-md-end {
    text-align: right !important;
  }
  .v-v1 .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .v-v1 .float-lg-start {
    float: left !important;
  }
  .v-v1 .float-lg-end {
    float: right !important;
  }
  .v-v1 .float-lg-none {
    float: none !important;
  }
  .v-v1 .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .v-v1 .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .v-v1 .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .v-v1 .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .v-v1 .object-fit-lg-none {
    object-fit: none !important;
  }
  .v-v1 .d-lg-inline {
    display: inline !important;
  }
  .v-v1 .d-lg-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-lg-block {
    display: block !important;
  }
  .v-v1 .d-lg-grid {
    display: grid !important;
  }
  .v-v1 .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-lg-table {
    display: table !important;
  }
  .v-v1 .d-lg-table-row {
    display: table-row !important;
  }
  .v-v1 .d-lg-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-lg-flex {
    display: flex !important;
  }
  .v-v1 .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-lg-none {
    display: none !important;
  }
  .v-v1 .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .v-v1 .flex-lg-row {
    flex-direction: row !important;
  }
  .v-v1 .flex-lg-column {
    flex-direction: column !important;
  }
  .v-v1 .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .v-v1 .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .v-v1 .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .v-v1 .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .v-v1 .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .v-v1 .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .v-v1 .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .v-v1 .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .v-v1 .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .v-v1 .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .v-v1 .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .v-v1 .justify-content-lg-center {
    justify-content: center !important;
  }
  .v-v1 .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .v-v1 .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .v-v1 .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .v-v1 .align-items-lg-start {
    align-items: flex-start !important;
  }
  .v-v1 .align-items-lg-end {
    align-items: flex-end !important;
  }
  .v-v1 .align-items-lg-center {
    align-items: center !important;
  }
  .v-v1 .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .v-v1 .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .v-v1 .align-content-lg-start {
    align-content: flex-start !important;
  }
  .v-v1 .align-content-lg-end {
    align-content: flex-end !important;
  }
  .v-v1 .align-content-lg-center {
    align-content: center !important;
  }
  .v-v1 .align-content-lg-between {
    align-content: space-between !important;
  }
  .v-v1 .align-content-lg-around {
    align-content: space-around !important;
  }
  .v-v1 .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .v-v1 .align-self-lg-auto {
    align-self: auto !important;
  }
  .v-v1 .align-self-lg-start {
    align-self: flex-start !important;
  }
  .v-v1 .align-self-lg-end {
    align-self: flex-end !important;
  }
  .v-v1 .align-self-lg-center {
    align-self: center !important;
  }
  .v-v1 .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .v-v1 .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .v-v1 .order-lg-first {
    order: -1 !important;
  }
  .v-v1 .order-lg-0 {
    order: 0 !important;
  }
  .v-v1 .order-lg-1 {
    order: 1 !important;
  }
  .v-v1 .order-lg-2 {
    order: 2 !important;
  }
  .v-v1 .order-lg-3 {
    order: 3 !important;
  }
  .v-v1 .order-lg-4 {
    order: 4 !important;
  }
  .v-v1 .order-lg-5 {
    order: 5 !important;
  }
  .v-v1 .order-lg-last {
    order: 6 !important;
  }
  .v-v1 .m-lg-0 {
    margin: 0 !important;
  }
  .v-v1 .m-lg-1 {
    margin: 0.25rem !important;
  }
  .v-v1 .m-lg-2 {
    margin: 0.5rem !important;
  }
  .v-v1 .m-lg-3 {
    margin: 1rem !important;
  }
  .v-v1 .m-lg-4 {
    margin: 1.5rem !important;
  }
  .v-v1 .m-lg-5 {
    margin: 3rem !important;
  }
  .v-v1 .m-lg-auto {
    margin: auto !important;
  }
  .v-v1 .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-v1 .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .v-v1 .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .v-v1 .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .v-v1 .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .v-v1 .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .v-v1 .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .v-v1 .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-v1 .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .v-v1 .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .v-v1 .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .v-v1 .mt-lg-0 {
    margin-top: 0 !important;
  }
  .v-v1 .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .v-v1 .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .v-v1 .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .v-v1 .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .v-v1 .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .v-v1 .mt-lg-auto {
    margin-top: auto !important;
  }
  .v-v1 .me-lg-0 {
    margin-right: 0 !important;
  }
  .v-v1 .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .v-v1 .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .v-v1 .me-lg-3 {
    margin-right: 1rem !important;
  }
  .v-v1 .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .v-v1 .me-lg-5 {
    margin-right: 3rem !important;
  }
  .v-v1 .me-lg-auto {
    margin-right: auto !important;
  }
  .v-v1 .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .v-v1 .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .v-v1 .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .v-v1 .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .v-v1 .ms-lg-0 {
    margin-left: 0 !important;
  }
  .v-v1 .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .v-v1 .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .v-v1 .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .v-v1 .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .v-v1 .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .v-v1 .ms-lg-auto {
    margin-left: auto !important;
  }
  .v-v1 .p-lg-0 {
    padding: 0 !important;
  }
  .v-v1 .p-lg-1 {
    padding: 0.25rem !important;
  }
  .v-v1 .p-lg-2 {
    padding: 0.5rem !important;
  }
  .v-v1 .p-lg-3 {
    padding: 1rem !important;
  }
  .v-v1 .p-lg-4 {
    padding: 1.5rem !important;
  }
  .v-v1 .p-lg-5 {
    padding: 3rem !important;
  }
  .v-v1 .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-v1 .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .v-v1 .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .v-v1 .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .v-v1 .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .v-v1 .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .v-v1 .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-v1 .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .v-v1 .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .v-v1 .pt-lg-0 {
    padding-top: 0 !important;
  }
  .v-v1 .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .v-v1 .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .v-v1 .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .v-v1 .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .v-v1 .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .v-v1 .pe-lg-0 {
    padding-right: 0 !important;
  }
  .v-v1 .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .v-v1 .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .v-v1 .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .v-v1 .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .v-v1 .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .v-v1 .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .v-v1 .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .v-v1 .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .v-v1 .ps-lg-0 {
    padding-left: 0 !important;
  }
  .v-v1 .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .v-v1 .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .v-v1 .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .v-v1 .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .v-v1 .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .v-v1 .gap-lg-0 {
    gap: 0 !important;
  }
  .v-v1 .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .v-v1 .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .v-v1 .gap-lg-3 {
    gap: 1rem !important;
  }
  .v-v1 .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .v-v1 .gap-lg-5 {
    gap: 3rem !important;
  }
  .v-v1 .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .v-v1 .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .v-v1 .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .v-v1 .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .v-v1 .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .v-v1 .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .v-v1 .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .v-v1 .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .v-v1 .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .v-v1 .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .v-v1 .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .v-v1 .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .v-v1 .text-lg-start {
    text-align: left !important;
  }
  .v-v1 .text-lg-end {
    text-align: right !important;
  }
  .v-v1 .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .v-v1 .float-xl-start {
    float: left !important;
  }
  .v-v1 .float-xl-end {
    float: right !important;
  }
  .v-v1 .float-xl-none {
    float: none !important;
  }
  .v-v1 .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .v-v1 .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .v-v1 .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .v-v1 .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .v-v1 .object-fit-xl-none {
    object-fit: none !important;
  }
  .v-v1 .d-xl-inline {
    display: inline !important;
  }
  .v-v1 .d-xl-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-xl-block {
    display: block !important;
  }
  .v-v1 .d-xl-grid {
    display: grid !important;
  }
  .v-v1 .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-xl-table {
    display: table !important;
  }
  .v-v1 .d-xl-table-row {
    display: table-row !important;
  }
  .v-v1 .d-xl-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-xl-flex {
    display: flex !important;
  }
  .v-v1 .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-xl-none {
    display: none !important;
  }
  .v-v1 .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .v-v1 .flex-xl-row {
    flex-direction: row !important;
  }
  .v-v1 .flex-xl-column {
    flex-direction: column !important;
  }
  .v-v1 .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .v-v1 .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .v-v1 .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .v-v1 .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .v-v1 .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .v-v1 .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .v-v1 .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .v-v1 .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .v-v1 .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .v-v1 .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .v-v1 .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .v-v1 .justify-content-xl-center {
    justify-content: center !important;
  }
  .v-v1 .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .v-v1 .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .v-v1 .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .v-v1 .align-items-xl-start {
    align-items: flex-start !important;
  }
  .v-v1 .align-items-xl-end {
    align-items: flex-end !important;
  }
  .v-v1 .align-items-xl-center {
    align-items: center !important;
  }
  .v-v1 .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .v-v1 .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .v-v1 .align-content-xl-start {
    align-content: flex-start !important;
  }
  .v-v1 .align-content-xl-end {
    align-content: flex-end !important;
  }
  .v-v1 .align-content-xl-center {
    align-content: center !important;
  }
  .v-v1 .align-content-xl-between {
    align-content: space-between !important;
  }
  .v-v1 .align-content-xl-around {
    align-content: space-around !important;
  }
  .v-v1 .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .v-v1 .align-self-xl-auto {
    align-self: auto !important;
  }
  .v-v1 .align-self-xl-start {
    align-self: flex-start !important;
  }
  .v-v1 .align-self-xl-end {
    align-self: flex-end !important;
  }
  .v-v1 .align-self-xl-center {
    align-self: center !important;
  }
  .v-v1 .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .v-v1 .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .v-v1 .order-xl-first {
    order: -1 !important;
  }
  .v-v1 .order-xl-0 {
    order: 0 !important;
  }
  .v-v1 .order-xl-1 {
    order: 1 !important;
  }
  .v-v1 .order-xl-2 {
    order: 2 !important;
  }
  .v-v1 .order-xl-3 {
    order: 3 !important;
  }
  .v-v1 .order-xl-4 {
    order: 4 !important;
  }
  .v-v1 .order-xl-5 {
    order: 5 !important;
  }
  .v-v1 .order-xl-last {
    order: 6 !important;
  }
  .v-v1 .m-xl-0 {
    margin: 0 !important;
  }
  .v-v1 .m-xl-1 {
    margin: 0.25rem !important;
  }
  .v-v1 .m-xl-2 {
    margin: 0.5rem !important;
  }
  .v-v1 .m-xl-3 {
    margin: 1rem !important;
  }
  .v-v1 .m-xl-4 {
    margin: 1.5rem !important;
  }
  .v-v1 .m-xl-5 {
    margin: 3rem !important;
  }
  .v-v1 .m-xl-auto {
    margin: auto !important;
  }
  .v-v1 .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-v1 .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .v-v1 .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .v-v1 .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .v-v1 .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .v-v1 .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .v-v1 .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .v-v1 .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-v1 .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .v-v1 .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .v-v1 .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .v-v1 .mt-xl-0 {
    margin-top: 0 !important;
  }
  .v-v1 .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .v-v1 .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .v-v1 .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .v-v1 .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .v-v1 .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .v-v1 .mt-xl-auto {
    margin-top: auto !important;
  }
  .v-v1 .me-xl-0 {
    margin-right: 0 !important;
  }
  .v-v1 .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .v-v1 .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .v-v1 .me-xl-3 {
    margin-right: 1rem !important;
  }
  .v-v1 .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .v-v1 .me-xl-5 {
    margin-right: 3rem !important;
  }
  .v-v1 .me-xl-auto {
    margin-right: auto !important;
  }
  .v-v1 .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .v-v1 .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .v-v1 .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .v-v1 .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .v-v1 .ms-xl-0 {
    margin-left: 0 !important;
  }
  .v-v1 .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .v-v1 .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .v-v1 .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .v-v1 .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .v-v1 .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .v-v1 .ms-xl-auto {
    margin-left: auto !important;
  }
  .v-v1 .p-xl-0 {
    padding: 0 !important;
  }
  .v-v1 .p-xl-1 {
    padding: 0.25rem !important;
  }
  .v-v1 .p-xl-2 {
    padding: 0.5rem !important;
  }
  .v-v1 .p-xl-3 {
    padding: 1rem !important;
  }
  .v-v1 .p-xl-4 {
    padding: 1.5rem !important;
  }
  .v-v1 .p-xl-5 {
    padding: 3rem !important;
  }
  .v-v1 .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-v1 .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .v-v1 .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .v-v1 .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .v-v1 .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .v-v1 .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .v-v1 .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-v1 .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .v-v1 .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .v-v1 .pt-xl-0 {
    padding-top: 0 !important;
  }
  .v-v1 .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .v-v1 .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .v-v1 .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .v-v1 .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .v-v1 .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .v-v1 .pe-xl-0 {
    padding-right: 0 !important;
  }
  .v-v1 .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .v-v1 .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .v-v1 .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .v-v1 .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .v-v1 .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .v-v1 .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .v-v1 .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .v-v1 .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .v-v1 .ps-xl-0 {
    padding-left: 0 !important;
  }
  .v-v1 .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .v-v1 .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .v-v1 .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .v-v1 .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .v-v1 .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .v-v1 .gap-xl-0 {
    gap: 0 !important;
  }
  .v-v1 .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .v-v1 .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .v-v1 .gap-xl-3 {
    gap: 1rem !important;
  }
  .v-v1 .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .v-v1 .gap-xl-5 {
    gap: 3rem !important;
  }
  .v-v1 .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .v-v1 .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .v-v1 .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .v-v1 .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .v-v1 .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .v-v1 .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .v-v1 .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .v-v1 .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .v-v1 .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .v-v1 .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .v-v1 .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .v-v1 .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .v-v1 .text-xl-start {
    text-align: left !important;
  }
  .v-v1 .text-xl-end {
    text-align: right !important;
  }
  .v-v1 .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .v-v1 .float-xxl-start {
    float: left !important;
  }
  .v-v1 .float-xxl-end {
    float: right !important;
  }
  .v-v1 .float-xxl-none {
    float: none !important;
  }
  .v-v1 .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .v-v1 .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .v-v1 .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .v-v1 .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .v-v1 .object-fit-xxl-none {
    object-fit: none !important;
  }
  .v-v1 .d-xxl-inline {
    display: inline !important;
  }
  .v-v1 .d-xxl-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-xxl-block {
    display: block !important;
  }
  .v-v1 .d-xxl-grid {
    display: grid !important;
  }
  .v-v1 .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-xxl-table {
    display: table !important;
  }
  .v-v1 .d-xxl-table-row {
    display: table-row !important;
  }
  .v-v1 .d-xxl-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-xxl-flex {
    display: flex !important;
  }
  .v-v1 .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-xxl-none {
    display: none !important;
  }
  .v-v1 .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .v-v1 .flex-xxl-row {
    flex-direction: row !important;
  }
  .v-v1 .flex-xxl-column {
    flex-direction: column !important;
  }
  .v-v1 .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .v-v1 .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .v-v1 .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .v-v1 .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .v-v1 .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .v-v1 .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .v-v1 .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .v-v1 .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .v-v1 .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .v-v1 .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .v-v1 .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .v-v1 .justify-content-xxl-center {
    justify-content: center !important;
  }
  .v-v1 .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .v-v1 .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .v-v1 .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .v-v1 .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .v-v1 .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .v-v1 .align-items-xxl-center {
    align-items: center !important;
  }
  .v-v1 .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .v-v1 .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .v-v1 .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .v-v1 .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .v-v1 .align-content-xxl-center {
    align-content: center !important;
  }
  .v-v1 .align-content-xxl-between {
    align-content: space-between !important;
  }
  .v-v1 .align-content-xxl-around {
    align-content: space-around !important;
  }
  .v-v1 .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .v-v1 .align-self-xxl-auto {
    align-self: auto !important;
  }
  .v-v1 .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .v-v1 .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .v-v1 .align-self-xxl-center {
    align-self: center !important;
  }
  .v-v1 .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .v-v1 .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .v-v1 .order-xxl-first {
    order: -1 !important;
  }
  .v-v1 .order-xxl-0 {
    order: 0 !important;
  }
  .v-v1 .order-xxl-1 {
    order: 1 !important;
  }
  .v-v1 .order-xxl-2 {
    order: 2 !important;
  }
  .v-v1 .order-xxl-3 {
    order: 3 !important;
  }
  .v-v1 .order-xxl-4 {
    order: 4 !important;
  }
  .v-v1 .order-xxl-5 {
    order: 5 !important;
  }
  .v-v1 .order-xxl-last {
    order: 6 !important;
  }
  .v-v1 .m-xxl-0 {
    margin: 0 !important;
  }
  .v-v1 .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .v-v1 .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .v-v1 .m-xxl-3 {
    margin: 1rem !important;
  }
  .v-v1 .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .v-v1 .m-xxl-5 {
    margin: 3rem !important;
  }
  .v-v1 .m-xxl-auto {
    margin: auto !important;
  }
  .v-v1 .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-v1 .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .v-v1 .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .v-v1 .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .v-v1 .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .v-v1 .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .v-v1 .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .v-v1 .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-v1 .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .v-v1 .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .v-v1 .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .v-v1 .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .v-v1 .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .v-v1 .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .v-v1 .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .v-v1 .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .v-v1 .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .v-v1 .mt-xxl-auto {
    margin-top: auto !important;
  }
  .v-v1 .me-xxl-0 {
    margin-right: 0 !important;
  }
  .v-v1 .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .v-v1 .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .v-v1 .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .v-v1 .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .v-v1 .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .v-v1 .me-xxl-auto {
    margin-right: auto !important;
  }
  .v-v1 .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .v-v1 .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .v-v1 .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .v-v1 .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .v-v1 .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .v-v1 .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .v-v1 .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .v-v1 .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .v-v1 .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .v-v1 .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .v-v1 .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .v-v1 .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .v-v1 .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .v-v1 .ms-xxl-auto {
    margin-left: auto !important;
  }
  .v-v1 .p-xxl-0 {
    padding: 0 !important;
  }
  .v-v1 .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .v-v1 .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .v-v1 .p-xxl-3 {
    padding: 1rem !important;
  }
  .v-v1 .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .v-v1 .p-xxl-5 {
    padding: 3rem !important;
  }
  .v-v1 .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .v-v1 .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .v-v1 .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .v-v1 .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .v-v1 .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .v-v1 .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .v-v1 .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-v1 .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .v-v1 .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .v-v1 .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .v-v1 .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .v-v1 .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .v-v1 .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .v-v1 .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .v-v1 .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .v-v1 .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .v-v1 .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .v-v1 .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .v-v1 .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .v-v1 .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .v-v1 .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .v-v1 .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .v-v1 .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .v-v1 .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .v-v1 .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .v-v1 .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .v-v1 .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .v-v1 .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .v-v1 .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .v-v1 .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .v-v1 .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .v-v1 .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .v-v1 .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .v-v1 .gap-xxl-0 {
    gap: 0 !important;
  }
  .v-v1 .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .v-v1 .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .v-v1 .gap-xxl-3 {
    gap: 1rem !important;
  }
  .v-v1 .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .v-v1 .gap-xxl-5 {
    gap: 3rem !important;
  }
  .v-v1 .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .v-v1 .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .v-v1 .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .v-v1 .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .v-v1 .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .v-v1 .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .v-v1 .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .v-v1 .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .v-v1 .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .v-v1 .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .v-v1 .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .v-v1 .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .v-v1 .text-xxl-start {
    text-align: left !important;
  }
  .v-v1 .text-xxl-end {
    text-align: right !important;
  }
  .v-v1 .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .v-v1 .fs-1 {
    font-size: 2.5rem !important;
  }
  .v-v1 .fs-2 {
    font-size: 2rem !important;
  }
  .v-v1 .fs-3 {
    font-size: 1.75rem !important;
  }
  .v-v1 .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .v-v1 .d-print-inline {
    display: inline !important;
  }
  .v-v1 .d-print-inline-block {
    display: inline-block !important;
  }
  .v-v1 .d-print-block {
    display: block !important;
  }
  .v-v1 .d-print-grid {
    display: grid !important;
  }
  .v-v1 .d-print-inline-grid {
    display: inline-grid !important;
  }
  .v-v1 .d-print-table {
    display: table !important;
  }
  .v-v1 .d-print-table-row {
    display: table-row !important;
  }
  .v-v1 .d-print-table-cell {
    display: table-cell !important;
  }
  .v-v1 .d-print-flex {
    display: flex !important;
  }
  .v-v1 .d-print-inline-flex {
    display: inline-flex !important;
  }
  .v-v1 .d-print-none {
    display: none !important;
  }
}
.v-v1 :root,
.v-v1 [data-bs-theme=light] {
  --bs-blue: #001155;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #d82236;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-heading-1: #333333;
  --bs-heading-2: #333333;
  --bs-heading-3: #086adb;
  --bs-heading-4: #086adb;
  --bs-heading-5: #333333;
  --bs-heading-6: #333333;
  --bs-p-lead: #333333;
  --bs-p-title: #333333;
  --bs-p: #333333;
  --bs-caption: #333333;
  --bs-glossary: #333333;
  --bs-modal-overlay-backdrop: rgba(0, 0, 0, 0.3);
  --bs-modal-background: #ffffff;
  --bs-modal-close-icon: #333333;
  --bs-navigation-arrow: #333333;
  --bs-navigation-background: #ffffff;
  --bs-navigation-default-border-bottom: transparent;
  --bs-navigation-color: #333333;
  --bs-navigation-hover-color: #333333;
  --bs-navigation-hover-background: #f8fafb;
  --bs-navigation-selected-color: #333333;
  --bs-navigation-selected-background: #eef3f6;
  --bs-navigation-expanded-color: #333333;
  --bs-navigation-expanded-background: #ffffff;
  --bs-tab-selected: #ffffff;
  --bs-tab-deselected: #f8fafb;
  --bs-tab-icon: #015;
  --bs-tab-active-border-bottom: #ffffff;
  --bs-language-overlay-background: rgba(0, 0, 0, 0.3);
  --bs-language-overlay-link: #015;
  --bs-language-overlay-link-selected: #ffffff;
  --bs-language-overlay-link-hover-background: #1781e3;
  --bs-link-internal: #086adb;
  --bs-link-external: #000000;
  --bs-text-selection: #bde0f2;
  --bs-text-selection-color: #000000;
  --bs-primary: #333333;
  --bs-secondary: #333333;
  --bs-white: #ffffff;
  --bs-black: #000000;
  --bs-sidebar-tabcontrol-background: #ffffff;
  --bs-sidebar-tabcontrol-header-background: #ffffff;
  --bs-sidebar-tabcontrol-header-border-bottom: transparent;
  --bs-sidebar-tabcontrol-content-background: #ffffff;
  --bs-sidebar-tabcontrol-tab-border: transparent;
  --bs-sidebar-tabcontrol-tab-border-bottom: transparent;
  --bs-sidebar-tabcontrol-tab-background: #f8fafb;
  --bs-sidebar-tabcontrol-tab-icon-color: #333333;
  --bs-sidebar-tabcontrol-tab-title-color: #333333;
  --bs-sidebar-tabcontrol-tab-active-border: #ffffff;
  --bs-sidebar-tabcontrol-tab-active-border-bottom: transparent;
  --bs-sidebar-tabcontrol-tab-active-background: #ffffff;
  --bs-sidebar-tabcontrol-tab-active-title-color: #000000;
  --bs-sidebar-tabcontrol-tab-active-icon-color: #000000;
  --bs-order-form: #333333;
  --bs-order-form-link: #333333;
  --bs-order-form-label: #333333;
  --bs-order-form-input-border: #333333;
  --bs-order-form-input-background: #333333;
  --bs-order-form-input: #333333;
  --bs-order-form-input-active-border: #333333;
  --bs-order-form-submit-background: #333333;
  --bs-order-form-submit: #ffffff;
  --bs-order-form-submit-hover-background: rgba(0, 0, 0, 0.3);
  --bs-order-form-error-label: #333333;
  --bs-order-form-error-input-background: #333333;
  --bs-order-form-error-input-border: #333333;
  --bs-order-form-error-message: #333333;
  --bs-share-button-color: #333333;
  --bs-share-button-hover-color: #333333;
  --bs-share-border: #1781e3;
  --bs-share-border-top: #1781e3;
  --bs-share-background: #f0f0f0;
  --bs-share-font-color: #333333;
  --bs-share-icon: #1781e3;
  --bs-share-popover-border: transparent;
  --bs-share-icon-icon-hover: #ffffff;
  --bs-share-icon-icon-hover-background: #1781e3;
  --bs-share-icon-icon-hover-border: transparent;
  --bs-share-icon-icon-active-border: #ffffff;
  --bs-report-switcher-li: #333333;
  --bs-report-switcher-badge: #333333;
  --bs-report-switcher-badge-background: #ffffff;
  --bs-report-switcher-collapsable-border-bottom: #333333;
  --bs-report-switcher-list-item-color: #333333;
  --bs-report-switcher-input-background: #ffffff;
  --bs-report-switcher-input-border: #e4e9ec;
  --bs-report-switcher-input: #333333;
  --bs-report-switcher-popup-background: #ffffff;
  --bs-report-switcher-popup-close: #1781e3;
  --bs-report-switcher-popup-list-li: #333333;
  --bs-report-switcher-popup-list-li-border-bottom: #1781e3;
  --bs-report-switcher-popup-list-li-hover: #333333;
  --bs-report-switcher-popup-list-li-hover-background: #d1e6f9;
  --bs-report-switcher-popup-list-li-a: #333333;
  --bs-report-switcher-popup-list-language: #333333;
  --bs-report-switcher-popup-collapsable-title-border-bottom: #333333;
  --bs-report-switcher-popup-collapsable-icon-font: #333333;
  --bs-report-switcher-popup-collapsable-li-border-bottom: #333333;
  --bs-report-switcher-popup-collapsable-li-color: #333333;
  --bs-search-input-background: #ffffff;
  --bs-search-border: transparent;
  --bs-search-focus-border: #000000;
  --bs-search-icon-color: #ffffff;
  --bs-search-title-color: #333333;
  --bs-search-message-color: #333333;
  --bs-search-input-background-button: #1781e3;
  --bs-search-input-background-button-hover: #1781e3;
  --bs-search-highlight-background: #d1e6f9;
  --bs-search-highlight: #333333;
  --bs-search-border-bottom: transparent;
  --bs-search-border-right: transparent;
  --bs-search-result-border-bottom: #f8fafb;
  --bs-search-result-hover-background: #eef3f6;
  --bs-sidebar-background: #ffffff;
  --bs-sidebar-border-right: transparent;
  --bs-spinner-background-1: #1781e3;
  --bs-spinner-background-2: #ffffff;
  --bs-spinner-after-background: #ffffff;
  --bs-table-highlight-border-top: transparent;
  --bs-table-linedark-border: #999999;
  --bs-table-linemedium-border: #999999;
  --bs-table-linelight-border: #bbbbbb;
  --bs-table-linethin-border: #6c6c6c;
  --bs-table-linetitle-border: #000000;
  --bs-table-linefootnote-border: transparent;
  --bs-table-stackable-thtd-color: #000000;
  --bs-table-color-class: #000000;
  --bs-table-desclight-color: #6c6c6c;
  --bs-table-number-color: #000000;
  --bs-table-highlight-color: #000000;
  --bs-table-highlight-background: #eef3f6;
  --bs-table-highlight-border: #eef3f6;
  --bs-table-row-hover-background: #eef3f6;
  --bs-table-headerrow: #333333;
  --bs-table-description: #333333;
  --bs-table-entry-bold: #333333;
  --bs-table-entry: #333333;
  --bs-heading-1-rgb: 51, 51, 51;
  --bs-heading-2-rgb: 51, 51, 51;
  --bs-heading-3-rgb: 8, 106, 219;
  --bs-heading-4-rgb: 8, 106, 219;
  --bs-heading-5-rgb: 51, 51, 51;
  --bs-heading-6-rgb: 51, 51, 51;
  --bs-p-lead-rgb: 51, 51, 51;
  --bs-p-title-rgb: 51, 51, 51;
  --bs-p-rgb: 51, 51, 51;
  --bs-caption-rgb: 51, 51, 51;
  --bs-glossary-rgb: 51, 51, 51;
  --bs-modal-overlay-backdrop-rgb: 0, 0, 0;
  --bs-modal-background-rgb: 255, 255, 255;
  --bs-modal-close-icon-rgb: 51, 51, 51;
  --bs-navigation-arrow-rgb: 51, 51, 51;
  --bs-navigation-background-rgb: 255, 255, 255;
  --bs-navigation-default-border-bottom-rgb: 0, 0, 0;
  --bs-navigation-color-rgb: 51, 51, 51;
  --bs-navigation-hover-color-rgb: 51, 51, 51;
  --bs-navigation-hover-background-rgb: 248, 250, 251;
  --bs-navigation-selected-color-rgb: 51, 51, 51;
  --bs-navigation-selected-background-rgb: 238, 243, 246;
  --bs-navigation-expanded-color-rgb: 51, 51, 51;
  --bs-navigation-expanded-background-rgb: 255, 255, 255;
  --bs-tab-selected-rgb: 255, 255, 255;
  --bs-tab-deselected-rgb: 248, 250, 251;
  --bs-tab-icon-rgb: 0, 17, 85;
  --bs-tab-active-border-bottom-rgb: 255, 255, 255;
  --bs-language-overlay-background-rgb: 0, 0, 0;
  --bs-language-overlay-link-rgb: 0, 17, 85;
  --bs-language-overlay-link-selected-rgb: 255, 255, 255;
  --bs-language-overlay-link-hover-background-rgb: 23, 129, 227;
  --bs-link-internal-rgb: 8, 106, 219;
  --bs-link-external-rgb: 0, 0, 0;
  --bs-text-selection-rgb: 189, 224, 242;
  --bs-text-selection-color-rgb: 0, 0, 0;
  --bs-primary-rgb: 51, 51, 51;
  --bs-secondary-rgb: 51, 51, 51;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-background-rgb: 255, 255, 255;
  --bs-sidebar-tabcontrol-header-background-rgb: 255, 255, 255;
  --bs-sidebar-tabcontrol-header-border-bottom-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-content-background-rgb: 255, 255, 255;
  --bs-sidebar-tabcontrol-tab-border-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-tab-border-bottom-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-tab-background-rgb: 248, 250, 251;
  --bs-sidebar-tabcontrol-tab-icon-color-rgb: 51, 51, 51;
  --bs-sidebar-tabcontrol-tab-title-color-rgb: 51, 51, 51;
  --bs-sidebar-tabcontrol-tab-active-border-rgb: 255, 255, 255;
  --bs-sidebar-tabcontrol-tab-active-border-bottom-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-tab-active-background-rgb: 255, 255, 255;
  --bs-sidebar-tabcontrol-tab-active-title-color-rgb: 0, 0, 0;
  --bs-sidebar-tabcontrol-tab-active-icon-color-rgb: 0, 0, 0;
  --bs-order-form-rgb: 51, 51, 51;
  --bs-order-form-link-rgb: 51, 51, 51;
  --bs-order-form-label-rgb: 51, 51, 51;
  --bs-order-form-input-border-rgb: 51, 51, 51;
  --bs-order-form-input-background-rgb: 51, 51, 51;
  --bs-order-form-input-rgb: 51, 51, 51;
  --bs-order-form-input-active-border-rgb: 51, 51, 51;
  --bs-order-form-submit-background-rgb: 51, 51, 51;
  --bs-order-form-submit-rgb: 255, 255, 255;
  --bs-order-form-submit-hover-background-rgb: 0, 0, 0;
  --bs-order-form-error-label-rgb: 51, 51, 51;
  --bs-order-form-error-input-background-rgb: 51, 51, 51;
  --bs-order-form-error-input-border-rgb: 51, 51, 51;
  --bs-order-form-error-message-rgb: 51, 51, 51;
  --bs-share-button-color-rgb: 51, 51, 51;
  --bs-share-button-hover-color-rgb: 51, 51, 51;
  --bs-share-border-rgb: 23, 129, 227;
  --bs-share-border-top-rgb: 23, 129, 227;
  --bs-share-background-rgb: 240, 240, 240;
  --bs-share-font-color-rgb: 51, 51, 51;
  --bs-share-icon-rgb: 23, 129, 227;
  --bs-share-popover-border-rgb: 0, 0, 0;
  --bs-share-icon-icon-hover-rgb: 255, 255, 255;
  --bs-share-icon-icon-hover-background-rgb: 23, 129, 227;
  --bs-share-icon-icon-hover-border-rgb: 0, 0, 0;
  --bs-share-icon-icon-active-border-rgb: 255, 255, 255;
  --bs-report-switcher-li-rgb: 51, 51, 51;
  --bs-report-switcher-badge-rgb: 51, 51, 51;
  --bs-report-switcher-badge-background-rgb: 255, 255, 255;
  --bs-report-switcher-collapsable-border-bottom-rgb: 51, 51, 51;
  --bs-report-switcher-list-item-color-rgb: 51, 51, 51;
  --bs-report-switcher-input-background-rgb: 255, 255, 255;
  --bs-report-switcher-input-border-rgb: 228, 233, 236;
  --bs-report-switcher-input-rgb: 51, 51, 51;
  --bs-report-switcher-popup-background-rgb: 255, 255, 255;
  --bs-report-switcher-popup-close-rgb: 23, 129, 227;
  --bs-report-switcher-popup-list-li-rgb: 51, 51, 51;
  --bs-report-switcher-popup-list-li-border-bottom-rgb: 23, 129, 227;
  --bs-report-switcher-popup-list-li-hover-rgb: 51, 51, 51;
  --bs-report-switcher-popup-list-li-hover-background-rgb: 209, 230, 249;
  --bs-report-switcher-popup-list-li-a-rgb: 51, 51, 51;
  --bs-report-switcher-popup-list-language-rgb: 51, 51, 51;
  --bs-report-switcher-popup-collapsable-title-border-bottom-rgb: 51, 51, 51;
  --bs-report-switcher-popup-collapsable-icon-font-rgb: 51, 51, 51;
  --bs-report-switcher-popup-collapsable-li-border-bottom-rgb: 51, 51, 51;
  --bs-report-switcher-popup-collapsable-li-color-rgb: 51, 51, 51;
  --bs-search-input-background-rgb: 255, 255, 255;
  --bs-search-border-rgb: 0, 0, 0;
  --bs-search-focus-border-rgb: 0, 0, 0;
  --bs-search-icon-color-rgb: 255, 255, 255;
  --bs-search-title-color-rgb: 51, 51, 51;
  --bs-search-message-color-rgb: 51, 51, 51;
  --bs-search-input-background-button-rgb: 23, 129, 227;
  --bs-search-input-background-button-hover-rgb: 23, 129, 227;
  --bs-search-highlight-background-rgb: 209, 230, 249;
  --bs-search-highlight-rgb: 51, 51, 51;
  --bs-search-border-bottom-rgb: 0, 0, 0;
  --bs-search-border-right-rgb: 0, 0, 0;
  --bs-search-result-border-bottom-rgb: 248, 250, 251;
  --bs-search-result-hover-background-rgb: 238, 243, 246;
  --bs-sidebar-background-rgb: 255, 255, 255;
  --bs-sidebar-border-right-rgb: 0, 0, 0;
  --bs-spinner-background-1-rgb: 23, 129, 227;
  --bs-spinner-background-2-rgb: 255, 255, 255;
  --bs-spinner-after-background-rgb: 255, 255, 255;
  --bs-table-highlight-border-top-rgb: 0, 0, 0;
  --bs-table-linedark-border-rgb: 153, 153, 153;
  --bs-table-linemedium-border-rgb: 153, 153, 153;
  --bs-table-linelight-border-rgb: 187, 187, 187;
  --bs-table-linethin-border-rgb: 108, 108, 108;
  --bs-table-linetitle-border-rgb: 0, 0, 0;
  --bs-table-linefootnote-border-rgb: 0, 0, 0;
  --bs-table-stackable-thtd-color-rgb: 0, 0, 0;
  --bs-table-color-class-rgb: 0, 0, 0;
  --bs-table-desclight-color-rgb: 108, 108, 108;
  --bs-table-number-color-rgb: 0, 0, 0;
  --bs-table-highlight-color-rgb: 0, 0, 0;
  --bs-table-highlight-background-rgb: 238, 243, 246;
  --bs-table-highlight-border-rgb: 238, 243, 246;
  --bs-table-row-hover-background-rgb: 238, 243, 246;
  --bs-table-headerrow-rgb: 51, 51, 51;
  --bs-table-description-rgb: 51, 51, 51;
  --bs-table-entry-bold-rgb: 51, 51, 51;
  --bs-table-entry-rgb: 51, 51, 51;
  --bs-primary-text-emphasis: #000722;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #560e16;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cccfdd;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f7d3d7;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #99a0bb;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #efa7af;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #001155;
  --bs-link-color-rgb: 0, 17, 85;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #000e44;
  --bs-link-hover-color-rgb: 0, 14, 68;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 17, 85, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #d82236;
  --bs-form-invalid-border-color: #d82236;
}
.v-v1 [data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #667099;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #e87a86;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #000311;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2b070b;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #000a33;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #821420;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #667099;
  --bs-link-hover-color: #858dad;
  --bs-link-color-rgb: 102, 112, 153;
  --bs-link-hover-color-rgb: 133, 141, 173;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #e87a86;
  --bs-form-invalid-border-color: #e87a86;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .report-switcher_input {
  padding: 0 16px 0 20px;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.reader__navigation__menu .level {
  padding-right: 6px;
  border-right: 1px solid #e4e9ec;
  border-bottom: 1px solid #e4e9ec;
}
.v-v1 .override.reader__navigation__menu .level .text,
.v-v1 .override.reader__navigation__menu .level .text span {
  font-size: 16px;
  font-weight: 300;
  justify-content: flex-start;
}
.v-v1 .override.reader__navigation__menu .level .arrow {
  justify-content: flex-start;
}
.v-v1 .override.reader__navigation__menu .level.level--1 .arrow {
  font-size: 2rem;
}
.v-v1 .override.reader__navigation__menu .level.level--1.level--1-1 .arrow, .v-v1 .override.reader__navigation__menu .level.level--1.level--1-2 .arrow {
  color: #eef3f6;
}
.v-v1 .override.reader__navigation__menu .level.level--1.level--1-1 .text, .v-v1 .override.reader__navigation__menu .level.level--1.level--1-2 .text {
  color: #333333;
}
.v-v1 .override.reader__navigation__menu .level.level--2 .arrow {
  font-size: 2rem;
}
.v-v1 .override.reader__navigation__menu .level.level--2.expanded {
  background-color: #eef3f6;
}
.v-v1 .override.reader__navigation__menu .level.level--3 {
  font-size: 1.35rem;
}
.v-v1 .override.reader__navigation__menu .level.level--3.expanded {
  background-color: #eef3f6;
}
.v-v1 .override.reader__navigation__menu .level.level--4 {
  font-size: 1.35rem;
}
.v-v1 .override.reader__navigation__menu .level.level--5 {
  font-size: 1.35rem;
}
.v-v1 .override.reader__navigation__menu .level:not(.level--1):not(.level--2).selected {
  background: #eef3f6;
}
.v-v1 .override.reader__navigation__menu .submenu--2 {
  padding: 0 0 20px 15px;
}
.v-v1 .override.reader__navigation__menu .submenu--2 .level .text {
  padding-left: 6px;
}
.v-v1 .override.reader__navigation__menu .submenu--3.expanded {
  background: #f8fafb;
}
.v-v1 .override.reader__content__wrapper h1, .v-v1 .override.reader__content__wrapper .h1 {
  margin: 0 0 24px;
  color: var(--heading-1);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h1, .v-v1 .override.reader__content__wrapper .h1 {
    margin: 0 0 56px;
  }
}
.v-v1 .override.reader__content__wrapper h2, .v-v1 .override.reader__content__wrapper .h2 {
  margin: 0 0 32px;
  color: var(--heading-2);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h2, .v-v1 .override.reader__content__wrapper .h2 {
    margin: 0 0 48px;
  }
}
.v-v1 .override.reader__content__wrapper h3, .v-v1 .override.reader__content__wrapper .swisscom-about-box-title-1zeile,
.v-v1 .override.reader__content__wrapper .swisscom-about-box-title-2zeilen, .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-36pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-30pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-36pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-30pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-29pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-25pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-25pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-21pt,
.v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-30pt_small,
.v-v1 .override.reader__content__wrapper .swisscom-about-value, .v-v1 .override.reader__content__wrapper .h3 {
  margin: 0 0 16px 0;
  color: var(--heading-3);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h3, .v-v1 .override.reader__content__wrapper .swisscom-about-box-title-1zeile,
  .v-v1 .override.reader__content__wrapper .swisscom-about-box-title-2zeilen, .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-36pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-30pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-36pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-30pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-29pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-25pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-grey-25pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-21pt,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value-navy-30pt_small,
  .v-v1 .override.reader__content__wrapper .swisscom-about-value, .v-v1 .override.reader__content__wrapper .h3 {
    margin: 0 0 24px 0;
  }
}
.v-v1 .override.reader__content__wrapper h4, .v-v1 .override.reader__content__wrapper .h4 {
  margin: 0 0 8px 0;
  color: var(--heading-4);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h4, .v-v1 .override.reader__content__wrapper .h4 {
    margin: 0 0 8px 0;
  }
}
.v-v1 .override.reader__content__wrapper h5, .v-v1 .override.reader__content__wrapper .swisscom-about-box-subtitle, .v-v1 .override.reader__content__wrapper .swisscom-corporate-governance-name, .v-v1 .override.reader__content__wrapper .h5 {
  margin: 0 0 24px;
  color: var(--heading-5);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h5, .v-v1 .override.reader__content__wrapper .swisscom-about-box-subtitle, .v-v1 .override.reader__content__wrapper .swisscom-corporate-governance-name, .v-v1 .override.reader__content__wrapper .h5 {
    margin: 0 0 56px;
  }
}
.v-v1 .override.reader__content__wrapper h6, .v-v1 .override.reader__content__wrapper .h6 {
  margin: 0 0 24px;
  color: var(--heading-6);
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper h6, .v-v1 .override.reader__content__wrapper .h6 {
    margin: 0 0 56px;
  }
}
.v-v1 .override.reader__content__wrapper p {
  margin: 0 0 40px 0;
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper p {
    margin: 0 0 40px 0;
  }
}
.v-v1 .override.reader__body p {
  margin-bottom: 40px;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.reader__search__menu .input__group--search {
  box-shadow: inset 1px 0 0 #e4e9ec;
}
.v-v1 .override.reader__search__menu input {
  font-family: "TheSans", sans-serif;
  padding: 12px;
}
.v-v1 .override.reader__search__menu .search-highlight {
  padding: 0 1px;
  font-style: italic;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.reader__content__wrapper *[data-glossary],
.v-v1 .override.reader__content__wrapper *[data-open-html-element-id-in-overlay],
.v-v1 .override.reader__content__wrapper *[data-attachment],
.v-v1 .override.reader__content__wrapper *[data-attachment-shortlinkid] {
  border-bottom: 2px dotted #086adb;
  font-size: inherit;
}
.v-v1 .override.reader__content__wrapper .gb-table *[data-attachment],
.v-v1 .override.reader__content__wrapper .gb-table *[data-glossary],
.v-v1 .override.reader__content__wrapper .gb-table *[data-attachment-shortlinkid] {
  font-weight: 300;
}
.v-v1 .override.reader__content__wrapper .external-link {
  border-bottom: 1px solid #086adb;
}
.v-v1 .override.reader__content__wrapper .external-link::after {
  display: none;
}
.v-v1 .override.reader__content__wrapper [data-internallink]:hover,
.v-v1 .override.reader__content__wrapper [data-shortlinkid]:hover,
.v-v1 .override.reader__content__wrapper .internal-link:hover {
  text-decoration: underline !important;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.reader__content__wrapper .gb-table th *,
.v-v1 .override.reader__content__wrapper .gb-table tr *,
.v-v1 .override.reader__overlay .gb-table th *,
.v-v1 .override.reader__overlay .gb-table tr * {
  font-size: 16px;
  font-weight: 300;
}
.v-v1 .override.reader__content__wrapper .gb-table th sup,
.v-v1 .override.reader__content__wrapper .gb-table th sub,
.v-v1 .override.reader__content__wrapper .gb-table tr sup,
.v-v1 .override.reader__content__wrapper .gb-table tr sub,
.v-v1 .override.reader__overlay .gb-table th sup,
.v-v1 .override.reader__overlay .gb-table th sub,
.v-v1 .override.reader__overlay .gb-table tr sup,
.v-v1 .override.reader__overlay .gb-table tr sub {
  font-size: 75%;
}
.v-v1 .override.reader__content__wrapper .gb-table th td:not(.footnote):not(.superscript),
.v-v1 .override.reader__content__wrapper .gb-table tr td:not(.footnote):not(.superscript),
.v-v1 .override.reader__overlay .gb-table th td:not(.footnote):not(.superscript),
.v-v1 .override.reader__overlay .gb-table tr td:not(.footnote):not(.superscript) {
  padding: 5px 10px 4px 4px !important;
}
.v-v1 .override.reader__content__wrapper .gb-table th.line-thin,
.v-v1 .override.reader__content__wrapper .gb-table tr.line-thin,
.v-v1 .override.reader__overlay .gb-table th.line-thin,
.v-v1 .override.reader__overlay .gb-table tr.line-thin {
  border-bottom: 2px solid #6c6c6c;
}
.v-v1 .override.reader__content__wrapper .gb-table .superscript,
.v-v1 .override.reader__overlay .gb-table .superscript {
  padding: 5px 4px 4px;
}
.v-v1 .override.reader__content__wrapper .gb-table .highlight,
.v-v1 .override.reader__overlay .gb-table .highlight {
  border-left: none !important;
  border-right: none !important;
}
.v-v1 .override.reader__content__wrapper .gb-table .highlight,
.v-v1 .override.reader__content__wrapper .gb-table .highlight *,
.v-v1 .override.reader__content__wrapper .gb-table .bold,
.v-v1 .override.reader__content__wrapper .gb-table .bold *,
.v-v1 .override.reader__overlay .gb-table .highlight,
.v-v1 .override.reader__overlay .gb-table .highlight *,
.v-v1 .override.reader__overlay .gb-table .bold,
.v-v1 .override.reader__overlay .gb-table .bold * {
  font-weight: bold;
}
.v-v1 .override.reader__content__wrapper .gb-table tr.line-title td,
.v-v1 .override.reader__overlay .gb-table tr.line-title td {
  padding-top: 24px !important;
}
.v-v1 .override.reader__content__wrapper th,
.v-v1 .override.reader__overlay th {
  text-align: revert;
}
.v-v1 .override.reader__content__wrapper .tablesaw thead th[colspan],
.v-v1 .override.reader__overlay .tablesaw thead th[colspan] {
  text-align: revert;
}
.v-v1 .override.reader__content__wrapper .stackable .gb-table .tablesaw-cell-content,
.v-v1 .override.reader__overlay .stackable .gb-table .tablesaw-cell-content {
  vertical-align: bottom;
}
.v-v1 .override.reader__content__wrapper .stackable,
.v-v1 .override.reader__overlay .stackable {
  overflow-y: hidden;
}
@media (min-width: 768px) {
  .v-v1 .override.reader__content__wrapper .stackable .gb-table tr td:last-child,
  .v-v1 .override.reader__overlay .stackable .gb-table tr td:last-child {
    padding-bottom: 4px;
  }
}
.v-v1 .override.reader__content__wrapper .scroller,
.v-v1 .override.reader__overlay .scroller {
  overflow-y: hidden;
}
@media screen and (min-width: 1300px) {
  .v-v1 .scroller.oversize,
  .v-v1 .stackable.oversize {
    margin-right: -50px;
  }
}
@media screen and (min-width: 1700px) {
  .v-v1 .scroller.oversize,
  .v-v1 .stackable.oversize {
    margin-right: -120px;
  }
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.input--search {
  border: 0.1rem solid #d6d6d6;
  border-radius: 0.4rem 0 0 0.4rem;
  padding: 1rem 1.2rem 0.8rem;
}
.v-v1 .override.input--search:focus {
  border: 0.1rem solid #086adb;
}
.v-v1 .override .input--search-button {
  border-radius: 0 0.4rem 0.4rem 0;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .reader-lead, .v-v1 .swisscom-lead {
  margin: 0 0 40px 0 !important;
  color: #086adb !important;
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  font-style: normal !important;
}
@media screen and (min-width: 1520px) {
  .v-v1 .reader-lead, .v-v1 .swisscom-lead {
    border-left: 0 !important;
    padding-left: 30px !important;
    margin-left: -30px !important;
  }
}
.v-v1 .red {
  color: #d82236 !important;
}
.v-v1 .blue {
  color: #001155 !important;
}
.v-v1 .blue-dark {
  color: #015 !important;
}
.v-v1 .blue-1 {
  color: #1f4485 !important;
}
.v-v1 .blue-1-5 {
  color: #19376b !important;
}
.v-v1 .blue-2 {
  color: #002a74 !important;
}
.v-v1 .blue-2-5 {
  color: #1b438d !important;
}
.v-v1 .blue-3 {
  color: #1a171b !important;
}
.v-v1 .blue-light {
  color: #1781e3 !important;
}
.v-v1 .blue-light-1 {
  color: #268def !important;
}
.v-v1 .blue-light-2 {
  color: #00a3fd !important;
}
.v-v1 .blue-light-2-2 {
  color: #dde3e7 !important;
}
.v-v1 .blue-light-2-5 {
  color: #d1e6f9 !important;
}
.v-v1 .blue-light-3 {
  color: #bcf0ff !important;
}
.v-v1 .blue-light-3-5 {
  color: #a2cdf4 !important;
}
.v-v1 .blue-light-4 {
  color: #0082ea !important;
}
.v-v1 .blue-light-5 {
  color: #0050e2 !important;
}
.v-v1 .blue-ultra-light {
  color: #cceeff !important;
}
.v-v1 .blue-accessible {
  color: #086adb !important;
}
.v-v1 .grey {
  color: #333333 !important;
}
.v-v1 .grey-light {
  color: #6c6c6c !important;
}
.v-v1 .grey-another-one {
  color: #999999 !important;
}
.v-v1 .grey-medium {
  color: #bbbbbb !important;
}
.v-v1 .grey-dark {
  color: #1a171b !important;
}
.v-v1 .grey-ultra-light {
  color: #e1e2e3 !important;
}
.v-v1 .grey-ultra-light-2 {
  color: #f3f3f3 !important;
}
.v-v1 .grey-another-one-2 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-3 {
  color: #d6d6d6 !important;
}
.v-v1 .grey-another-one-4 {
  color: #f0f0f0 !important;
}
.v-v1 .grey-another-one-5 {
  color: #f5f5f5 !important;
}
.v-v1 .grey-another-one-6 {
  color: #666666 !important;
}
.v-v1 .grey-another-one-7 {
  color: #f1f1f1 !important;
}
.v-v1 .grey-another-one-8 {
  color: #d7d7d7 !important;
}
.v-v1 .grey-another-one-9 {
  color: #edf3f6 !important;
}
.v-v1 .grey-another-one-10 {
  color: #dce3e7 !important;
}
.v-v1 .grey-another-one-11 {
  color: #cfd5d9 !important;
}
.v-v1 .grey-another-one-12 {
  color: #eef3f6 !important;
}
.v-v1 .grey-another-one-13 {
  color: #e4e9ec !important;
}
.v-v1 .grey-another-one-14 {
  color: #f8fafb !important;
}
.v-v1 .white {
  color: #ffffff !important;
}
.v-v1 .black {
  color: #000000 !important;
}
.v-v1 .hover-color {
  color: #f5f5f5 !important;
}
.v-v1 .active-color {
  color: #eef3f6 !important;
}
.v-v1 .kpi-modal-arrow-fill-color {
  color: #0082ea !important;
}
.v-v1 .kpi-modal-arrow-background-hover {
  color: #edf3f6 !important;
}
.v-v1 .kpi-modal-arrow-background-active {
  color: #dce3e7 !important;
}
.v-v1 .kpis-card-separator-color {
  color: #000000 !important;
}
.v-v1 .kpis-background-color {
  color: rgba(26, 23, 27, 0.24) !important;
}
.v-v1 .kpis-background-color-hove {
  color: #0082ea !important;
}
.v-v1 .materiality-color-turquoise {
  color: rgb(14, 171, 169) !important;
}
.v-v1 .materiality-color-orchid {
  color: rgb(166, 55, 151) !important;
}
.v-v1 .materiality-color-turquoise-light {
  color: rgba(14, 171, 169, 0.05) !important;
}
.v-v1 .materiality-color-orchid-light {
  color: rgba(166, 55, 151, 0.05) !important;
}
.v-v1 .override.tabcontrol {
  border-right: 1px solid #e4e9ec;
}
.v-v1 .override.tabcontrol .tab {
  padding: 20px 0;
}
.v-v1 .reader__content {
  padding: 20px 20px 20px 44px;
  max-width: 1400px;
}
@media (min-width: 768px) {
  .v-v1 .reader__content {
    padding: 50px;
  }
}
@media screen and (min-width: 1300px) {
  .v-v1 .reader__content {
    padding: 50px 80px;
  }
}
@media screen and (min-width: 1500px) {
  .v-v1 .reader__content {
    padding: 50px 200px;
  }
}
.v-v1 .container, .v-v1 .order-form .content {
  padding: 1px 0;
  margin: 0 0 20px 0;
  max-width: unset;
}
.v-v1 .override.reader__header {
  font-family: "TheSans", Arial, sans-serif;
}
.v-v1 ul {
  padding-left: 18px;
  margin-top: 0;
  margin-bottom: 40px;
}
.v-v1 .row--equal {
  margin-bottom: 30px;
}
.v-v1 ::selection {
  background: var(--text-selection, #bde0f2);
  color: var(--text-selection-color, #000000);
}
.v-v1 .tabcontrol {
  display: flex;
  flex-direction: column;
  background-color: var(--sidebar-tabcontrol-background, #ffffff);
  height: 100%;
  width: 100%;
}
.v-v1 .tabcontrol__header {
  display: flex;
  flex-direction: row;
  margin-left: 0;
  padding-left: 0;
  flex: 0 0 auto;
  list-style: none;
  margin-bottom: 0;
  background-color: var(--sidebar-tabcontrol-header-background, #ffffff);
  border-bottom: 1px solid var(--sidebar-tabcontrol-header-border-bottom, transparent);
  flex: 0 0 auto;
}
.v-v1 .tabcontrol__content {
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding-top: 0;
  background-color: var(--sidebar-tabcontrol-content-background, #ffffff);
}
.v-v1 .tabcontrol .tab {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--sidebar-tabcontrol-tab-border, transparent);
  border-bottom: 1px solid var(--sidebar-tabcontrol-tab-border-bottom, transparent);
  border-left: 0;
  padding: 10px 0;
  margin-bottom: 0;
  background: var(--sidebar-tabcontrol-tab-background, #f8fafb);
}
.v-v1 .tabcontrol .tab .tab__icon {
  margin-right: 8px;
}
.v-v1 .tabcontrol .tab .tab__title {
  margin-top: 0;
}
.v-v1 .tabcontrol .tab .tab__title, .v-v1 .tabcontrol .tab .tab__icon {
  font-weight: inherit;
}
.v-v1 .tabcontrol .tab .tab__icon__title__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.v-v1 .tabcontrol .tab__icon {
  color: var(--sidebar-tabcontrol-tab-icon-color, #333333);
  font-size: 19px;
  transition: color 0.25s ease-in-out;
}
.v-v1 .tabcontrol .tab__title {
  color: var(--sidebar-tabcontrol-tab-title-color, #333333);
  font-size: 14px;
  margin-top: 0;
  transition: color 0.25s ease-in-out;
}
.v-v1 .tabcontrol .tab--active {
  border: 1px solid var(--sidebar-tabcontrol-tab-active-border, #ffffff);
  border-bottom: 1px solid var(--sidebar-tabcontrol-tab-active-border-bottom, transparent);
  background-color: var(--sidebar-tabcontrol-tab-active-background, #ffffff);
}
.v-v1 .tabcontrol .tab--active .tab__title {
  color: var(--sidebar-tabcontrol-tab-active-title-color, #000000);
  font-weight: normal;
}
.v-v1 .tabcontrol .tab--active .tab__icon {
  color: var(--sidebar-tabcontrol-tab-active-icon-color, #000000);
  font-weight: normal;
}
.v-v1 .tabcontrol .tab--active:hover .tab__title {
  color: var(--sidebar-tabcontrol-tab-active-title-color, #000000);
}
.v-v1 .tabcontrol .tab--active:hover .tab__icon {
  color: var(--sidebar-tabcontrol-tab-active-icon-color, #000000);
}
.v-v1 .tabcontrol .tab-content {
  display: none;
}
.v-v1 .tabcontrol .tab-content--active {
  display: block;
}
.v-v1 .tablesaw {
  width: 100%;
  max-width: 100%;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  empty-cells: show;
}
.v-v1 .tablesaw th,
.v-v1 .tablesaw td {
  box-sizing: border-box;
}
.v-v1 .tablesaw thead {
  background: 0;
}
.v-v1 .tablesaw thead tr:first-child th:first-child {
  display: table-cell;
}
.v-v1 .tablesaw thead th[colspan] {
  text-align: right;
}
.v-v1 .tablesaw-stack td .tablesaw-cell-label,
.v-v1 .tablesaw-stack th .tablesaw-cell-label {
  display: none;
}
.v-v1 .tablesaw-stack th,
.v-v1 .tablesaw-stack td {
  display: block;
}
.v-v1 .tablesaw-stack th .tablesaw-cell-label,
.v-v1 .tablesaw-stack td .tablesaw-cell-label {
  display: inline-block;
  padding: 0 6px 0 0;
  width: 50%;
}
.v-v1 .tablesaw-stack th .tablesaw-cell-label-top,
.v-v1 .tablesaw-stack td .tablesaw-cell-label-top {
  display: block;
  margin: 4px 0;
}
.v-v1 .tablesaw-stack th .tablesaw-cell-content,
.v-v1 .tablesaw-stack td .tablesaw-cell-content {
  width: 50%;
}
.v-v1 .tablesaw-stack tr {
  clear: both;
  display: table-row;
}
.v-v1 .tablesaw-stack tbody th.group {
  margin-top: -1px;
}
.v-v1 .tablesaw-stack th.group b.tablesaw-cell-label {
  display: none !important;
}
.v-v1 .tablesaw-cell-label {
  display: block;
}
@media (max-width: 767px) {
  .v-v1 .tablesaw-stack thead td,
  .v-v1 .tablesaw-stack thead th {
    display: none;
    clear: left;
    float: left;
    width: 100%;
  }
  .v-v1 .tablesaw-stack td:empty,
  .v-v1 .tablesaw-stack th:empty {
    display: none;
  }
  .v-v1 .tablesaw-cell-label {
    vertical-align: top;
  }
  .v-v1 .tablesaw-cell-content {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .v-v1 .tablesaw-stack tr {
    display: table-row;
  }
  .v-v1 .tablesaw-stack td,
  .v-v1 .tablesaw-stack th,
  .v-v1 .tablesaw-stack thead td,
  .v-v1 .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .v-v1 .tablesaw-stack td .tablesaw-cell-label,
  .v-v1 .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 767.9px) {
  .v-v1 .tablesaw-cell-hidden {
    display: none !important;
  }
}
.v-v1 .reader__header {
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid var(--header-border-bottom, #dde3e7);
  background: var(--header-background, #ffffff);
  z-index: 91;
  height: 80px;
}
.v-v1 .reader__header .left {
  grid-column: 1;
  height: 100%;
}
.v-v1 .reader__header .left .navigateToContentBox:focus {
  opacity: 1 !important;
}
.v-v1 .reader__header .right {
  grid-column: 2;
  padding-right: 0;
}
@media (min-width: 768px) {
  .v-v1 .reader__header .right {
    padding: 0px;
  }
}
@media (min-width: 1200px) {
  .v-v1 .reader__header--with-tabs {
    grid-template-columns: auto 1fr auto;
  }
  .v-v1 .reader__header--with-tabs .left {
    grid-column: 1;
  }
  .v-v1 .reader__header--with-tabs .reader-tabs {
    grid-column: 2;
  }
  .v-v1 .reader__header--with-tabs .right {
    grid-column: 3;
  }
  .v-v1 .reader__header--with-tabs .reader-tabs,
  .v-v1 .reader__header--with-tabs .reader-tabs__additional-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.v-v1 .reader__header .left,
.v-v1 .reader__header .right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.v-v1 .reader__header .left {
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .v-v1 .reader__header .left {
    width: 320px;
  }
}
.v-v1 .reader__header .button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--header-button, #000000);
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}
@media (min-width: 768px) {
  .v-v1 .reader__header .button {
    min-width: 100px;
  }
}
.v-v1 .reader__header .button .icon-font {
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.v-v1 .reader__header .button p {
  color: var(--header-button, #000000);
  margin: 10px 0 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  font-family: inherit;
  user-select: none;
}
@media (min-width: 768px) {
  .v-v1 .reader__header .button p {
    font-size: 16px;
  }
}
.v-v1 .reader__header .button--menu,
.v-v1 .reader__header .button--close {
  background: var(--header-button-background-menu-and-close, #ffffff);
  box-shadow: none;
}
.v-v1 .reader__header .button--menu,
.v-v1 .reader__header .button--menu p,
.v-v1 .reader__header .button--close,
.v-v1 .reader__header .button--close p {
  color: var(--header-button-menu-and-close, #000000);
}
.v-v1 .reader__header .button--menu:hover, .v-v1 .reader__header .button--menu:active, .v-v1 .reader__header .button--menu.active,
.v-v1 .reader__header .button--close:hover,
.v-v1 .reader__header .button--close:active,
.v-v1 .reader__header .button--close.active {
  background: var(--header-button-active-background-menu-and-close, #eef3f6);
}
.v-v1 .reader__header .button--menu {
  margin: 0 8px 0 0;
}
@media (min-width: 768px) {
  .v-v1 .reader__header .button--menu {
    margin: 0 16px 0 0;
  }
}
.v-v1 .reader__header .button--menu .icon-font {
  width: 16px;
  height: 16px;
  color: var(--header-button-menu-icon, #000000);
}
.v-v1 .reader__header .button--menu .icon-font::before {
  font-size: inherit;
}
.v-v1 .reader__header .button--language,
.v-v1 .reader__header .button--downloads {
  position: relative;
  transition: background-color ease-in-out 0.15s;
}
.v-v1 .reader__header .button--language:not(.active):hover,
.v-v1 .reader__header .button--downloads:not(.active):hover {
  background-color: var(--header-button-language-downloads-hover, #ffffff);
}
.v-v1 .reader__header .button--language:active,
.v-v1 .reader__header .button--downloads:active {
  background-color: var(--header-button-active-language-downloads-hover, #ffffff);
}
@media (min-width: 768px) {
  .v-v1 .reader__header .button--language {
    display: flex;
  }
}
.v-v1 .reader__header .button--language .icon-font {
  color: var(--header-button-language-icon, #000000);
}
.v-v1 .reader__header .button--close .icon-font {
  color: var(--header-button-close-icon, #000000);
}
.v-v1 .reader__header .button--downloads .icon-font {
  color: var(--header-button-downloads-icon, #000000);
}
.v-v1 .reader__header .logo-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-v1 .reader__header .logo-container .logo {
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 68px;
  height: 40px;
  background-image: url("../images/logo-2x.png");
}
@media (min-width: 768px) {
  .v-v1 .reader__header .logo-container .logo {
    width: 102px;
    height: 50px;
  }
}
@media (min-width: 992px) {
  .v-v1 .reader__header .logo-container .logo {
    width: 136px;
  }
}
.v-v1 .reader__header.small {
  height: 60px;
}
@media (min-width: 992px) {
  .v-v1 .reader__header.small {
    height: 80px;
  }
}
@media (min-width: 768px) {
  .v-v1 .reader__header.small {
    height: 80px;
  }
}
@media (max-width: 767.98px) {
  .v-v1 .reader__header.small .button p {
    font-size: 12px;
    margin-top: 4px;
  }
  .v-v1 .reader__header.small .button--menu .icon-font,
  .v-v1 .reader__header.small .button--close .icon-font {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
  .v-v1 .reader__header.small .button--downloads,
  .v-v1 .reader__header.small .button--close,
  .v-v1 .reader__header.small .button--language {
    min-width: auto;
    justify-content: center;
    border-left: 1px solid var(--header-small-button-downloads-close-language-border-left, );
  }
  .v-v1 .reader__header.small .button--downloads p,
  .v-v1 .reader__header.small .button--close p,
  .v-v1 .reader__header.small .button--language p {
    display: none;
  }
}
@media (max-width: 767.98px) and (min-width: 768px) {
  .v-v1 .reader__header.small .button--downloads,
  .v-v1 .reader__header.small .button--close,
  .v-v1 .reader__header.small .button--language {
    border-left: 0;
  }
}
.v-v1 .m-1 {
  margin: 4px !important;
}
.v-v1 .mb-1 {
  margin-bottom: 4px !important;
}
.v-v1 .mt-1 {
  margin-top: 4px !important;
}
.v-v1 .mr-1 {
  margin-right: 4px !important;
}
.v-v1 .ml-1 {
  margin-left: 4px !important;
}
.v-v1 .p-1 {
  padding: 4px !important;
}
.v-v1 .pl-1 {
  padding-left: 4px !important;
}
.v-v1 .pr-1 {
  padding-right: 4px !important;
}
.v-v1 .pt-1 {
  padding-top: 4px !important;
}
.v-v1 .pb-1 {
  padding-bottom: 4px !important;
}
.v-v1 .m-2 {
  margin: 8px !important;
}
.v-v1 .mb-2 {
  margin-bottom: 8px !important;
}
.v-v1 .mt-2 {
  margin-top: 8px !important;
}
.v-v1 .mr-2 {
  margin-right: 8px !important;
}
.v-v1 .ml-2 {
  margin-left: 8px !important;
}
.v-v1 .p-2 {
  padding: 8px !important;
}
.v-v1 .pl-2 {
  padding-left: 8px !important;
}
.v-v1 .pr-2 {
  padding-right: 8px !important;
}
.v-v1 .pt-2 {
  padding-top: 8px !important;
}
.v-v1 .pb-2 {
  padding-bottom: 8px !important;
}
.v-v1 .m-3 {
  margin: 12px !important;
}
.v-v1 .mb-3 {
  margin-bottom: 12px !important;
}
.v-v1 .mt-3 {
  margin-top: 12px !important;
}
.v-v1 .mr-3 {
  margin-right: 12px !important;
}
.v-v1 .ml-3 {
  margin-left: 12px !important;
}
.v-v1 .p-3 {
  padding: 12px !important;
}
.v-v1 .pl-3 {
  padding-left: 12px !important;
}
.v-v1 .pr-3 {
  padding-right: 12px !important;
}
.v-v1 .pt-3 {
  padding-top: 12px !important;
}
.v-v1 .pb-3 {
  padding-bottom: 12px !important;
}
.v-v1 .m-4 {
  margin: 16px !important;
}
.v-v1 .mb-4 {
  margin-bottom: 16px !important;
}
.v-v1 .mt-4 {
  margin-top: 16px !important;
}
.v-v1 .mr-4 {
  margin-right: 16px !important;
}
.v-v1 .ml-4 {
  margin-left: 16px !important;
}
.v-v1 .p-4 {
  padding: 16px !important;
}
.v-v1 .pl-4 {
  padding-left: 16px !important;
}
.v-v1 .pr-4 {
  padding-right: 16px !important;
}
.v-v1 .pt-4 {
  padding-top: 16px !important;
}
.v-v1 .pb-4 {
  padding-bottom: 16px !important;
}
.v-v1 .m-5 {
  margin: 20px !important;
}
.v-v1 .mb-5 {
  margin-bottom: 20px !important;
}
.v-v1 .mt-5 {
  margin-top: 20px !important;
}
.v-v1 .mr-5 {
  margin-right: 20px !important;
}
.v-v1 .ml-5 {
  margin-left: 20px !important;
}
.v-v1 .p-5 {
  padding: 20px !important;
}
.v-v1 .pl-5 {
  padding-left: 20px !important;
}
.v-v1 .pr-5 {
  padding-right: 20px !important;
}
.v-v1 .pt-5 {
  padding-top: 20px !important;
}
.v-v1 .pb-5 {
  padding-bottom: 20px !important;
}
.v-v1 .m-6 {
  margin: 24px !important;
}
.v-v1 .mb-6 {
  margin-bottom: 24px !important;
}
.v-v1 .mt-6 {
  margin-top: 24px !important;
}
.v-v1 .mr-6 {
  margin-right: 24px !important;
}
.v-v1 .ml-6 {
  margin-left: 24px !important;
}
.v-v1 .p-6 {
  padding: 24px !important;
}
.v-v1 .pl-6 {
  padding-left: 24px !important;
}
.v-v1 .pr-6 {
  padding-right: 24px !important;
}
.v-v1 .pt-6 {
  padding-top: 24px !important;
}
.v-v1 .pb-6 {
  padding-bottom: 24px !important;
}
.v-v1 .m-7 {
  margin: 28px !important;
}
.v-v1 .mb-7 {
  margin-bottom: 28px !important;
}
.v-v1 .mt-7 {
  margin-top: 28px !important;
}
.v-v1 .mr-7 {
  margin-right: 28px !important;
}
.v-v1 .ml-7 {
  margin-left: 28px !important;
}
.v-v1 .p-7 {
  padding: 28px !important;
}
.v-v1 .pl-7 {
  padding-left: 28px !important;
}
.v-v1 .pr-7 {
  padding-right: 28px !important;
}
.v-v1 .pt-7 {
  padding-top: 28px !important;
}
.v-v1 .pb-7 {
  padding-bottom: 28px !important;
}
.v-v1 .m-8 {
  margin: 32px !important;
}
.v-v1 .mb-8 {
  margin-bottom: 32px !important;
}
.v-v1 .mt-8 {
  margin-top: 32px !important;
}
.v-v1 .mr-8 {
  margin-right: 32px !important;
}
.v-v1 .ml-8 {
  margin-left: 32px !important;
}
.v-v1 .p-8 {
  padding: 32px !important;
}
.v-v1 .pl-8 {
  padding-left: 32px !important;
}
.v-v1 .pr-8 {
  padding-right: 32px !important;
}
.v-v1 .pt-8 {
  padding-top: 32px !important;
}
.v-v1 .pb-8 {
  padding-bottom: 32px !important;
}
.v-v1 .m-9 {
  margin: 36px !important;
}
.v-v1 .mb-9 {
  margin-bottom: 36px !important;
}
.v-v1 .mt-9 {
  margin-top: 36px !important;
}
.v-v1 .mr-9 {
  margin-right: 36px !important;
}
.v-v1 .ml-9 {
  margin-left: 36px !important;
}
.v-v1 .p-9 {
  padding: 36px !important;
}
.v-v1 .pl-9 {
  padding-left: 36px !important;
}
.v-v1 .pr-9 {
  padding-right: 36px !important;
}
.v-v1 .pt-9 {
  padding-top: 36px !important;
}
.v-v1 .pb-9 {
  padding-bottom: 36px !important;
}
.v-v1 .m-10 {
  margin: 40px !important;
}
.v-v1 .mb-10 {
  margin-bottom: 40px !important;
}
.v-v1 .mt-10 {
  margin-top: 40px !important;
}
.v-v1 .mr-10 {
  margin-right: 40px !important;
}
.v-v1 .ml-10 {
  margin-left: 40px !important;
}
.v-v1 .p-10 {
  padding: 40px !important;
}
.v-v1 .pl-10 {
  padding-left: 40px !important;
}
.v-v1 .pr-10 {
  padding-right: 40px !important;
}
.v-v1 .pt-10 {
  padding-top: 40px !important;
}
.v-v1 .pb-10 {
  padding-bottom: 40px !important;
}
.v-v1 img,
.v-v1 .reader-image,
.v-v1 .swisscom-image {
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  height: auto;
  margin: 0 0 30px 0;
}
@media (min-width: 768px) {
  .v-v1 .reader-image--full, .v-v1 .swisscom-image--full {
    max-width: 100%;
  }
}
.v-v1 .reader-image--half, .v-v1 .swisscom-image--half {
  max-width: 50%;
}
.v-v1 .input__group--search {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.v-v1 .input--search {
  outline: none;
  font-weight: normal;
  padding: 8px 16px;
  width: calc(100% - 44px);
  background-color: var(--search-input-background, #ffffff);
  border: 1px solid var(--search-border, transparent);
  border-right-width: 1px;
  border-radius: 4px 0 0 4px;
  -webkit-appearance: none;
}
.v-v1 .input--search:focus {
  border: 1px solid var(--search-focus-border, #000000);
  border-right-width: 0;
}
.v-v1 .input--search-button {
  cursor: pointer;
  color: var(--search-icon-color, #ffffff);
  flex: 1 0 auto;
  background: var(--search-input-background-button, #1781e3);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 44px;
  width: 44px;
  border: 0;
  border-radius: 0 4px 0 4px;
  outline: none;
  padding: 0;
}
.v-v1 .input--search-button:hover {
  background: var(--search-input-background-button-hover, #1781e3);
}
.v-v1 .language__overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--language-overlay-background, rgba(0, 0, 0, 0.3));
  z-index: 150;
}
.v-v1 .language__overlay .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-v1 .language__overlay .wrapper a, .v-v1 .language__overlay .wrapper *[data-shortlinkid],
.v-v1 .language__overlay .wrapper *[data-shortlinkurl] {
  display: block;
  width: 100%;
  padding: 12px;
  color: var(--language-overlay-link, #015);
  text-decoration: none;
  text-align: center;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.v-v1 .language__overlay .wrapper a.selected, .v-v1 .language__overlay .wrapper .selected[data-shortlinkid],
.v-v1 .language__overlay .wrapper .selected[data-shortlinkurl] {
  font-weight: bold;
  color: var(--language-overlay-link-selected, #ffffff);
  background-color: var(--language-overlay-link-selected-background, );
}
.v-v1 .language__overlay .icon--close {
  position: absolute;
  color: var(--language-overlay-link, #015);
  font-size: 16px;
  top: 30px;
  right: 30px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.language-overlay-shown .language__overlay {
  display: flex !important;
}
@media (min-width: 768px) {
  .language-overlay-shown .language__overlay {
    display: none !important;
  }
}

.v-v1 .reader__content__wrapper *[data-glossary],
.v-v1 .reader__content__wrapper *[data-open-html-element-id-in-overlay],
.v-v1 .reader__content__wrapper *[data-attachment],
.v-v1 .reader__content__wrapper *[data-attachment-shortlinkid] {
  position: relative;
  cursor: pointer;
  user-select: text;
  border-bottom: 1px dotted var(--glossary, #333333);
  display: inline;
}
.v-v1 .reader__content__wrapper *[data-glossary]:before,
.v-v1 .reader__content__wrapper *[data-open-html-element-id-in-overlay]:before,
.v-v1 .reader__content__wrapper *[data-attachment]:before,
.v-v1 .reader__content__wrapper *[data-attachment-shortlinkid]:before {
  content: " ";
  display: inline-block;
}
.v-v1 .reader__content__wrapper .gb-table span[data-glossary],
.v-v1 .reader__content__wrapper .gb-table span[data-attachment] {
  font-weight: inherit;
  user-select: none;
}
.v-v1 .reader__content__wrapper .gb-table tr td:first-child span[data-glossary],
.v-v1 .reader__content__wrapper .gb-table tr td:first-child span[data-attachment] {
  font-weight: 300;
}
.v-v1 .reader__content__wrapper .external-link {
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
  color: var(--link-external, #000000);
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  padding-bottom: 1px;
  border-bottom: 1px solid var(--link-external, #000000);
}
.v-v1 .reader__content__wrapper .external-link::after {
  content: "3";
  font-family: "icon-font.v-v1";
  display: inline-block;
  margin-left: 8px;
}
.v-v1 .reader__content__wrapper *[data-internallink], .v-v1 .reader__content__wrapper *[data-internallink]:not([href]),
.v-v1 .reader__content__wrapper *[data-shortlinkid],
.v-v1 .reader__content__wrapper *[data-shortlinkid]:not([href]),
.v-v1 .reader__content__wrapper *[data-shortlinkurl],
.v-v1 .reader__content__wrapper *[data-shortlinkurl]:not([href]),
.v-v1 .reader__content__wrapper .internal-link,
.v-v1 .reader__content__wrapper .internal-link:not([href]) {
  position: relative;
  cursor: pointer;
  user-select: text;
  color: var(--link-internal, #086adb);
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none !important;
}
.v-v1 .reader__content__wrapper *[data-internallink]::after, .v-v1 .reader__content__wrapper *[data-internallink]:not([href])::after,
.v-v1 .reader__content__wrapper *[data-shortlinkid]::after,
.v-v1 .reader__content__wrapper *[data-shortlinkid]:not([href])::after,
.v-v1 .reader__content__wrapper *[data-shortlinkurl]::after,
.v-v1 .reader__content__wrapper *[data-shortlinkurl]:not([href])::after,
.v-v1 .reader__content__wrapper .internal-link::after,
.v-v1 .reader__content__wrapper .internal-link:not([href])::after {
  content: "";
  font-family: "icon-font.v-v1";
  display: inline-block;
  margin-left: 8px;
}
.v-v1 .reader__content__wrapper p + a, .v-v1 .reader__content__wrapper p + *[data-shortlinkid],
.v-v1 .reader__content__wrapper p + *[data-shortlinkurl],
.v-v1 .reader__content__wrapper p + *[data-internallink] {
  display: block;
  margin-top: -16px !important;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .v-v1 .reader__content__wrapper p + a, .v-v1 .reader__content__wrapper p + *[data-shortlinkid],
  .v-v1 .reader__content__wrapper p + *[data-shortlinkurl],
  .v-v1 .reader__content__wrapper p + *[data-internallink] {
    margin-top: -24px;
    margin-bottom: 24px;
  }
}
.v-v1 .reader__content__wrapper *[data-videourl] {
  cursor: pointer;
}
.v-v1 .reader__navigation__menu {
  background: var(--navigation-background, #ffffff);
  z-index: 95;
}
@supports (-webkit-touch-callout: none) {
  .v-v1 .reader__navigation__menu {
    padding-bottom: 80px;
  }
}
.v-v1 .reader__navigation__menu a, .v-v1 .reader__navigation__menu *[data-shortlinkid],
.v-v1 .reader__navigation__menu *[data-shortlinkurl] {
  text-decoration: none !important;
  overflow: auto;
}
.v-v1 .level {
  display: block;
  min-height: 56px;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--navigation-default-border-bottom, transparent);
}
.v-v1 .level > :nth-child(n) {
  padding-top: 12px;
  padding-bottom: 12px;
}
.v-v1 .level .text {
  padding-left: 24px;
  color: var(--navigation-color, #333333);
  flex: 1 1 100%;
  width: 100%;
}
.v-v1 .level .arrow {
  margin-left: 16px;
  color: var(--navigation-arrow, #333333);
  padding-right: 24px;
}
.v-v1 .level .color {
  display: none;
}
.v-v1 .level.selected {
  background-color: var(--navigation-selected-background, #eef3f6);
}
.v-v1 .level.selected .text {
  color: var(--navigation-selected-color, #333333) !important;
}
.v-v1 .level.expanded:not(.selected) {
  background-color: var(--navigation-expanded-background, #ffffff);
}
.v-v1 .level.expanded:not(.selected) .text {
  color: var(--navigation-expanded-color, #333333) !important;
}
.v-v1 .level:hover {
  background-color: var(--navigation-hover-background, #f8fafb);
}
.v-v1 .level:hover .text {
  color: var(--navigation-hover-color, #333333) !important;
}
.v-v1 .submenu {
  display: none;
}
.v-v1 .submenu.expanded {
  display: block;
}
.reader__app.overlay-shown {
  overflow: hidden;
}
.reader__app.overlay-shown .reader__overlay {
  display: flex;
}

.v-v1 .sidebar {
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
}
.v-v1 .sidebar.small-header {
  top: 60px;
}
@media (min-width: 768px) {
  .v-v1 .sidebar.small-header {
    top: 80px;
  }
}
.v-v1 .reader__body {
  padding-top: 80px;
}
.v-v1 .reader__body.navigation-shown .sidebar {
  opacity: 1;
  visibility: visible;
}
.v-v1 .reader__body.small-header {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .v-v1 .sidebar {
    position: fixed;
    width: 320px;
    opacity: 1;
    visibility: visible;
  }
  .v-v1 .sidebar {
    left: -328px;
    right: auto;
  }
  .v-v1 .reader__body.navigation-shown .sidebar {
    left: 0;
    overflow: visible;
  }
  .v-v1 .reader__body.small-header {
    padding-top: 80px;
  }
}
.v-v1 .reader__content__wrapper {
  display: none !important;
  height: calc(100vh - 80px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.v-v1 .reader__content__wrapper.active {
  display: block !important;
}
@supports (-webkit-touch-callout: none) {
  .v-v1 .reader__content__wrapper {
    padding-bottom: 80px;
  }
}
.v-v1 .reader__content {
  position: relative;
  margin: 0 auto;
  z-index: 90;
}
@media (min-width: 768px) {
  .v-v1 .reader__body.navigation-shown .reader__content__wrapper {
    display: block;
    margin-left: 320px;
  }
}
.v-v1 .reader__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: var(--modal-overlay-backdrop, rgba(0, 0, 0, 0.3));
  z-index: 200;
}
@media only screen and (max-width: 767px) {
  .v-v1 .reader__overlay.reader__body {
    padding-top: 0px;
  }
}
.v-v1 .reader__overlay .overlay__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
  background-color: var(--modal-background, #ffffff);
}
.v-v1 .reader__overlay .header {
  position: relative;
  flex: 0 0 60px;
  height: 60px;
}
.v-v1 .reader__overlay .header .icon-font {
  position: absolute;
  color: var(--modal-close-icon, #333333);
  top: 24px;
  right: 24px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.v-v1 .reader__overlay .reader__content {
  flex: 0 1 auto;
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  overflow: auto;
}
.v-v1 .reader__overlay .footer {
  flex: 0 0 50px;
  height: 50px;
}
.v-v1 .reader__overlay .reader__body.navigation-shown .reader__content__wrapper {
  margin-left: 0;
}
@media (min-width: 768px) {
  .v-v1 .reader__overlay {
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  .v-v1 .reader__overlay .overlay__container {
    flex: 0 0 auto;
    height: auto;
  }
  .v-v1 .reader__overlay .overlay__container .reader__content {
    max-height: calc(80vh - 110px);
  }
  .v-v1 .reader__overlay .overlay__container .header {
    flex: 0 0 90px;
  }
  .v-v1 .reader__overlay .overlay__container .footer {
    flex: 0 0 75px;
  }
}
.v-v1 .reader__body .overlay.order-form--shown {
  display: block;
}
.v-v1 .order-form .content {
  width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  color: var(--order-form, #333333);
}
@media (min-width: 768px) {
  .v-v1 .order-form .content {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .v-v1 .order-form .content {
    padding-right: 0;
  }
}
.navigation-shown .v-v1 .order-form .content {
  padding-left: 40px;
  padding-right: 40px;
}

.v-v1 .order-form .content form {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
  padding: 25px 10px;
}
@media (min-width: 768px) {
  .v-v1 .order-form .content form {
    padding: 48px;
  }
}
.v-v1 .order-form__actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 20px 0;
}
.v-v1 .order-form__actions input[type=submit] {
  margin-left: 40px;
}
.v-v1 .order-form__actions a, .v-v1 .order-form__actions *[data-shortlinkid],
.v-v1 .order-form__actions *[data-shortlinkurl] {
  font-size: 16px;
  text-decoration: none;
}
.v-v1 .order-form__actions a, .v-v1 .order-form__actions *[data-shortlinkid],
.v-v1 .order-form__actions *[data-shortlinkurl], .v-v1 .order-form__actions a:active, .v-v1 .order-form__actions a:visited {
  color: var(--order-form-link, #333333);
}
.v-v1 .order-form__actions .spinner__loader {
  display: inline-block;
  margin: 0 0 0 10px;
  align-self: 10px;
}
.v-v1 .order-form label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  color: var(--order-form-label, #333333);
}
.v-v1 .order-form input {
  display: block;
  width: 100%;
  padding: 8px 15px;
  box-shadow: none;
  border: 2px solid var(--order-form-input-border, #333333);
  border-radius: 6px;
  background-color: var(--order-form-input-background, #333333);
  color: var(--order-form-input, #333333);
  transition: border 160ms linear;
}
.v-v1 .order-form input:focus, .v-v1 .order-form input:active {
  outline: none;
  border: 2px solid var(--order-form-input-active-border, #333333);
}
.v-v1 .order-form input::-ms-expand {
  display: none;
}
.v-v1 .order-form input[type=submit] {
  max-width: 240px;
  min-width: 240px;
  border: 0;
  width: auto;
  height: auto;
  display: inline-block;
  padding: 11px 22px;
  background: var(--order-form-submit-background, #333333);
  border-radius: 5px;
  color: var(--order-form-submit, #ffffff);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}
@media (min-width: 768px) {
  .v-v1 .order-form input[type=submit] {
    font-size: 18px;
  }
}
.v-v1 .order-form input[type=submit]:hover {
  background: var(--order-form-submit-hover-background, rgba(0, 0, 0, 0.3));
}
.v-v1 .order-form .form {
  margin-top: 50px;
}
.v-v1 .order-form .form-group {
  margin-bottom: 15px;
}
.v-v1 .order-form .form-group__error label {
  color: var(--order-form-error-label, #333333);
}
.v-v1 .order-form .form-group__error input {
  background-color: var(--order-form-error-input-background, #333333);
  border-color: var(--order-form-error-input-border, #333333);
}
.v-v1 .order-form .form-group__error > .form-group__error-message {
  margin-top: 8px;
  color: var(--order-form-error-message, #333333);
  font-weight: 300;
}
.v-v1 .order-form .form-group--radio-buttons .radio-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.v-v1 .order-form .form-group--radio-buttons .radio-buttons > div {
  flex: 0 1 auto;
  margin-right: 20px;
}
.v-v1 .order-form .form-group--radio-buttons input[type=radio] {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.v-v1 .order-form .form-group__submit {
  margin-top: 50px;
}
.v-v1 .order-form .form__copies {
  margin-bottom: 50px;
  max-width: 300px;
}
.reader-body .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 94;
  margin-top: 80px;
  overflow: auto;
  background: white;
}
.reader-body .overlay--shown {
  display: block;
}
@media (min-width: 768px) {
  .reader-body.navigation-shown .overlay {
    margin-left: 320px;
  }
}

.v-v1 .reader-tabs {
  display: none;
  align-self: flex-end;
  overflow: visible;
  padding-left: 0;
  margin-left: -1px;
  margin-bottom: -1px;
  width: 100%;
  height: 100%;
}
.v-v1 .reader-tabs__wrapper {
  display: inline-flex;
  flex-direction: row;
}
.v-v1 .reader-tabs__additional-buttons {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-end;
}
.v-v1 .reader-tabs__init-render {
  visibility: hidden;
  position: fixed;
  top: -2000px;
  display: flex;
}
.v-v1 .reader-tabs__reports {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.v-v1 .reader-tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  height: 50px;
  background-color: var(--reader-tabs-tab-background, #f8fafb);
  padding: 0 20px;
  border: 1px solid var(--reader-tabs-border-color, #dde3e7);
  border-bottom: 1px solid var(--reader-tabs-border-bottom-color, #dde3e7);
  border-width: 1px 0 1px 1px;
  cursor: pointer;
  text-decoration: none;
  color: var(--reader-tabs-tab-color, #015);
}
.v-v1 .reader-tabs__tab:hover {
  text-decoration: none;
  color: var(--reader-tabs-tab-color, #015);
}
.v-v1 .reader-tabs__tab > .icon-font-content-close {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: -20px;
  padding: 0 10px;
  font-size: 12px;
}
.v-v1 .reader-tabs__tab > span:first-of-type {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-v1 .reader-tabs__tab:nth-child(n+2) {
  margin-left: 0;
}
.v-v1 .reader-tabs__tab:last-child {
  border-right-width: 1px;
}
.v-v1 .reader-tabs__tab:hover {
  background-color: var(--reader-tabs-tab-background-hover, #ffffff);
}
.v-v1 .reader-tabs__tab.selected {
  color: var(--reader-tabs-tab-selected-color, #015);
  background-color: var(--reader-tabs-tab-background-selected, #ffffff);
  border-bottom: transparent;
  border-bottom: 1px solid var(--reader-tabs-selected-border-bottom-color, #ffffff);
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons {
  margin: 0;
  border-bottom: 1px solid transparent;
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .reader-tabs__tab {
  color: var(--reader-tabs-add-new-color, #086adb);
  font-weight: inherit;
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .dropdown--show-all-tabs {
  top: 100%;
  left: 50%;
  padding: 0;
  width: 300px;
  margin-top: 18px;
  margin-left: 0;
  transform: translateX(-50%);
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .dropdown--show-all-tabs a, .v-v1 .reader-tabs .reader-tabs__additional-buttons .dropdown--show-all-tabs *[data-shortlinkid],
.v-v1 .reader-tabs .reader-tabs__additional-buttons .dropdown--show-all-tabs *[data-shortlinkurl] {
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .show-all-button {
  position: relative;
  color: var(--reader-tabs-add-new-color, #086adb);
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .add-new-button {
  min-width: 150px;
  background-color: var(--reader-tabs-add-new-background, transparent);
  border: 1px solid var(--reader-tabs-add-new-border, transparent);
  border-bottom: var(--reader-tabs-border-color, #dde3e7);
  color: var(--reader-tabs-add-new-color, #086adb);
  white-space: nowrap;
}
.v-v1 .reader-tabs .reader-tabs__additional-buttons .add-new-button .icon-font {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--reader-tabs-add-new-color, #086adb);
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  font-size: 14px;
}
.v-v1 *[data-reader-social-media-enabled] {
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-v1 *[data-reader-social-media-enabled]::before {
  text-align: center;
  cursor: pointer;
  content: "8"/"";
  font-family: "icon-font";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -44px;
  font-size: 14px;
  width: 44px;
  color: var(--share-button-color, #333333);
}
.v-v1 *[data-reader-social-media-enabled]::before:hover {
  color: var(--share-button-hover-color, #333333);
}
.v-v1 .webui-popover {
  z-index: 95;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--share-border, #1781e3);
  border-top: var(--share-border-top, #1781e3);
  padding: 0;
  background-color: 0;
  background: var(--share-background, #f0f0f0);
  border-radius: 0;
  margin-top: 88px;
  margin-left: 268px;
  width: 304px;
}
.v-v1 .webui-popover .webui-arrow {
  display: none;
}
.v-v1 .webui-popover a::after, .v-v1 .webui-popover [data-shortlinkid]::after,
.v-v1 .webui-popover [data-shortlinkurl]::after {
  display: none !important;
}
.v-v1 .webui-popover .webui-popover-title,
.v-v1 .webui-popover .webui-popover-content {
  background: var(--share-background, #f0f0f0);
  color: var(--share-font-color, #333333);
  border: 0;
}
.v-v1 .webui-popover .webui-popover-title {
  font-size: inherit;
  font-weight: bold;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing {
  outline: none;
  text-decoration: none;
  display: block;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--share-popover-border, transparent);
  border-radius: 50%;
  transition: all 0.25s ease-out;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing:hover {
  background: var(--share-icon-icon-hover-background, #1781e3);
  border-color: var(--share-icon-icon-hover-border, transparent);
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing:active {
  border-color: var(--share-icon-icon-active-border, #ffffff);
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing:active > i {
  color: var(--share-icon-icon-active-border, #ffffff);
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing:not(:last-of-type) {
  margin: 0 20px 0 0;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing > i {
  font-size: 24px;
  color: var(--share-icon, #1781e3);
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing > i.icon-font-linkedin {
  margin: -2px 0 0 2px;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing > i.icon-font-facebook {
  margin: 0 0 0 -2px;
}
.v-v1 .webui-popover .webui-popover-content .reader-sharing-tools .social-media-sharing > i.icon-font-twitter {
  margin: 2px 0 0 2px;
}
.v-v1 .report-switcher ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.v-v1 .report-switcher ul li {
  color: var(--report-switcher-li, #333333);
  padding: 0;
  margin: 0;
  background: none;
}
.v-v1 .report-switcher__hint-text {
  padding: var(--report-switcher-hint-text-padding, 8px 10px);
  color: var(--report-switcher-hint-text-color, black);
}
.v-v1 .report-switcher .badge {
  margin: 0 20px;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 14px;
  color: var(--report-switcher-badge, #333333);
}
.v-v1 .report-switcher .badge--violet {
  background-color: var(--report-switcher-badge-background, #ffffff);
}
.v-v1 .report-switcher__input {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 280px;
  margin: 20px auto 0 auto;
  padding: 10px 20px;
  background: var(--report-switcher-input-background, #ffffff);
  border: 1px solid var(--report-switcher-input-border, #e4e9ec);
  color: var(--report-switcher-input, #333333);
  height: 44px;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 8px;
  border-radius: 4px;
}
.v-v1 .report-switcher__input::-ms-expand {
  display: none;
}
@media (min-width: 1200px) {
  .v-v1 .report-switcher__input {
    display: none;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .v-v1 .report-switcher__input--show {
    display: flex;
  }
}
.v-v1 .report-switcher__popup-backdrop {
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--modal-overlay-backdrop, rgba(0, 0, 0, 0.3));
  z-index: 1000;
}
.v-v1 .report-switcher__popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--report-switcher-popup-background, #ffffff);
  padding: 40px 0 0 0;
  z-index: 1001;
  grid-template-rows: auto 1fr auto;
}
@media (min-width: 768px) {
  .v-v1 .report-switcher__popup {
    padding: 74px 120px 48px 120px;
  }
}
.v-v1 .report-switcher__popup > :nth-child(3) {
  align-self: flex-end;
}
@media (min-width: 768px) {
  .v-v1 .report-switcher__popup {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    min-width: 700px;
    max-width: 700px;
  }
}
.v-v1 .report-switcher--open .report-switcher__popup,
.v-v1 .report-switcher--open .report-switcher__popup-backdrop {
  display: block;
}
.v-v1 .report-switcher__popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: var(--report-switcher-popup-close, #1781e3);
  font-size: 24px;
}
.v-v1 .report-switcher .report-list {
  height: 100%;
  overflow: auto;
}
@media (min-width: 768px) {
  .v-v1 .report-switcher .report-list {
    max-height: 45vh;
  }
}
.v-v1 .report-switcher .report-list li .entry {
  display: block;
  color: var(--report-switcher-popup-list-li, #333333);
  text-decoration: none;
  padding: 8px 10px;
}
.v-v1 .report-switcher .report-list li .entry.entry-different-languages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.v-v1 .report-switcher .report-list li .languages {
  margin: 0 2px;
}
.v-v1 .report-switcher .report-list li .language-entry {
  color: var(--report-switcher-popup-list-language, #333333);
  text-decoration: none;
  padding: 0 2px;
}
.v-v1 .report-switcher .report-list li:hover {
  background-color: var(--report-switcher-popup-list-li-hover-background, #d1e6f9);
}
.v-v1 .report-switcher .report-list li:hover,
.v-v1 .report-switcher .report-list li:hover a,
.v-v1 .report-switcher .report-list li:hover *[data-shortlinkid],
.v-v1 .report-switcher .report-list li:hover *[data-shortlinkurl] {
  color: var(--report-switcher-popup-list-li-hover, #333333);
}
.v-v1 .report-switcher .report-list li.collapsable:hover {
  background-color: transparent;
}
.v-v1 .report-switcher .report-list li.collapsable > .collapsable__title:hover {
  background-color: var(--report-switcher-popup-list-li-hover-background, #d1e6f9);
}
.v-v1 .report-switcher .report-list > li {
  font-size: 16px;
  border-bottom: 1px solid var(--report-switcher-popup-list-li-border-bottom, #1781e3);
}
.v-v1 .report-switcher .report-list > li > a, .v-v1 .report-switcher .report-list > li > *[data-shortlinkid],
.v-v1 .report-switcher .report-list > li > *[data-shortlinkurl] {
  color: var(--report-switcher-popup-list-li-a, #333333);
}
.v-v1 .report-switcher .collapsable__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid var(--report-switcher-popup-collapsable-title-border-bottom, #333333);
}
.v-v1 .report-switcher .collapsable__title .icon-font {
  color: var(--report-switcher-popup-collapsable-icon-font, #333333);
}
.v-v1 .report-switcher .collapsable > ul {
  display: none;
  border-top: none;
  margin: 0 0 32px 0;
}
.v-v1 .report-switcher .collapsable li {
  border-bottom: 1px solid var(--report-switcher-popup-collapsable-li-border-bottom, #333333);
  color: var(--report-switcher-popup-collapsable-li-color, #333333);
  padding: 8px 24px;
}
.v-v1 .report-switcher .collapsable li:last-child {
  border-bottom: none;
}
.v-v1 .report-switcher .collapsable--open > .collapsable__title > .icon-font-expand {
  transform: rotate(180deg);
}
.v-v1 .report-switcher .collapsable--open > ul {
  display: block !important;
}
.v-v1 .report-switcher .collapsable .collapsable {
  padding-left: 0;
  padding-right: 0;
}
.v-v1 .report-switcher .collapsable .collapsable > .collapsable__title {
  padding-left: 8px;
  padding-right: 8px;
}
.v-v1 .report-switcher .collapsable .collapsable li a, .v-v1 .report-switcher .collapsable .collapsable li *[data-shortlinkid],
.v-v1 .report-switcher .collapsable .collapsable li *[data-shortlinkurl] {
  padding-left: 24px;
  padding-right: 24px;
}
.v-v1 .reader__search__menu {
  padding: 16px 0;
  border-bottom: 1px solid var(--search-border-bottom, transparent);
  border-right: 1px solid var(--search-border-right, transparent);
}
.v-v1 .reader__search__menu a, .v-v1 .reader__search__menu *[data-shortlinkid],
.v-v1 .reader__search__menu *[data-shortlinkurl] {
  text-decoration: none;
}
.v-v1 .reader__search__menu .input__group--search {
  margin: 0 15px 5px;
}
.v-v1 .reader__search__menu .search-results-title {
  padding: 24px 16px;
  color: var(--search-title-color, #333333);
}
.v-v1 .reader__search__menu .search-results-title h3, .v-v1 .reader__search__menu .search-results-title .swisscom-about-box-title-1zeile,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-box-title-2zeilen, .v-v1 .reader__search__menu .search-results-title .swisscom-about-value-navy-36pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-navy-30pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-grey-36pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-grey-30pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-grey-29pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-navy-25pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-grey-25pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-navy-21pt,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value-navy-30pt_small,
.v-v1 .reader__search__menu .search-results-title .swisscom-about-value, .v-v1 .reader__search__menu .search-results-title .h3 {
  margin: 0;
  text-align: center;
}
.v-v1 .reader__search__menu .results .result {
  padding: 16px;
  border-bottom: 1px solid var(--search-result-border-bottom, #f8fafb);
  cursor: pointer;
  margin-left: 1px;
  word-break: break-word;
  margin-right: 1px;
}
.v-v1 .reader__search__menu .results .result h4, .v-v1 .reader__search__menu .results .result .h4 {
  color: var(--search-result-title, );
}
.v-v1 .reader__search__menu .results .result:hover {
  transition: background 0.25s ease-in-out;
  background-color: var(--search-result-hover-background, #eef3f6);
}
.v-v1 .reader__search__menu .results .result p {
  color: var(--search-result-text, );
  margin-bottom: 0;
}
.v-v1 .search-highlight {
  color: var(--search-highlight, #333333);
  padding: 0 3px;
  background: var(--search-highlight-background, #d1e6f9);
}
.v-v1 .reader__body .sidebar {
  overflow: hidden;
  background-color: var(--sidebar-background, #ffffff);
  border-right: 1px solid var(--sidebar-border-right, transparent);
  z-index: 99;
}
.v-v1 .reader__body .sidebar > *:nth-child(2) {
  flex: 0 0 auto;
  height: 100%;
}
.v-v1 .reader__body .sidebar .tabcontrol {
  min-height: 0;
}
.v-v1 .spinner__loader {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 10px auto 10px;
  font-size: 10px;
  text-indent: -9999em;
  border-radius: 50%;
  background: var(--spinner-background-1, #1781e3);
  background: linear-gradient(to right, var(--spinner-background-1, #1781e3) 10%, rgba(var(--spinner-background-2, #ffffff), 0) 42%);
}
.v-v1 .spinner__loader.animate {
  animation: load_spinner_loader_keyframes 0.8s infinite linear;
  transform: translateZ(0);
}
.v-v1 .spinner__loader:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background: var(--spinner-background, );
  border-radius: 100% 0 0 0;
  content: "";
}
.v-v1 .spinner__loader:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 75%;
  height: 75%;
  margin: auto;
  background: var(--spinner-after-background, #ffffff);
  border-radius: 50%;
  content: "";
}
@keyframes load_spinner_loader_keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-v1 .reader__body .gb-table {
  width: 100%;
  border-collapse: collapse;
}
.v-v1 .reader__body .gb-table th,
.v-v1 .reader__body .gb-table td {
  min-width: var(--fixed-width, unset);
}
.v-v1 .reader__body .gb-table img {
  display: inline-block;
  margin: 0;
}
.v-v1 .reader__body .gb-table th {
  vertical-align: bottom;
  color: var(--table-headerrow, #333333);
}
.v-v1 .reader__body .gb-table th.highlight {
  border-top: 1px solid var(--table-highlight-border-top, transparent);
}
.v-v1 .reader__body .gb-table th.line-dark,
.v-v1 .reader__body .gb-table tr.line-dark {
  border-bottom: 2px solid var(--table-linedark-border, #999999);
}
.v-v1 .reader__body .gb-table th.line-medium,
.v-v1 .reader__body .gb-table tr.line-medium {
  border-bottom: 1px solid var(--table-linemedium-border, #999999);
}
.v-v1 .reader__body .gb-table th.line-light,
.v-v1 .reader__body .gb-table tr.line-light {
  border-bottom: 1px solid var(--table-linelight-border, #bbbbbb);
}
.v-v1 .reader__body .gb-table th.line-thin,
.v-v1 .reader__body .gb-table tr.line-thin {
  border-bottom: 1px solid var(--table-linethin-border, #6c6c6c);
}
.v-v1 .reader__body .gb-table th.reader-table-noline,
.v-v1 .reader__body .gb-table tr.reader-table-noline {
  border-bottom: 0 !important;
}
@media only screen and (min-width: 768px) {
  .v-v1 .reader__body .gb-table tr.row-spacing {
    height: 12px;
  }
  .v-v1 .reader__body .gb-table tr.row-spacing > td {
    padding: 0 !important;
  }
  .v-v1 .reader__body .gb-table tr.row-spacing > td > * {
    display: none !important;
  }
}
.v-v1 .reader__body .gb-table tr.line-title td {
  padding-top: 12px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid var(--table-linetitle-border, #000000) !important;
}
.v-v1 .reader__body .gb-table tr.line-footnote {
  border-bottom: 1px solid var(--table-linefootnote-border, transparent);
}
.v-v1 .reader__body .gb-table th,
.v-v1 .reader__body .gb-table td {
  padding: 5px 10px 5px 4px;
  color: var(--table-stackable-thtd-color, #000000);
}
.v-v1 .reader__body .gb-table th.superscript,
.v-v1 .reader__body .gb-table td.superscript {
  padding: 5px;
}
.v-v1 .reader__body .gb-table th.reader-table-noline,
.v-v1 .reader__body .gb-table td.reader-table-noline {
  border-bottom: 0 !important;
}
.v-v1 .reader__body .gb-table .footnote {
  padding-top: 4px !important;
  padding-bottom: 0 !important;
}
.v-v1 .reader__body .gb-table .footnote .tablesaw-cell-content > div {
  text-align: left;
}
.v-v1 .reader__body .gb-table .footnote-title {
  display: block;
}
.v-v1 .reader__body .gb-table tr.suppress {
  display: none !important;
}
.v-v1 .reader__body .gb-table tr td:first-child {
  text-align: left;
}
.v-v1 .reader__body .gb-table .bold {
  font-weight: bold;
  color: var(--table-entry-bold, #333333);
}
.v-v1 .reader__body .gb-table .desc {
  vertical-align: top;
  color: var(--table-description, #333333);
}
.v-v1 .reader__body .gb-table .desc-light {
  color: var(--table-desclight-color, #6c6c6c);
  text-align: left;
}
.v-v1 .reader__body .gb-table .number {
  text-align: right;
  color: var(--table-number-color, #000000);
}
.v-v1 .reader__body .gb-table .icon {
  text-align: center;
  padding: 5px 10px;
}
.v-v1 .reader__body .gb-table .highlight {
  color: var(--table-highlight-color, #000000);
  background: var(--table-highlight-background, #eef3f6);
  border-right: 2px solid var(--table-highlight-border, #eef3f6);
  border-left: 2px solid var(--table-highlight-border, #eef3f6);
}
.v-v1 .reader__body .gb-table .highlight.line-thin {
  border-right: 0;
  border-right: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-2,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-2 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-2 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-2 + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-3,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-3 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-3 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-3 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-3 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-3 + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-4,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-4 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-4 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-4 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-4 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-4 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-4 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-4 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-5,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-5 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-5 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-5 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-5 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-5 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-5 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-5 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-5 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-5 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-6 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-7 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-8 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-9 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-10 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-11 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-12 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-13 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-14 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 {
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
  border-right-style: none;
}
.v-v1 .reader__body .gb-table th.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight,
.v-v1 .reader__body .gb-table td.highlight.highlight-span-15 + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight + * + *.highlight {
  border-left-style: none;
}
.v-v1 .reader__body .gb-table .width1 {
  width: 1%;
}
.v-v1 .reader__body .gb-table .width2 {
  width: 2%;
}
.v-v1 .reader__body .gb-table .width3 {
  width: 3%;
}
.v-v1 .reader__body .gb-table .width4 {
  width: 4%;
}
.v-v1 .reader__body .gb-table .width5 {
  width: 5%;
}
.v-v1 .reader__body .gb-table .width6 {
  width: 6%;
}
.v-v1 .reader__body .gb-table .width7 {
  width: 7%;
}
.v-v1 .reader__body .gb-table .width8 {
  width: 8%;
}
.v-v1 .reader__body .gb-table .width9 {
  width: 9%;
}
.v-v1 .reader__body .gb-table .width10 {
  width: 10%;
}
.v-v1 .reader__body .gb-table .width11 {
  width: 11%;
}
.v-v1 .reader__body .gb-table .width12 {
  width: 12%;
}
.v-v1 .reader__body .gb-table .width13 {
  width: 13%;
}
.v-v1 .reader__body .gb-table .width14 {
  width: 14%;
}
.v-v1 .reader__body .gb-table .width15 {
  width: 15%;
}
.v-v1 .reader__body .gb-table .width16 {
  width: 16%;
}
.v-v1 .reader__body .gb-table .width17 {
  width: 17%;
}
.v-v1 .reader__body .gb-table .width18 {
  width: 18%;
}
.v-v1 .reader__body .gb-table .width19 {
  width: 19%;
}
.v-v1 .reader__body .gb-table .width20 {
  width: 20%;
}
.v-v1 .reader__body .gb-table .width21 {
  width: 21%;
}
.v-v1 .reader__body .gb-table .width22 {
  width: 22%;
}
.v-v1 .reader__body .gb-table .width23 {
  width: 23%;
}
.v-v1 .reader__body .gb-table .width24 {
  width: 24%;
}
.v-v1 .reader__body .gb-table .width25 {
  width: 25%;
}
.v-v1 .reader__body .gb-table .width26 {
  width: 26%;
}
.v-v1 .reader__body .gb-table .width27 {
  width: 27%;
}
.v-v1 .reader__body .gb-table .width28 {
  width: 28%;
}
.v-v1 .reader__body .gb-table .width29 {
  width: 29%;
}
.v-v1 .reader__body .gb-table .width30 {
  width: 30%;
}
.v-v1 .reader__body .gb-table .width31 {
  width: 31%;
}
.v-v1 .reader__body .gb-table .width32 {
  width: 32%;
}
.v-v1 .reader__body .gb-table .width33 {
  width: 33%;
}
.v-v1 .reader__body .gb-table .width34 {
  width: 34%;
}
.v-v1 .reader__body .gb-table .width35 {
  width: 35%;
}
.v-v1 .reader__body .gb-table .width36 {
  width: 36%;
}
.v-v1 .reader__body .gb-table .width37 {
  width: 37%;
}
.v-v1 .reader__body .gb-table .width38 {
  width: 38%;
}
.v-v1 .reader__body .gb-table .width39 {
  width: 39%;
}
.v-v1 .reader__body .gb-table .width40 {
  width: 40%;
}
.v-v1 .reader__body .gb-table .width41 {
  width: 41%;
}
.v-v1 .reader__body .gb-table .width42 {
  width: 42%;
}
.v-v1 .reader__body .gb-table .width43 {
  width: 43%;
}
.v-v1 .reader__body .gb-table .width44 {
  width: 44%;
}
.v-v1 .reader__body .gb-table .width45 {
  width: 45%;
}
.v-v1 .reader__body .gb-table .width46 {
  width: 46%;
}
.v-v1 .reader__body .gb-table .width47 {
  width: 47%;
}
.v-v1 .reader__body .gb-table .width48 {
  width: 48%;
}
.v-v1 .reader__body .gb-table .width49 {
  width: 49%;
}
.v-v1 .reader__body .gb-table .width50 {
  width: 50%;
}
.v-v1 .reader__body .gb-table .width51 {
  width: 51%;
}
.v-v1 .reader__body .gb-table .width52 {
  width: 52%;
}
.v-v1 .reader__body .gb-table .width53 {
  width: 53%;
}
.v-v1 .reader__body .gb-table .width54 {
  width: 54%;
}
.v-v1 .reader__body .gb-table .width55 {
  width: 55%;
}
.v-v1 .reader__body .gb-table .width56 {
  width: 56%;
}
.v-v1 .reader__body .gb-table .width57 {
  width: 57%;
}
.v-v1 .reader__body .gb-table .width58 {
  width: 58%;
}
.v-v1 .reader__body .gb-table .width59 {
  width: 59%;
}
.v-v1 .reader__body .gb-table .width60 {
  width: 60%;
}
.v-v1 .reader__body .gb-table .width61 {
  width: 61%;
}
.v-v1 .reader__body .gb-table .width62 {
  width: 62%;
}
.v-v1 .reader__body .gb-table .width63 {
  width: 63%;
}
.v-v1 .reader__body .gb-table .width64 {
  width: 64%;
}
.v-v1 .reader__body .gb-table .width65 {
  width: 65%;
}
.v-v1 .reader__body .gb-table .width66 {
  width: 66%;
}
.v-v1 .reader__body .gb-table .width67 {
  width: 67%;
}
.v-v1 .reader__body .gb-table .width68 {
  width: 68%;
}
.v-v1 .reader__body .gb-table .width69 {
  width: 69%;
}
.v-v1 .reader__body .gb-table .width70 {
  width: 70%;
}
.v-v1 .reader__body .gb-table .width71 {
  width: 71%;
}
.v-v1 .reader__body .gb-table .width72 {
  width: 72%;
}
.v-v1 .reader__body .gb-table .width73 {
  width: 73%;
}
.v-v1 .reader__body .gb-table .width74 {
  width: 74%;
}
.v-v1 .reader__body .gb-table .width75 {
  width: 75%;
}
.v-v1 .reader__body .gb-table .width76 {
  width: 76%;
}
.v-v1 .reader__body .gb-table .width77 {
  width: 77%;
}
.v-v1 .reader__body .gb-table .width78 {
  width: 78%;
}
.v-v1 .reader__body .gb-table .width79 {
  width: 79%;
}
.v-v1 .reader__body .gb-table .width80 {
  width: 80%;
}
.v-v1 .reader__body .gb-table .width81 {
  width: 81%;
}
.v-v1 .reader__body .gb-table .width82 {
  width: 82%;
}
.v-v1 .reader__body .gb-table .width83 {
  width: 83%;
}
.v-v1 .reader__body .gb-table .width84 {
  width: 84%;
}
.v-v1 .reader__body .gb-table .width85 {
  width: 85%;
}
.v-v1 .reader__body .gb-table .width86 {
  width: 86%;
}
.v-v1 .reader__body .gb-table .width87 {
  width: 87%;
}
.v-v1 .reader__body .gb-table .width88 {
  width: 88%;
}
.v-v1 .reader__body .gb-table .width89 {
  width: 89%;
}
.v-v1 .reader__body .gb-table .width90 {
  width: 90%;
}
.v-v1 .reader__body .gb-table .width91 {
  width: 91%;
}
.v-v1 .reader__body .gb-table .width92 {
  width: 92%;
}
.v-v1 .reader__body .gb-table .width93 {
  width: 93%;
}
.v-v1 .reader__body .gb-table .width94 {
  width: 94%;
}
.v-v1 .reader__body .gb-table .width95 {
  width: 95%;
}
.v-v1 .reader__body .gb-table .width96 {
  width: 96%;
}
.v-v1 .reader__body .gb-table .width97 {
  width: 97%;
}
.v-v1 .reader__body .gb-table .width98 {
  width: 98%;
}
.v-v1 .reader__body .gb-table .width99 {
  width: 99%;
}
.v-v1 .reader__body .gb-table .width100 {
  width: 100%;
}
.v-v1 .reader__body .gb-table .firstnote {
  padding-top: 10px;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-width {
  table-layout: fixed;
  min-width: 0px !important;
  width: auto !important;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-width th, .v-v1 .reader__body .gb-table.gb-table-fixed-width td {
  word-break: break-word;
  width: auto;
  padding-right: 4px;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-width .highlight {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  .v-v1 .reader__body .gb-table .desc--unit,
  .v-v1 .reader__body .gb-table .cell--empty {
    display: none;
  }
  .v-v1 .reader__body .gb-table .line--title .tablesaw-cell-label {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .v-v1 .reader__body .scroller {
    background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(0, 0, 0, 0.1) 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1) 100%) 100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 40px 100%, 6px 100%, 6px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, scroll, scroll;
    border-radius: 0.1px;
    user-select: none;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 1200px) {
  .v-v1 .reader__body .scroller {
    background-size: 40px 100%, 8px 100%, 8px 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .v-v1 .reader__body .scroller > *:first-of-type {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), white 50%) calc(100% + 2px) 0;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 40px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local;
  }
  .v-v1 .reader__body .scroller tr:hover td {
    background: var(--table-row-hover-background, #eef3f6) !important;
  }
  .v-v1 .reader__body .scroller:focus {
    outline: none;
  }
}
@media only screen and (max-width: 1024px) {
  .v-v1 .reader__body .stackable {
    background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(0, 0, 0, 0.1) 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1) 100%) 100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 40px 100%, 6px 100%, 6px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, scroll, scroll;
    border-radius: 0.1px;
    user-select: none;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 1200px) {
  .v-v1 .reader__body .stackable {
    background-size: 40px 100%, 8px 100%, 8px 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .v-v1 .reader__body .stackable > *:first-of-type {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), white 50%) calc(100% + 2px) 0;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 40px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local;
  }
  .v-v1 .reader__body .stackable tr:hover td {
    background: var(--table-row-hover-background, #eef3f6) !important;
  }
  .v-v1 .reader__body .stackable:focus {
    outline: none;
  }
}
.v-v1 .reader__body .scroller {
  overflow-x: auto;
  margin-bottom: 30px;
}
.v-v1 .reader__body .scroller .gb-table {
  min-width: 768px;
  width: calc(100% - 2px);
}
.v-v1 .reader__body .scroller .gb-table th.desc {
  text-align: left;
}
.v-v1 .reader__body .scroller.stackable .gb-table,
.v-v1 .reader__body .stackable.scroller .gb-table {
  min-width: 0;
}
@media (min-width: 768px) {
  .v-v1 .reader__body .scroller.stackable .gb-table,
  .v-v1 .reader__body .stackable.scroller .gb-table {
    min-width: 768px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .v-v1 .reader__body .scroller {
    overflow-x: auto;
  }
}
@-moz-document url-prefix() {
  .v-v1 .reader__body .scroller {
    overflow-x: auto;
  }
}
.v-v1 .reader__body .stackable {
  margin-bottom: 30px;
  padding: 0 1px 0 1px;
  overflow-x: auto;
}
@media (max-width: 767.9375px) {
  .v-v1 .reader__body .stackable .tablesaw-stack thead td,
  .v-v1 .reader__body .stackable .tablesaw-stack thead th {
    display: none;
  }
  .v-v1 .reader__body .stackable .tablesaw-stack tbody td,
  .v-v1 .reader__body .stackable .tablesaw-stack tbody th {
    clear: left;
    float: left;
    width: 100%;
  }
  .v-v1 .reader__body .stackable .tablesaw-cell-label {
    vertical-align: top;
  }
  .v-v1 .reader__body .stackable .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .v-v1 .reader__body .stackable .tablesaw-stack td:empty,
  .v-v1 .reader__body .stackable .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 768px) {
  .v-v1 .reader__body .stackable {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .v-v1 .reader__body .stackable .tablesaw-stack tr {
    display: table-row;
  }
  .v-v1 .reader__body .stackable .tablesaw-stack td,
  .v-v1 .reader__body .stackable .tablesaw-stack th,
  .v-v1 .reader__body .stackable .tablesaw-stack thead td,
  .v-v1 .reader__body .stackable .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .v-v1 .reader__body .stackable .tablesaw-stack td .tablesaw-cell-label,
  .v-v1 .reader__body .stackable .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}
.v-v1 .reader__body .stackable .gb-table td:not(.superscript),
.v-v1 .reader__body .stackable .gb-table th:not(.superscript) {
  padding: 16px 8px;
}
.v-v1 .reader__body .stackable .gb-table .tablesaw-cell-content {
  vertical-align: baseline;
}
.v-v1 .reader__body .stackable .gb-table tr td:first-child {
  padding-top: 12px;
}
.v-v1 .reader__body .stackable .gb-table tr td:first-child .tablesaw-cell-label {
  display: none;
}
.v-v1 .reader__body .stackable .gb-table tr td:first-child .tablesaw-cell-content {
  width: 100%;
  max-width: 100%;
}
.v-v1 .reader__body .stackable .gb-table tr td:last-child {
  padding-bottom: 12px;
}
.v-v1 .reader__body .stackable .gb-table .superscript {
  display: none;
}
.v-v1 .reader__body .stackable .gb-table .line-title td:not(:first-child) {
  display: none;
}
.v-v1 .reader__body .stackable .gb-table .highlight {
  border-right: none;
  color: var(--table-highlight-color, #000000);
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body .stackable .gb-table td:not(.superscript),
  .v-v1 .reader__body .stackable .gb-table th:not(.superscript) {
    padding: 5px 10px 5px 4px;
  }
  .v-v1 .reader__body .stackable .gb-table tr td:first-child {
    padding-top: 16px;
  }
  .v-v1 .reader__body .stackable .gb-table tr td:last-child {
    padding-bottom: 16px;
  }
  .v-v1 .reader__body .stackable .gb-table th.desc {
    text-align: left;
  }
  .v-v1 .reader__body .stackable .gb-table .superscript {
    display: table-cell;
  }
  .v-v1 .reader__body .stackable .gb-table .highlight {
    border-right: 2px solid var(--table-highlight-border, #eef3f6);
    color: var(--table-highlight-color, #000000);
  }
  .v-v1 .reader__body .stackable .gb-table .line-title td:not(:first-child) {
    display: table-cell;
  }
}
.no-touchevents .v-v1 .reader__body .gb-table tr:hover td {
  background-color: var(--table-row-hover-background, #eef3f6);
  transition: background-color 100ms linear;
}

.v-v1 .reader__body .gb-table.gb-table-fixed-font-11 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-11 td {
  font-size: 11px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-12 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-12 td {
  font-size: 12px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-13 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-13 td {
  font-size: 13px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-14 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-14 td {
  font-size: 14px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-15 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-15 td {
  font-size: 15px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-16 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-16 td {
  font-size: 16px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-17 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-17 td {
  font-size: 17px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table.gb-table-fixed-font-18 th,
.v-v1 .reader__body .gb-table.gb-table-fixed-font-18 td {
  font-size: 18px;
  line-height: 1.2;
}
.v-v1 .reader__body .gb-table th.aligned-bottom,
.v-v1 .reader__body .gb-table td.aligned-bottom {
  vertical-align: bottom;
}
.v-v1 .reader__body .gb-table th.aligned-middle,
.v-v1 .reader__body .gb-table td.aligned-middle {
  vertical-align: middle;
}
.v-v1 .reader__body .gb-table th.aligned-top,
.v-v1 .reader__body .gb-table td.aligned-top {
  vertical-align: top;
}
.dropdown {
  display: none;
  margin: 10px 0 0 0;
  padding: 10px 0;
  position: absolute;
  width: 180px;
  z-index: 100000;
  left: 0;
  right: 0;
  top: 80px;
  border: 1px solid var(--dropdown-border, #d6d6d6);
  border-width: 1px;
  background-color: var(--dropdown-background, #ffffff);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
.dropdown a, .dropdown .v-v1 *[data-shortlinkid], .v-v1 .dropdown *[data-shortlinkid],
.dropdown .v-v1 *[data-shortlinkurl],
.v-v1 .dropdown *[data-shortlinkurl] {
  display: block;
  padding: 16px;
  color: var(--dropdown-link, #001155);
  text-align: center;
  text-decoration: none;
}
.dropdown a, .dropdown .v-v1 *[data-shortlinkid], .v-v1 .dropdown *[data-shortlinkid],
.dropdown .v-v1 *[data-shortlinkurl],
.v-v1 .dropdown *[data-shortlinkurl], .dropdown a:hover {
  text-decoration: none;
}
.dropdown a:hover, .dropdown .v-v1 [data-shortlinkid]:hover, .v-v1 .dropdown [data-shortlinkid]:hover,
.dropdown .v-v1 [data-shortlinkurl]:hover,
.v-v1 .dropdown [data-shortlinkurl]:hover {
  background-color: var(--dropdown-hover-link-background, #f8fafb);
}
.dropdown a.selected, .dropdown .v-v1 .selected[data-shortlinkid], .v-v1 .dropdown .selected[data-shortlinkid],
.dropdown .v-v1 .selected[data-shortlinkurl],
.v-v1 .dropdown .selected[data-shortlinkurl] {
  position: relative;
}
.dropdown.show {
  display: block;
}

.dropdown--language {
  left: 50%;
  transform: translateX(-50%);
}

.v-v1 .text-two-columns-autoflow {
  column-count: 2;
}
.v-v1 .bg-box {
  background-color: var(--bg-box-background, );
  padding: 24px;
}
.v-v1 .bg-box > :first-child {
  margin-top: 0;
}
.v-v1 .bg-box > :last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .v-v1 .bg-box {
    padding: 32px;
  }
}
.v-v1 .signature-wrapper {
  margin-bottom: 48px;
}
.v-v1 .signature-wrapper img {
  width: auto;
  height: 60px;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .v-v1 .signature-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .v-v1 .signature-wrapper > .signature:nth-child(n+2) {
    margin-left: 35px;
  }
}
@media (min-width: 992px) {
  .v-v1 .signature-wrapper {
    margin-bottom: 72px;
  }
  .v-v1 .signature-wrapper img {
    height: 120px;
  }
  .v-v1 .signature-wrapper > .signature:nth-child(n+2) {
    margin-left: 75px;
  }
}
@media (min-width: 1200px) {
  .v-v1 .signature-wrapper > .signature:nth-child(n+2) {
    margin-left: 100px;
  }
}
.v-v1 .signature-wrapper .signature p:nth-child(n+1) {
  margin-bottom: 0;
}
.v-v1 .signature-wrapper .signature p:nth-child(n+2) {
  margin-top: 0;
}
.VersionNumber {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 8000;
}
.VersionNumber-number {
  font-weight: bold;
}

.v-v1 .atlwdg-trigger {
  z-index: 10000;
}
.v-v1 .text-column-1 {
  column-count: 1;
}
.v-v1 .text-column-gap-1 {
  column-gap: 4px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-xs-1 {
    column-gap: 4px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-sm-1 {
    column-gap: 4px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-md-1 {
    column-gap: 4px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-lg-1 {
    column-gap: 4px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-xl-1 {
    column-gap: 4px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-1 {
    column-count: 1;
  }
  .v-v1 .text-column-gap-xxl-1 {
    column-gap: 4px;
  }
}
.v-v1 .text-column-2 {
  column-count: 2;
}
.v-v1 .text-column-gap-2 {
  column-gap: 8px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-xs-2 {
    column-gap: 8px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-sm-2 {
    column-gap: 8px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-md-2 {
    column-gap: 8px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-lg-2 {
    column-gap: 8px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-xl-2 {
    column-gap: 8px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-2 {
    column-count: 2;
  }
  .v-v1 .text-column-gap-xxl-2 {
    column-gap: 8px;
  }
}
.v-v1 .text-column-3 {
  column-count: 3;
}
.v-v1 .text-column-gap-3 {
  column-gap: 12px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-xs-3 {
    column-gap: 12px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-sm-3 {
    column-gap: 12px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-md-3 {
    column-gap: 12px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-lg-3 {
    column-gap: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-xl-3 {
    column-gap: 12px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-3 {
    column-count: 3;
  }
  .v-v1 .text-column-gap-xxl-3 {
    column-gap: 12px;
  }
}
.v-v1 .text-column-4 {
  column-count: 4;
}
.v-v1 .text-column-gap-4 {
  column-gap: 16px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-xs-4 {
    column-gap: 16px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-sm-4 {
    column-gap: 16px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-md-4 {
    column-gap: 16px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-lg-4 {
    column-gap: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-xl-4 {
    column-gap: 16px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-4 {
    column-count: 4;
  }
  .v-v1 .text-column-gap-xxl-4 {
    column-gap: 16px;
  }
}
.v-v1 .text-column-5 {
  column-count: 5;
}
.v-v1 .text-column-gap-5 {
  column-gap: 20px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-xs-5 {
    column-gap: 20px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-sm-5 {
    column-gap: 20px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-md-5 {
    column-gap: 20px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-lg-5 {
    column-gap: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-xl-5 {
    column-gap: 20px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-5 {
    column-count: 5;
  }
  .v-v1 .text-column-gap-xxl-5 {
    column-gap: 20px;
  }
}
.v-v1 .text-column-6 {
  column-count: 6;
}
.v-v1 .text-column-gap-6 {
  column-gap: 24px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-xs-6 {
    column-gap: 24px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-sm-6 {
    column-gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-md-6 {
    column-gap: 24px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-lg-6 {
    column-gap: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-xl-6 {
    column-gap: 24px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-6 {
    column-count: 6;
  }
  .v-v1 .text-column-gap-xxl-6 {
    column-gap: 24px;
  }
}
.v-v1 .text-column-7 {
  column-count: 7;
}
.v-v1 .text-column-gap-7 {
  column-gap: 28px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-xs-7 {
    column-gap: 28px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-sm-7 {
    column-gap: 28px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-md-7 {
    column-gap: 28px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-lg-7 {
    column-gap: 28px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-xl-7 {
    column-gap: 28px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-7 {
    column-count: 7;
  }
  .v-v1 .text-column-gap-xxl-7 {
    column-gap: 28px;
  }
}
.v-v1 .text-column-8 {
  column-count: 8;
}
.v-v1 .text-column-gap-8 {
  column-gap: 32px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-xs-8 {
    column-gap: 32px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-sm-8 {
    column-gap: 32px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-md-8 {
    column-gap: 32px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-lg-8 {
    column-gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-xl-8 {
    column-gap: 32px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-8 {
    column-count: 8;
  }
  .v-v1 .text-column-gap-xxl-8 {
    column-gap: 32px;
  }
}
.v-v1 .text-column-9 {
  column-count: 9;
}
.v-v1 .text-column-gap-9 {
  column-gap: 36px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-xs-9 {
    column-gap: 36px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-sm-9 {
    column-gap: 36px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-md-9 {
    column-gap: 36px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-lg-9 {
    column-gap: 36px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-xl-9 {
    column-gap: 36px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-9 {
    column-count: 9;
  }
  .v-v1 .text-column-gap-xxl-9 {
    column-gap: 36px;
  }
}
.v-v1 .text-column-10 {
  column-count: 10;
}
.v-v1 .text-column-gap-10 {
  column-gap: 40px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-xs-10 {
    column-gap: 40px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-sm-10 {
    column-gap: 40px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-md-10 {
    column-gap: 40px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-lg-10 {
    column-gap: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-xl-10 {
    column-gap: 40px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-10 {
    column-count: 10;
  }
  .v-v1 .text-column-gap-xxl-10 {
    column-gap: 40px;
  }
}
.v-v1 .text-column-11 {
  column-count: 11;
}
.v-v1 .text-column-gap-11 {
  column-gap: 44px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-xs-11 {
    column-gap: 44px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-sm-11 {
    column-gap: 44px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-md-11 {
    column-gap: 44px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-lg-11 {
    column-gap: 44px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-xl-11 {
    column-gap: 44px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-11 {
    column-count: 11;
  }
  .v-v1 .text-column-gap-xxl-11 {
    column-gap: 44px;
  }
}
.v-v1 .text-column-12 {
  column-count: 12;
}
.v-v1 .text-column-gap-12 {
  column-gap: 48px;
}
@media screen and (min-width: 0) {
  .v-v1 .text-column-xs-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-xs-12 {
    column-gap: 48px;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .text-column-sm-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-sm-12 {
    column-gap: 48px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .text-column-md-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-md-12 {
    column-gap: 48px;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .text-column-lg-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-lg-12 {
    column-gap: 48px;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .text-column-xl-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-xl-12 {
    column-gap: 48px;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .text-column-xxl-12 {
    column-count: 12;
  }
  .v-v1 .text-column-gap-xxl-12 {
    column-gap: 48px;
  }
}
.v-v1 .border-1 {
  border: 1px solid gray;
}
.v-v1 .border-t-1 {
  border-top: 1px solid gray;
}
.v-v1 .border-r-1 {
  border-right: 1px solid gray;
}
.v-v1 .border-b-1 {
  border-bottom: 1px solid gray;
}
.v-v1 .border-l-1 {
  border-left: 1px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-xs-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-xs-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-xs-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-xs-1 {
    border-left: 1px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-sm-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-sm-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-sm-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-sm-1 {
    border-left: 1px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-md-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-md-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-md-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-md-1 {
    border-left: 1px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-lg-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-lg-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-lg-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-lg-1 {
    border-left: 1px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-xl-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-xl-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-xl-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-xl-1 {
    border-left: 1px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-1 {
    border: 1px solid gray;
  }
  .v-v1 .border-t-xxl-1 {
    border-top: 1px solid gray;
  }
  .v-v1 .border-r-xxl-1 {
    border-right: 1px solid gray;
  }
  .v-v1 .border-b-xxl-1 {
    border-bottom: 1px solid gray;
  }
  .v-v1 .border-l-xxl-1 {
    border-left: 1px solid gray;
  }
}
.v-v1 .border-2 {
  border: 2px solid gray;
}
.v-v1 .border-t-2 {
  border-top: 2px solid gray;
}
.v-v1 .border-r-2 {
  border-right: 2px solid gray;
}
.v-v1 .border-b-2 {
  border-bottom: 2px solid gray;
}
.v-v1 .border-l-2 {
  border-left: 2px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-xs-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-xs-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-xs-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-xs-2 {
    border-left: 2px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-sm-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-sm-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-sm-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-sm-2 {
    border-left: 2px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-md-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-md-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-md-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-md-2 {
    border-left: 2px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-lg-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-lg-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-lg-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-lg-2 {
    border-left: 2px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-xl-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-xl-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-xl-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-xl-2 {
    border-left: 2px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-2 {
    border: 2px solid gray;
  }
  .v-v1 .border-t-xxl-2 {
    border-top: 2px solid gray;
  }
  .v-v1 .border-r-xxl-2 {
    border-right: 2px solid gray;
  }
  .v-v1 .border-b-xxl-2 {
    border-bottom: 2px solid gray;
  }
  .v-v1 .border-l-xxl-2 {
    border-left: 2px solid gray;
  }
}
.v-v1 .border-3 {
  border: 3px solid gray;
}
.v-v1 .border-t-3 {
  border-top: 3px solid gray;
}
.v-v1 .border-r-3 {
  border-right: 3px solid gray;
}
.v-v1 .border-b-3 {
  border-bottom: 3px solid gray;
}
.v-v1 .border-l-3 {
  border-left: 3px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-xs-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-xs-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-xs-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-xs-3 {
    border-left: 3px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-sm-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-sm-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-sm-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-sm-3 {
    border-left: 3px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-md-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-md-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-md-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-md-3 {
    border-left: 3px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-lg-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-lg-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-lg-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-lg-3 {
    border-left: 3px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-xl-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-xl-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-xl-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-xl-3 {
    border-left: 3px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-3 {
    border: 3px solid gray;
  }
  .v-v1 .border-t-xxl-3 {
    border-top: 3px solid gray;
  }
  .v-v1 .border-r-xxl-3 {
    border-right: 3px solid gray;
  }
  .v-v1 .border-b-xxl-3 {
    border-bottom: 3px solid gray;
  }
  .v-v1 .border-l-xxl-3 {
    border-left: 3px solid gray;
  }
}
.v-v1 .border-4 {
  border: 4px solid gray;
}
.v-v1 .border-t-4 {
  border-top: 4px solid gray;
}
.v-v1 .border-r-4 {
  border-right: 4px solid gray;
}
.v-v1 .border-b-4 {
  border-bottom: 4px solid gray;
}
.v-v1 .border-l-4 {
  border-left: 4px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-xs-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-xs-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-xs-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-xs-4 {
    border-left: 4px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-sm-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-sm-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-sm-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-sm-4 {
    border-left: 4px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-md-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-md-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-md-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-md-4 {
    border-left: 4px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-lg-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-lg-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-lg-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-lg-4 {
    border-left: 4px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-xl-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-xl-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-xl-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-xl-4 {
    border-left: 4px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-4 {
    border: 4px solid gray;
  }
  .v-v1 .border-t-xxl-4 {
    border-top: 4px solid gray;
  }
  .v-v1 .border-r-xxl-4 {
    border-right: 4px solid gray;
  }
  .v-v1 .border-b-xxl-4 {
    border-bottom: 4px solid gray;
  }
  .v-v1 .border-l-xxl-4 {
    border-left: 4px solid gray;
  }
}
.v-v1 .border-5 {
  border: 5px solid gray;
}
.v-v1 .border-t-5 {
  border-top: 5px solid gray;
}
.v-v1 .border-r-5 {
  border-right: 5px solid gray;
}
.v-v1 .border-b-5 {
  border-bottom: 5px solid gray;
}
.v-v1 .border-l-5 {
  border-left: 5px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-xs-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-xs-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-xs-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-xs-5 {
    border-left: 5px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-sm-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-sm-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-sm-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-sm-5 {
    border-left: 5px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-md-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-md-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-md-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-md-5 {
    border-left: 5px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-lg-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-lg-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-lg-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-lg-5 {
    border-left: 5px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-xl-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-xl-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-xl-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-xl-5 {
    border-left: 5px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-5 {
    border: 5px solid gray;
  }
  .v-v1 .border-t-xxl-5 {
    border-top: 5px solid gray;
  }
  .v-v1 .border-r-xxl-5 {
    border-right: 5px solid gray;
  }
  .v-v1 .border-b-xxl-5 {
    border-bottom: 5px solid gray;
  }
  .v-v1 .border-l-xxl-5 {
    border-left: 5px solid gray;
  }
}
.v-v1 .border-6 {
  border: 6px solid gray;
}
.v-v1 .border-t-6 {
  border-top: 6px solid gray;
}
.v-v1 .border-r-6 {
  border-right: 6px solid gray;
}
.v-v1 .border-b-6 {
  border-bottom: 6px solid gray;
}
.v-v1 .border-l-6 {
  border-left: 6px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-xs-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-xs-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-xs-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-xs-6 {
    border-left: 6px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-sm-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-sm-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-sm-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-sm-6 {
    border-left: 6px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-md-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-md-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-md-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-md-6 {
    border-left: 6px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-lg-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-lg-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-lg-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-lg-6 {
    border-left: 6px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-xl-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-xl-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-xl-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-xl-6 {
    border-left: 6px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-6 {
    border: 6px solid gray;
  }
  .v-v1 .border-t-xxl-6 {
    border-top: 6px solid gray;
  }
  .v-v1 .border-r-xxl-6 {
    border-right: 6px solid gray;
  }
  .v-v1 .border-b-xxl-6 {
    border-bottom: 6px solid gray;
  }
  .v-v1 .border-l-xxl-6 {
    border-left: 6px solid gray;
  }
}
.v-v1 .border-7 {
  border: 7px solid gray;
}
.v-v1 .border-t-7 {
  border-top: 7px solid gray;
}
.v-v1 .border-r-7 {
  border-right: 7px solid gray;
}
.v-v1 .border-b-7 {
  border-bottom: 7px solid gray;
}
.v-v1 .border-l-7 {
  border-left: 7px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-xs-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-xs-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-xs-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-xs-7 {
    border-left: 7px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-sm-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-sm-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-sm-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-sm-7 {
    border-left: 7px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-md-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-md-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-md-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-md-7 {
    border-left: 7px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-lg-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-lg-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-lg-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-lg-7 {
    border-left: 7px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-xl-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-xl-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-xl-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-xl-7 {
    border-left: 7px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-7 {
    border: 7px solid gray;
  }
  .v-v1 .border-t-xxl-7 {
    border-top: 7px solid gray;
  }
  .v-v1 .border-r-xxl-7 {
    border-right: 7px solid gray;
  }
  .v-v1 .border-b-xxl-7 {
    border-bottom: 7px solid gray;
  }
  .v-v1 .border-l-xxl-7 {
    border-left: 7px solid gray;
  }
}
.v-v1 .border-8 {
  border: 8px solid gray;
}
.v-v1 .border-t-8 {
  border-top: 8px solid gray;
}
.v-v1 .border-r-8 {
  border-right: 8px solid gray;
}
.v-v1 .border-b-8 {
  border-bottom: 8px solid gray;
}
.v-v1 .border-l-8 {
  border-left: 8px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-xs-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-xs-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-xs-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-xs-8 {
    border-left: 8px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-sm-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-sm-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-sm-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-sm-8 {
    border-left: 8px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-md-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-md-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-md-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-md-8 {
    border-left: 8px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-lg-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-lg-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-lg-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-lg-8 {
    border-left: 8px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-xl-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-xl-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-xl-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-xl-8 {
    border-left: 8px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-8 {
    border: 8px solid gray;
  }
  .v-v1 .border-t-xxl-8 {
    border-top: 8px solid gray;
  }
  .v-v1 .border-r-xxl-8 {
    border-right: 8px solid gray;
  }
  .v-v1 .border-b-xxl-8 {
    border-bottom: 8px solid gray;
  }
  .v-v1 .border-l-xxl-8 {
    border-left: 8px solid gray;
  }
}
.v-v1 .border-9 {
  border: 9px solid gray;
}
.v-v1 .border-t-9 {
  border-top: 9px solid gray;
}
.v-v1 .border-r-9 {
  border-right: 9px solid gray;
}
.v-v1 .border-b-9 {
  border-bottom: 9px solid gray;
}
.v-v1 .border-l-9 {
  border-left: 9px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-xs-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-xs-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-xs-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-xs-9 {
    border-left: 9px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-sm-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-sm-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-sm-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-sm-9 {
    border-left: 9px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-md-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-md-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-md-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-md-9 {
    border-left: 9px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-lg-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-lg-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-lg-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-lg-9 {
    border-left: 9px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-xl-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-xl-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-xl-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-xl-9 {
    border-left: 9px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-9 {
    border: 9px solid gray;
  }
  .v-v1 .border-t-xxl-9 {
    border-top: 9px solid gray;
  }
  .v-v1 .border-r-xxl-9 {
    border-right: 9px solid gray;
  }
  .v-v1 .border-b-xxl-9 {
    border-bottom: 9px solid gray;
  }
  .v-v1 .border-l-xxl-9 {
    border-left: 9px solid gray;
  }
}
.v-v1 .border-10 {
  border: 10px solid gray;
}
.v-v1 .border-t-10 {
  border-top: 10px solid gray;
}
.v-v1 .border-r-10 {
  border-right: 10px solid gray;
}
.v-v1 .border-b-10 {
  border-bottom: 10px solid gray;
}
.v-v1 .border-l-10 {
  border-left: 10px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-xs-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-xs-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-xs-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-xs-10 {
    border-left: 10px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-sm-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-sm-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-sm-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-sm-10 {
    border-left: 10px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-md-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-md-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-md-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-md-10 {
    border-left: 10px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-lg-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-lg-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-lg-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-lg-10 {
    border-left: 10px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-xl-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-xl-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-xl-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-xl-10 {
    border-left: 10px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-10 {
    border: 10px solid gray;
  }
  .v-v1 .border-t-xxl-10 {
    border-top: 10px solid gray;
  }
  .v-v1 .border-r-xxl-10 {
    border-right: 10px solid gray;
  }
  .v-v1 .border-b-xxl-10 {
    border-bottom: 10px solid gray;
  }
  .v-v1 .border-l-xxl-10 {
    border-left: 10px solid gray;
  }
}
.v-v1 .border-11 {
  border: 11px solid gray;
}
.v-v1 .border-t-11 {
  border-top: 11px solid gray;
}
.v-v1 .border-r-11 {
  border-right: 11px solid gray;
}
.v-v1 .border-b-11 {
  border-bottom: 11px solid gray;
}
.v-v1 .border-l-11 {
  border-left: 11px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-xs-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-xs-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-xs-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-xs-11 {
    border-left: 11px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-sm-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-sm-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-sm-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-sm-11 {
    border-left: 11px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-md-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-md-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-md-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-md-11 {
    border-left: 11px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-lg-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-lg-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-lg-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-lg-11 {
    border-left: 11px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-xl-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-xl-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-xl-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-xl-11 {
    border-left: 11px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-11 {
    border: 11px solid gray;
  }
  .v-v1 .border-t-xxl-11 {
    border-top: 11px solid gray;
  }
  .v-v1 .border-r-xxl-11 {
    border-right: 11px solid gray;
  }
  .v-v1 .border-b-xxl-11 {
    border-bottom: 11px solid gray;
  }
  .v-v1 .border-l-xxl-11 {
    border-left: 11px solid gray;
  }
}
.v-v1 .border-12 {
  border: 12px solid gray;
}
.v-v1 .border-t-12 {
  border-top: 12px solid gray;
}
.v-v1 .border-r-12 {
  border-right: 12px solid gray;
}
.v-v1 .border-b-12 {
  border-bottom: 12px solid gray;
}
.v-v1 .border-l-12 {
  border-left: 12px solid gray;
}
@media screen and (min-width: 0) {
  .v-v1 .border-xs-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-xs-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-xs-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-xs-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-xs-12 {
    border-left: 12px solid gray;
  }
}
@media screen and (min-width: 576px) {
  .v-v1 .border-sm-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-sm-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-sm-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-sm-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-sm-12 {
    border-left: 12px solid gray;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .border-md-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-md-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-md-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-md-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-md-12 {
    border-left: 12px solid gray;
  }
}
@media screen and (min-width: 992px) {
  .v-v1 .border-lg-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-lg-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-lg-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-lg-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-lg-12 {
    border-left: 12px solid gray;
  }
}
@media screen and (min-width: 1200px) {
  .v-v1 .border-xl-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-xl-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-xl-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-xl-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-xl-12 {
    border-left: 12px solid gray;
  }
}
@media screen and (min-width: 1400px) {
  .v-v1 .border-xxl-12 {
    border: 12px solid gray;
  }
  .v-v1 .border-t-xxl-12 {
    border-top: 12px solid gray;
  }
  .v-v1 .border-r-xxl-12 {
    border-right: 12px solid gray;
  }
  .v-v1 .border-b-xxl-12 {
    border-bottom: 12px solid gray;
  }
  .v-v1 .border-l-xxl-12 {
    border-left: 12px solid gray;
  }
}
.v-v1 *[data-is-open-button-toggle] {
  cursor: pointer;
  user-select: none;
}
.v-v1 *[data-is-open-button-toggle] .open {
  display: block;
}
.v-v1 *[data-is-open-button-toggle] .close {
  display: none;
}
.v-v1 *[data-is-open-button-toggle] .icon-font {
  transition: all 0.25s ease-in-out;
}
.v-v1 *[data-is-open-button-toggle].is-open .icon-font {
  transform: rotate(180deg);
}
.v-v1 *[data-is-open-button-toggle].is-open .open {
  display: none;
}
.v-v1 *[data-is-open-button-toggle].is-open .close {
  display: block;
}
.medium-zoom-overlay {
  z-index: 1000;
}

.medium-zoom-image--opened {
  z-index: 1001;
}

.v-v1 .carousel-outer-container {
  margin: 0 auto;
  position: relative;
}
.v-v1 .carousel-arrow {
  display: var(--carousel-arrow-display, block);
  position: var(--carousel-arrow-position, absolute);
  cursor: pointer;
  z-index: 10;
}
.v-v1 .carousel-arrow.left {
  transform: var(--carousel-arrow-left-transform-mobile, unset);
  left: var(--carousel-arrow-left-left, 0);
  bottom: var(--carousel-arrow-left-bottom-mobile, 0);
}
.v-v1 .carousel-arrow.right {
  transform: var(--carousel-arrow-right-transform-mobile, unset);
  right: var(--carousel-arrow-right-right, 0);
  bottom: var(--carousel-arrow-right-bottom-mobile, 0);
}
@media screen and (min-width: 1280px) {
  .v-v1 .carousel-arrow.left {
    top: var(--carousel-arrow-left-top, 50%);
    transform: var(--carousel-arrow-left-transform, translate(-50%, -50%));
    left: var(--carousel-arrow-left-left, 0);
    bottom: var(--carousel-arrow-right-bottom, unset);
  }
  .v-v1 .carousel-arrow.right {
    top: var(--carousel-arrow-right-top, 50%);
    transform: var(--carousel-arrow-right-transform, translate(50%, -50%));
    right: var(--carousel-arrow-right-right, 0);
    bottom: var(--carousel-arrow-right-bottom, unset);
  }
}
.v-v1 .carousel-container {
  display: flex;
  align-items: var(--carousel-align, center);
  gap: var(--carousel-gap, 8px);
  max-height: var(--carousel-max-height, 400px);
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  container-type: inline-size;
  container-name: carousel;
}
@media screen and (min-width: 1280px) {
  .v-v1 .carousel-container {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .v-v1 .carousel-container::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
}
.v-v1 .carousel-container-image {
  display: block;
  object-fit: contain;
  width: auto;
  max-width: var(--carousel-img-max-width, 100%);
  height: 100%;
  max-height: var(--carousel-max-height, 400px);
  scroll-snap-align: var(--carousel-img-snap-align, start);
  scroll-snap-stop: var(--carousel-img-snap-stop, always);
}
@container carousel (inline-size > 768px) {
  .v-v1 .carousel-container-image {
    max-width: var(--carousel-img-max-width-container, 80cqw) !important;
  }
}
.v-v1 *[hidden] {
  display: none !important;
}
.v-v1 .mdd-colored-box {
  padding: var(--mdd-colored-box-padding, 8px);
  background-color: var(--mdd-colored-box-background, magenta) !important;
  color: var(--mdd-colored-box-color, white) !important;
}
.v-v1 .mdd-placeholder,
.v-v1 .mdd-placeholder * {
  color: var(--mdd-placeholder-color, magenta) !important;
}
:root {
  --heading-1: #333333;
  --heading-2: #333333;
  --heading-3: #086adb;
  --heading-4: #086adb;
  --heading-5: #333333;
  --heading-6: #333333;
  --p-lead: #333333;
  --p-title: #333333;
  --p: #333333;
  --caption: #333333;
  --glossary: #333333;
  --modal-overlay-backdrop: rgba(0, 0, 0, 0.3);
  --modal-background: #ffffff;
  --modal-close-icon: #333333;
  --navigation-arrow: #333333;
  --navigation-background: #ffffff;
  --navigation-default-border-bottom: transparent;
  --navigation-color: #333333;
  --navigation-hover-color: #333333;
  --navigation-hover-background: #f8fafb;
  --navigation-selected-color: #333333;
  --navigation-selected-background: #eef3f6;
  --navigation-expanded-color: #333333;
  --navigation-expanded-background: #ffffff;
  --tab-selected: #ffffff;
  --tab-deselected: #f8fafb;
  --tab-icon: #015;
  --tab-active-border-bottom: #ffffff;
  --language-overlay-background: rgba(0, 0, 0, 0.3);
  --language-overlay-link: #015;
  --language-overlay-link-selected: #ffffff;
  --language-overlay-link-hover-background: #1781e3;
  --link-internal: #086adb;
  --link-external: #000000;
  --text-selection: #bde0f2;
  --text-selection-color: #000000;
  --primary: #333333;
  --secondary: #333333;
  --white: #ffffff;
  --black: #000000;
  --sidebar-tabcontrol-background: #ffffff;
  --sidebar-tabcontrol-header-background: #ffffff;
  --sidebar-tabcontrol-header-border-bottom: transparent;
  --sidebar-tabcontrol-content-background: #ffffff;
  --sidebar-tabcontrol-tab-border: transparent;
  --sidebar-tabcontrol-tab-border-bottom: transparent;
  --sidebar-tabcontrol-tab-background: #f8fafb;
  --sidebar-tabcontrol-tab-icon-color: #333333;
  --sidebar-tabcontrol-tab-title-color: #333333;
  --sidebar-tabcontrol-tab-active-border: #ffffff;
  --sidebar-tabcontrol-tab-active-border-bottom: transparent;
  --sidebar-tabcontrol-tab-active-background: #ffffff;
  --sidebar-tabcontrol-tab-active-title-color: #000000;
  --sidebar-tabcontrol-tab-active-icon-color: #000000;
  --order-form: #333333;
  --order-form-link: #333333;
  --order-form-label: #333333;
  --order-form-input-border: #333333;
  --order-form-input-background: #333333;
  --order-form-input: #333333;
  --order-form-input-active-border: #333333;
  --order-form-submit-background: #333333;
  --order-form-submit: #ffffff;
  --order-form-submit-hover-background: rgba(0, 0, 0, 0.3);
  --order-form-error-label: #333333;
  --order-form-error-input-background: #333333;
  --order-form-error-input-border: #333333;
  --order-form-error-message: #333333;
  --share-button-color: #333333;
  --share-button-hover-color: #333333;
  --share-border: #1781e3;
  --share-border-top: #1781e3;
  --share-background: #f0f0f0;
  --share-font-color: #333333;
  --share-icon: #1781e3;
  --share-popover-border: transparent;
  --share-icon-icon-hover: #ffffff;
  --share-icon-icon-hover-background: #1781e3;
  --share-icon-icon-hover-border: transparent;
  --share-icon-icon-active-border: #ffffff;
  --report-switcher-li: #333333;
  --report-switcher-badge: #333333;
  --report-switcher-badge-background: #ffffff;
  --report-switcher-collapsable-border-bottom: #333333;
  --report-switcher-list-item-color: #333333;
  --report-switcher-input-background: #ffffff;
  --report-switcher-input-border: #e4e9ec;
  --report-switcher-input: #333333;
  --report-switcher-popup-background: #ffffff;
  --report-switcher-popup-close: #1781e3;
  --report-switcher-popup-list-li: #333333;
  --report-switcher-popup-list-li-border-bottom: #1781e3;
  --report-switcher-popup-list-li-hover: #333333;
  --report-switcher-popup-list-li-hover-background: #d1e6f9;
  --report-switcher-popup-list-li-a: #333333;
  --report-switcher-popup-list-language: #333333;
  --report-switcher-popup-collapsable-title-border-bottom: #333333;
  --report-switcher-popup-collapsable-icon-font: #333333;
  --report-switcher-popup-collapsable-li-border-bottom: #333333;
  --report-switcher-popup-collapsable-li-color: #333333;
  --search-input-background: #ffffff;
  --search-border: transparent;
  --search-focus-border: #000000;
  --search-icon-color: #ffffff;
  --search-title-color: #333333;
  --search-message-color: #333333;
  --search-input-background-button: #1781e3;
  --search-input-background-button-hover: #1781e3;
  --search-highlight-background: #d1e6f9;
  --search-highlight: #333333;
  --search-border-bottom: transparent;
  --search-border-right: transparent;
  --search-result-border-bottom: #f8fafb;
  --search-result-hover-background: #eef3f6;
  --sidebar-background: #ffffff;
  --sidebar-border-right: transparent;
  --spinner-background-1: #1781e3;
  --spinner-background-2: #ffffff;
  --spinner-after-background: #ffffff;
  --table-highlight-border-top: transparent;
  --table-linedark-border: #999999;
  --table-linemedium-border: #999999;
  --table-linelight-border: #bbbbbb;
  --table-linethin-border: #6c6c6c;
  --table-linetitle-border: #000000;
  --table-linefootnote-border: transparent;
  --table-stackable-thtd-color: #000000;
  --table-color-class: #000000;
  --table-desclight-color: #6c6c6c;
  --table-number-color: #000000;
  --table-highlight-color: #000000;
  --table-highlight-background: #eef3f6;
  --table-highlight-border: #eef3f6;
  --table-row-hover-background: #eef3f6;
  --table-headerrow: #333333;
  --table-description: #333333;
  --table-entry-bold: #333333;
  --table-entry: #333333;
  --header-border-bottom: #dde3e7;
  --header-background: #ffffff;
  --header-button: #000000;
  --header-button-background-menu-and-close: #ffffff;
  --header-button-active-background-menu-and-close: #eef3f6;
  --header-button-menu-and-close: #000000;
  --header-button-menu-icon: #000000;
  --header-button-language-downloads-hover: #ffffff;
  --header-button-active-language-downloads-hover: #ffffff;
  --header-button-language-icon: #000000;
  --header-button-close-icon: #000000;
  --header-button-downloads-icon: #000000;
  --reader-tabs-tab-background: #f8fafb;
  --reader-tabs-tab-background-hover: #ffffff;
  --reader-tabs-tab-background-selected: #ffffff;
  --reader-tabs-border-color: #dde3e7;
  --reader-tabs-border-bottom-color: #dde3e7;
  --reader-tabs-selected-border: #dde3e7;
  --reader-tabs-selected-border-bottom-color: #ffffff;
  --reader-tabs-tab-color: #015;
  --reader-tabs-tab-selected-color: #015;
  --reader-tabs-add-new-color: #086adb;
  --reader-tabs-add-new-background: transparent;
  --reader-tabs-add-new-border: transparent;
  --dropdown-background: #ffffff;
  --dropdown-border: #d6d6d6;
  --dropdown-link: #001155;
  --dropdown-link--selected: #086adb;
  --dropdown-hover-link-background: #f8fafb;
}

.v-v1 .swisscom-hint--special,
.v-v1 .hint--special {
  display: block;
  height: auto;
}
.v-v1 .swisscom-hint--special > div:first-child,
.v-v1 .hint--special > div:first-child {
  margin-bottom: 25px;
}
.v-v1 .swisscom-hint--special > div:nth-child(2),
.v-v1 .hint--special > div:nth-child(2) {
  display: inline-block;
}
.v-v1 .swisscom-hint--special > div:last-child,
.v-v1 .hint--special > div:last-child {
  display: block;
}
.v-v1 .swisscom-hint--special > div:last-child .swisscom-box-digit .box-digit > .digit-value:first-child, .v-v1 .swisscom-hint--special > div:last-child .swisscom-box-digit .swisscom-box-digit > .digit-value:first-child,
.v-v1 .swisscom-hint--special > div:last-child .swisscom-box-digit .swisscom-box-digit-null > .digit-value:first-child,
.v-v1 .hint--special > div:last-child .swisscom-box-digit .box-digit > .digit-value:first-child,
.v-v1 .hint--special > div:last-child .swisscom-box-digit .swisscom-box-digit > .digit-value:first-child,
.v-v1 .hint--special > div:last-child .swisscom-box-digit .swisscom-box-digit-null > .digit-value:first-child {
  margin-left: 0;
}
.v-v1 .swisscom-image {
  margin: 0 0 40px 0;
}
.v-v1 .row__block img {
  margin-bottom: 0;
}
.v-v1 .swisscom-lead {
  color: #001155;
  border-left: 1px solid #001155;
}
.v-v1 .esg-body-lead:has(+ :not(.esg-body-lead)) {
  margin: 0 0 40px 0 !important;
}
@media (min-width: 768px) {
  .v-v1 .esg-body-lead:has(+ :not(.esg-body-lead)) {
    margin: 0 0 40px 0 !important;
  }
}
.v-v1 ul + .swisscom-marginal {
  margin-top: -22px;
}
.v-v1 .swisscom-marginal + .swisscom-marginal {
  margin-top: -16px;
}
.v-v1 .swisscom-marginal {
  margin-bottom: 30px;
  display: inline-block;
  height: auto;
}
.v-v1 .swisscom-marginal .text,
.v-v1 .swisscom-marginal .external-link {
  font-weight: normal;
  color: #086adb !important;
  line-height: 24px;
}
@media screen and (max-width: 768px) {
  .v-v1 .swisscom-marginal .text,
  .v-v1 .swisscom-marginal .external-link {
    font-size: 16px;
  }
}
.v-v1 .swisscom-marginal .link a,
.v-v1 .swisscom-marginal .link *[data-shortlinkurl],
.v-v1 .swisscom-marginal .link b,
.v-v1 .swisscom-marginal .external-link,
.v-v1 .swisscom-marginal *[data-internallink],
.v-v1 .swisscom-marginal *[data-shortlinkid] {
  text-decoration: none;
  color: #086adb !important;
  cursor: pointer;
  font-weight: bold !important;
  border-bottom: 0 !important;
}
.v-v1 .swisscom-marginal .link a:visited,
.v-v1 .swisscom-marginal .link [data-shortlinkurl]:visited,
.v-v1 .swisscom-marginal .link b:visited,
.v-v1 .swisscom-marginal .external-link:visited,
.v-v1 .swisscom-marginal *[data-internallink]:visited,
.v-v1 .swisscom-marginal *[data-shortlinkid]:visited {
  color: #086adb !important;
}
.v-v1 .swisscom-marginal .link a:hover,
.v-v1 .swisscom-marginal .link [data-shortlinkurl]:hover,
.v-v1 .swisscom-marginal .link b:hover,
.v-v1 .swisscom-marginal .external-link:hover,
.v-v1 .swisscom-marginal *[data-internallink]:hover,
.v-v1 .swisscom-marginal *[data-shortlinkid]:hover {
  text-decoration: underline !important;
}
.v-v1 .swisscom-marginal .link b {
  cursor: unset;
}
.v-v1 .swisscom-marginal .link b:hover {
  text-decoration: none !important;
}
.v-v1 .swisscom-marginal *[data-shortlinkid] {
  font-weight: 600 !important;
}
.v-v1 .swisscom-marginal.swisscom-marginal--internal, .v-v1 .swisscom-marginal.swisscom-marginal--external {
  padding-left: 40px;
}
.v-v1 .swisscom-marginal.swisscom-marginal--internal {
  background: url("../images/document-icon-2x.png") no-repeat left center;
  background-size: 24px 24px;
}
.v-v1 .swisscom-marginal.swisscom-marginal--external {
  background: url("../images/globe-icon-2x.png") no-repeat left center;
  background-size: 24px 24px;
}
.v-v1 .swisscom-body--followed-by-marginal {
  margin-bottom: 15px !important;
}
.v-v1 .reader-person, .v-v1 .swisscom-person {
  margin: 0;
}
.v-v1 .reader-person img, .v-v1 .swisscom-person img {
  margin: 30px 0 10px 0;
}
.v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    margin-left: calc(
            32px +
              56 *
              ((100vw - 768px) / (1152))
          );
    margin-right: calc(
            32px +
              56 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    margin-left: 88px;
    margin-right: 88px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    margin-top: calc(
            32px +
              32 *
              ((100vw - 768px) / (1152))
          );
    margin-bottom: calc(
            32px +
              32 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    margin-top: 64;
    margin-bottom: 64;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    font-size: calc(
            24px +
              24 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .container > p.swisscom-AB-zitat, .v-v1 .order-form .content > p.swisscom-AB-zitat {
    font-size: 48px;
  }
}
.v-v1 .reader__body h1, .reader__body .v-v1 h1, .reader__body .v-v1 .h1, .v-v1 .reader__body .h1,
.v-v1 .heading1 {
  font-size: 18px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.33em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h1, .reader__body .v-v1 h1, .reader__body .v-v1 .h1, .v-v1 .reader__body .h1,
  .v-v1 .heading1 {
    font-size: calc(
            18px +
              6 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h1, .reader__body .v-v1 h1, .reader__body .v-v1 .h1, .v-v1 .reader__body .h1,
  .v-v1 .heading1 {
    font-size: 24px;
  }
}
.v-v1 .reader__body h2, .reader__body .v-v1 h2, .reader__body .v-v1 .h2, .v-v1 .reader__body .h2,
.v-v1 .heading2 {
  font-size: 32px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.19em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h2, .reader__body .v-v1 h2, .reader__body .v-v1 .h2, .v-v1 .reader__body .h2,
  .v-v1 .heading2 {
    font-size: calc(
            32px +
              22 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h2, .reader__body .v-v1 h2, .reader__body .v-v1 .h2, .v-v1 .reader__body .h2,
  .v-v1 .heading2 {
    font-size: 54px;
  }
}
.v-v1 .reader__body h3, .v-v1 .reader__body .swisscom-about-box-title-1zeile,
.v-v1 .reader__body .swisscom-about-box-title-2zeilen, .v-v1 .reader__body .swisscom-about-value-navy-36pt,
.v-v1 .reader__body .swisscom-about-value-navy-30pt,
.v-v1 .reader__body .swisscom-about-value-grey-36pt,
.v-v1 .reader__body .swisscom-about-value-grey-30pt,
.v-v1 .reader__body .swisscom-about-value-grey-29pt,
.v-v1 .reader__body .swisscom-about-value-navy-25pt,
.v-v1 .reader__body .swisscom-about-value-grey-25pt,
.v-v1 .reader__body .swisscom-about-value-navy-21pt,
.v-v1 .reader__body .swisscom-about-value-navy-30pt_small,
.v-v1 .reader__body .swisscom-about-value, .reader__body .v-v1 h3, .reader__body .v-v1 .swisscom-about-box-title-1zeile,
.reader__body .v-v1 .swisscom-about-box-title-2zeilen, .reader__body .v-v1 .swisscom-about-value-navy-36pt,
.reader__body .v-v1 .swisscom-about-value-navy-30pt,
.reader__body .v-v1 .swisscom-about-value-grey-36pt,
.reader__body .v-v1 .swisscom-about-value-grey-30pt,
.reader__body .v-v1 .swisscom-about-value-grey-29pt,
.reader__body .v-v1 .swisscom-about-value-navy-25pt,
.reader__body .v-v1 .swisscom-about-value-grey-25pt,
.reader__body .v-v1 .swisscom-about-value-navy-21pt,
.reader__body .v-v1 .swisscom-about-value-navy-30pt_small,
.reader__body .v-v1 .swisscom-about-value, .reader__body .v-v1 .h3, .v-v1 .reader__body .h3,
.v-v1 .heading3 {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2em;
  color: #086adb;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h3, .v-v1 .reader__body .swisscom-about-box-title-1zeile,
  .v-v1 .reader__body .swisscom-about-box-title-2zeilen, .v-v1 .reader__body .swisscom-about-value-navy-36pt,
  .v-v1 .reader__body .swisscom-about-value-navy-30pt,
  .v-v1 .reader__body .swisscom-about-value-grey-36pt,
  .v-v1 .reader__body .swisscom-about-value-grey-30pt,
  .v-v1 .reader__body .swisscom-about-value-grey-29pt,
  .v-v1 .reader__body .swisscom-about-value-navy-25pt,
  .v-v1 .reader__body .swisscom-about-value-grey-25pt,
  .v-v1 .reader__body .swisscom-about-value-navy-21pt,
  .v-v1 .reader__body .swisscom-about-value-navy-30pt_small,
  .v-v1 .reader__body .swisscom-about-value, .reader__body .v-v1 h3, .reader__body .v-v1 .swisscom-about-box-title-1zeile,
  .reader__body .v-v1 .swisscom-about-box-title-2zeilen, .reader__body .v-v1 .swisscom-about-value-navy-36pt,
  .reader__body .v-v1 .swisscom-about-value-navy-30pt,
  .reader__body .v-v1 .swisscom-about-value-grey-36pt,
  .reader__body .v-v1 .swisscom-about-value-grey-30pt,
  .reader__body .v-v1 .swisscom-about-value-grey-29pt,
  .reader__body .v-v1 .swisscom-about-value-navy-25pt,
  .reader__body .v-v1 .swisscom-about-value-grey-25pt,
  .reader__body .v-v1 .swisscom-about-value-navy-21pt,
  .reader__body .v-v1 .swisscom-about-value-navy-30pt_small,
  .reader__body .v-v1 .swisscom-about-value, .reader__body .v-v1 .h3, .v-v1 .reader__body .h3,
  .v-v1 .heading3 {
    font-size: calc(
            16px +
              12 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h3, .v-v1 .reader__body .swisscom-about-box-title-1zeile,
  .v-v1 .reader__body .swisscom-about-box-title-2zeilen, .v-v1 .reader__body .swisscom-about-value-navy-36pt,
  .v-v1 .reader__body .swisscom-about-value-navy-30pt,
  .v-v1 .reader__body .swisscom-about-value-grey-36pt,
  .v-v1 .reader__body .swisscom-about-value-grey-30pt,
  .v-v1 .reader__body .swisscom-about-value-grey-29pt,
  .v-v1 .reader__body .swisscom-about-value-navy-25pt,
  .v-v1 .reader__body .swisscom-about-value-grey-25pt,
  .v-v1 .reader__body .swisscom-about-value-navy-21pt,
  .v-v1 .reader__body .swisscom-about-value-navy-30pt_small,
  .v-v1 .reader__body .swisscom-about-value, .reader__body .v-v1 h3, .reader__body .v-v1 .swisscom-about-box-title-1zeile,
  .reader__body .v-v1 .swisscom-about-box-title-2zeilen, .reader__body .v-v1 .swisscom-about-value-navy-36pt,
  .reader__body .v-v1 .swisscom-about-value-navy-30pt,
  .reader__body .v-v1 .swisscom-about-value-grey-36pt,
  .reader__body .v-v1 .swisscom-about-value-grey-30pt,
  .reader__body .v-v1 .swisscom-about-value-grey-29pt,
  .reader__body .v-v1 .swisscom-about-value-navy-25pt,
  .reader__body .v-v1 .swisscom-about-value-grey-25pt,
  .reader__body .v-v1 .swisscom-about-value-navy-21pt,
  .reader__body .v-v1 .swisscom-about-value-navy-30pt_small,
  .reader__body .v-v1 .swisscom-about-value, .reader__body .v-v1 .h3, .v-v1 .reader__body .h3,
  .v-v1 .heading3 {
    font-size: 28px;
  }
}
.v-v1 .reader__body h4, .reader__body .v-v1 h4, .reader__body .v-v1 .h4, .v-v1 .reader__body .h4,
.v-v1 .heading4 {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: #086adb;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h4, .reader__body .v-v1 h4, .reader__body .v-v1 .h4, .v-v1 .reader__body .h4,
  .v-v1 .heading4 {
    font-size: calc(
            16px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h4, .reader__body .v-v1 h4, .reader__body .v-v1 .h4, .v-v1 .reader__body .h4,
  .v-v1 .heading4 {
    font-size: 24px;
  }
}
.v-v1 .reader__body h5, .v-v1 .reader__body .swisscom-about-box-subtitle, .v-v1 .reader__body .swisscom-corporate-governance-name, .reader__body .v-v1 h5, .reader__body .v-v1 .swisscom-about-box-subtitle, .reader__body .v-v1 .swisscom-corporate-governance-name, .reader__body .v-v1 .h5, .v-v1 .reader__body .h5,
.v-v1 .heading5 {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h5, .v-v1 .reader__body .swisscom-about-box-subtitle, .v-v1 .reader__body .swisscom-corporate-governance-name, .reader__body .v-v1 h5, .reader__body .v-v1 .swisscom-about-box-subtitle, .reader__body .v-v1 .swisscom-corporate-governance-name, .reader__body .v-v1 .h5, .v-v1 .reader__body .h5,
  .v-v1 .heading5 {
    font-size: calc(
            16px +
              5 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h5, .v-v1 .reader__body .swisscom-about-box-subtitle, .v-v1 .reader__body .swisscom-corporate-governance-name, .reader__body .v-v1 h5, .reader__body .v-v1 .swisscom-about-box-subtitle, .reader__body .v-v1 .swisscom-corporate-governance-name, .reader__body .v-v1 .h5, .v-v1 .reader__body .h5,
  .v-v1 .heading5 {
    font-size: 21px;
  }
}
.v-v1 .reader__body h6, .reader__body .v-v1 h6, .reader__body .v-v1 .h6, .v-v1 .reader__body .h6,
.v-v1 .heading6 {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body h6, .reader__body .v-v1 h6, .reader__body .v-v1 .h6, .v-v1 .reader__body .h6,
  .v-v1 .heading6 {
    font-size: calc(
            16px +
              5 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body h6, .reader__body .v-v1 h6, .reader__body .v-v1 .h6, .v-v1 .reader__body .h6,
  .v-v1 .heading6 {
    font-size: 21px;
  }
}
.v-v1 .reader__body span, .reader__body .v-v1 span, .v-v1 .reader__body ul li, .reader__body ul .v-v1 li,
.v-v1 .reader__body dl,
.reader__body .v-v1 dl,
.v-v1 .reader__body dd,
.reader__body .v-v1 dd, .v-v1 .reader__body p, .reader__body .v-v1 p, .v-v1 .reader__body .body, .reader__body .v-v1 .body,
.v-v1 .paragraph {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.39em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body span, .reader__body .v-v1 span, .v-v1 .reader__body ul li, .reader__body ul .v-v1 li,
  .v-v1 .reader__body dl,
  .reader__body .v-v1 dl,
  .v-v1 .reader__body dd,
  .reader__body .v-v1 dd, .v-v1 .reader__body p, .reader__body .v-v1 p, .v-v1 .reader__body .body, .reader__body .v-v1 .body,
  .v-v1 .paragraph {
    font-size: calc(
            16px +
              2 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body span, .reader__body .v-v1 span, .v-v1 .reader__body ul li, .reader__body ul .v-v1 li,
  .v-v1 .reader__body dl,
  .reader__body .v-v1 dl,
  .v-v1 .reader__body dd,
  .reader__body .v-v1 dd, .v-v1 .reader__body p, .reader__body .v-v1 p, .v-v1 .reader__body .body, .reader__body .v-v1 .body,
  .v-v1 .paragraph {
    font-size: 18px;
  }
}
.v-v1 .reader__body p.lead, .reader__body .v-v1 p.lead,
.v-v1 .lead {
  font-size: 16px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.25em;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__body p.lead, .reader__body .v-v1 p.lead,
  .v-v1 .lead {
    font-size: calc(
            16px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__body p.lead, .reader__body .v-v1 p.lead,
  .v-v1 .lead {
    font-size: 24px;
  }
}
.v-v1 .link {
  font-size: 15px;
  font-family: "TheSans", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.48em;
  color: #086adb;
}
@media screen and (min-width: 768px) {
  .v-v1 .link {
    font-size: calc(
            15px +
              6 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .link {
    font-size: 21px;
  }
}
.v-v1 .bold,
.v-v1 b,
.v-v1 bolder {
  font-weight: bold !important;
}
.v-v1 a, .v-v1 *[data-shortlinkid],
.v-v1 *[data-shortlinkurl] {
  font-family: "TheSans", Arial, sans-serif;
}
.reader__body {
  font-family: "TheSans", Arial, sans-serif;
}
.reader__body h1, .reader__body .v-v1 .h1, .v-v1 .reader__body .h1 {
  letter-spacing: -0.3px;
}
.reader__body h2, .reader__body .v-v1 .h2, .v-v1 .reader__body .h2 {
  letter-spacing: -1.4px;
}
.reader__body h3, .reader__body .v-v1 .swisscom-about-box-title-1zeile, .v-v1 .reader__body .swisscom-about-box-title-1zeile,
.reader__body .v-v1 .swisscom-about-box-title-2zeilen,
.v-v1 .reader__body .swisscom-about-box-title-2zeilen, .reader__body .v-v1 .swisscom-about-value-navy-36pt, .v-v1 .reader__body .swisscom-about-value-navy-36pt,
.reader__body .v-v1 .swisscom-about-value-navy-30pt,
.v-v1 .reader__body .swisscom-about-value-navy-30pt,
.reader__body .v-v1 .swisscom-about-value-grey-36pt,
.v-v1 .reader__body .swisscom-about-value-grey-36pt,
.reader__body .v-v1 .swisscom-about-value-grey-30pt,
.v-v1 .reader__body .swisscom-about-value-grey-30pt,
.reader__body .v-v1 .swisscom-about-value-grey-29pt,
.v-v1 .reader__body .swisscom-about-value-grey-29pt,
.reader__body .v-v1 .swisscom-about-value-navy-25pt,
.v-v1 .reader__body .swisscom-about-value-navy-25pt,
.reader__body .v-v1 .swisscom-about-value-grey-25pt,
.v-v1 .reader__body .swisscom-about-value-grey-25pt,
.reader__body .v-v1 .swisscom-about-value-navy-21pt,
.v-v1 .reader__body .swisscom-about-value-navy-21pt,
.reader__body .v-v1 .swisscom-about-value-navy-30pt_small,
.v-v1 .reader__body .swisscom-about-value-navy-30pt_small,
.reader__body .v-v1 .swisscom-about-value,
.v-v1 .reader__body .swisscom-about-value, .reader__body .v-v1 .h3, .v-v1 .reader__body .h3 {
  letter-spacing: -0.2px;
}
.reader__body h4, .reader__body .v-v1 .h4, .v-v1 .reader__body .h4 {
  margin-bottom: 16px !important;
}
.reader__body h5, .reader__body .v-v1 .swisscom-about-box-subtitle, .v-v1 .reader__body .swisscom-about-box-subtitle, .reader__body .v-v1 .swisscom-corporate-governance-name, .v-v1 .reader__body .swisscom-corporate-governance-name, .reader__body .v-v1 .h5, .v-v1 .reader__body .h5 {
  margin-bottom: 16px !important;
}
.reader__body h6, .reader__body .v-v1 .h6, .v-v1 .reader__body .h6 {
  margin-bottom: 16px !important;
}
.reader__body .body {
  margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .reader__body .body {
    margin-bottom: calc(
            24px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .reader__body .body {
    margin-bottom: 32px;
  }
}
.reader__body p.lead,
.reader__body .lead {
  letter-spacing: -0.2px;
  margin-left: 0;
  padding-left: 0;
  border-left: 0;
}
.reader__body span {
  color: inherit !important;
  font-weight: inherit !important;
}
.reader__body h4 + h5, .reader__body .v-v1 h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 .h4 + h5, .reader__body .v-v1 .h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 .h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body .h4 + h5, .v-v1 .reader__body .h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body .h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 h4 + .h5, .v-v1 .reader__body h4 + .h5, .reader__body .v-v1 .h4 + .h5, .v-v1 .reader__body .h4 + .h5 {
  margin-top: 16px !important;
}
@media only screen and (min-width: 768px) {
  .reader__body h4 + h5, .reader__body .v-v1 h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 .h4 + h5, .reader__body .v-v1 .h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 .h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body .h4 + h5, .v-v1 .reader__body .h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body .h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 h4 + .h5, .v-v1 .reader__body h4 + .h5, .reader__body .v-v1 .h4 + .h5, .v-v1 .reader__body .h4 + .h5 {
    margin-top: 24px !important;
  }
}
@media only screen and (min-width: 1280px) {
  .reader__body h4 + h5, .reader__body .v-v1 h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 .h4 + h5, .reader__body .v-v1 .h4 + .swisscom-about-box-subtitle, .reader__body .v-v1 .h4 + .swisscom-corporate-governance-name, .v-v1 .reader__body .h4 + h5, .v-v1 .reader__body .h4 + .swisscom-about-box-subtitle, .v-v1 .reader__body .h4 + .swisscom-corporate-governance-name, .reader__body .v-v1 h4 + .h5, .v-v1 .reader__body h4 + .h5, .reader__body .v-v1 .h4 + .h5, .v-v1 .reader__body .h4 + .h5 {
    margin-top: 32px !important;
  }
}

.v-v1 h3.webui-popover-title, .v-v1 .webui-popover-title.swisscom-about-box-title-1zeile,
.v-v1 .webui-popover-title.swisscom-about-box-title-2zeilen, .v-v1 .webui-popover-title.swisscom-about-value-navy-36pt,
.v-v1 .webui-popover-title.swisscom-about-value-navy-30pt,
.v-v1 .webui-popover-title.swisscom-about-value-grey-36pt,
.v-v1 .webui-popover-title.swisscom-about-value-grey-30pt,
.v-v1 .webui-popover-title.swisscom-about-value-grey-29pt,
.v-v1 .webui-popover-title.swisscom-about-value-navy-25pt,
.v-v1 .webui-popover-title.swisscom-about-value-grey-25pt,
.v-v1 .webui-popover-title.swisscom-about-value-navy-21pt,
.v-v1 .webui-popover-title.swisscom-about-value-navy-30pt_small,
.v-v1 .webui-popover-title.swisscom-about-value, .v-v1 .webui-popover-title.h3 {
  padding: 0px 14px !important;
  font-size: 1rem !important;
  color: #333333 !important;
  border: 0;
}
.v-v1 .webui-popover {
  padding: 0.8rem 1.2rem 1.5rem 0.2rem;
  border-width: 2px 0 0 0 !important;
  border-style: solid;
  border-color: #1781e3;
  box-shadow: none;
}
.v-v1 .webui-popover .social-media-sharing {
  border-radius: 3px !important;
  border: 2px solid #1781e3 !important;
  color: #1781e3 !important;
}
.v-v1 .webui-popover .social-media-sharing:hover {
  background: #1781e3 !important;
  border: 2px solid #1781e3 !important;
}
.v-v1 .webui-popover .social-media-sharing:hover i {
  color: #ffffff !important;
}
.v-v1 .essential-topics-grid {
  width: 100%;
  margin: 48px 0;
  display: grid;
  grid-template-columns: 40px 12px 1fr 8px 1fr;
  grid-template-rows: 1px 1fr 8px 1fr 12px 40px auto auto auto auto auto;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid {
    grid-template-rows: 1px 1fr 8px 1fr 12px 40px auto;
  }
}
.v-v1 .essential-topics-grid ul {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 0 !important;
  padding-left: 0;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid ul {
    margin-left: 16px;
  }
}
.v-v1 .essential-topics-grid ul,
.v-v1 .essential-topics-grid ul li {
  list-style-position: outside !important;
  list-style-type: disc !important;
  padding-left: 0;
  background: none !important;
}
.v-v1 .essential-topics-grid ul a, .v-v1 .essential-topics-grid ul *[data-shortlinkid],
.v-v1 .essential-topics-grid ul *[data-shortlinkurl],
.v-v1 .essential-topics-grid ul a:visited,
.v-v1 .essential-topics-grid ul a:active,
.v-v1 .essential-topics-grid ul a:hover {
  color: #1781e3 !important;
}
.v-v1 .essential-topics-grid-label-left, .v-v1 .essential-topics-grid-label-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.v-v1 .essential-topics-grid-label-left {
  grid-row: 1/7;
  grid-column: 1/2;
  transform-origin: center;
  transform: rotate(-90deg);
  line-height: 0.8;
  width: 188px;
  padding-bottom: 160px;
  padding-left: 39px;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-label-left {
    width: auto;
    padding-bottom: 0;
    padding-left: 0;
    white-space: nowrap;
  }
}
.v-v1 .essential-topics-grid-label-bottom {
  grid-row: 6/7;
  grid-column: 1/6;
}
.v-v1 .essential-topics-grid-left-top-color, .v-v1 .essential-topics-grid-right-bottom-color {
  background-color: rgb(14, 171, 169);
}
.v-v1 .essential-topics-grid-left-top-color {
  grid-row: 1/3;
  grid-column: 2/3;
}
.v-v1 .essential-topics-grid-right-bottom-color {
  grid-row: 5/6;
  grid-column: 5/6;
}
.v-v1 .essential-topics-grid-left-bottom-color, .v-v1 .essential-topics-grid-bottom-left-color {
  background-color: rgb(166, 55, 151);
}
.v-v1 .essential-topics-grid-left-bottom-color {
  grid-row: 4/5;
  grid-column: 2/3;
}
.v-v1 .essential-topics-grid-bottom-left-color {
  grid-row: 5/6;
  grid-column: 2/4;
}
.v-v1 .essential-topics-grid-vertical-spacing, .v-v1 .essential-topics-grid-horizontal-center-spacing, .v-v1 .essential-topics-grid-horizontal-top-spacing {
  background-color: transparent;
}
.v-v1 .essential-topics-grid-vertical-spacing {
  grid-row: 1/5;
  grid-column: 4/5;
  margin: 8px 0;
}
.v-v1 .essential-topics-grid-horizontal-center-spacing {
  grid-row: 3/4;
  grid-column: 3/6;
  margin: 0 8px;
}
.v-v1 .essential-topics-grid-horizontal-top-spacing {
  grid-row: 1/2;
  grid-column: 3/6;
  margin: 0 8px;
}
.v-v1 .essential-topics-grid-circle {
  padding: 48px 40px;
  grid-column: 1/2;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-circle {
    display: none;
  }
}
.v-v1 .essential-topics-grid-circle-top-left {
  grid-row: 8/9;
  margin-top: 43px;
  padding: 0;
}
.v-v1 .essential-topics-grid-circle-top-left::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #0eaba9;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-circle-top-left::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-circle-top-left::before {
  margin-left: 0;
}
.v-v1 .essential-topics-grid-circle-top-right {
  grid-row: 9/10;
  margin-top: 3px;
  padding: 0;
}
.v-v1 .essential-topics-grid-circle-top-right::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #0c847e;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-circle-top-right::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-circle-top-right::before {
  margin-left: 0;
}
.v-v1 .essential-topics-grid-circle-bottom-left {
  grid-row: 10/11;
  margin-top: 3px;
  padding: 0;
}
.v-v1 .essential-topics-grid-circle-bottom-left::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #5944c6;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-circle-bottom-left::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-circle-bottom-left::before {
  margin-left: 0;
}
.v-v1 .essential-topics-grid-circle-bottom-right {
  grid-row: 11/12;
  margin-top: 3px;
  padding: 0;
}
.v-v1 .essential-topics-grid-circle-bottom-right::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #42389e;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-circle-bottom-right::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-circle-bottom-right::before {
  margin-left: 0;
}
.v-v1 .essential-topics-grid-top-left-circle {
  grid-column: 3/4;
  grid-row: 2/3;
  background-color: rgba(14, 171, 169, 0.05);
}
.v-v1 .essential-topics-grid-top-left-circle::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #0eaba9;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-top-left-circle::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-top-right-circle {
  grid-column: 5/6;
  grid-row: 2/3;
  background-color: rgba(14, 171, 169, 0.05);
}
.v-v1 .essential-topics-grid-top-right-circle::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #0c847e;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-top-right-circle::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-bottom-left-circle {
  grid-column: 3/4;
  grid-row: 4/5;
  background-color: rgba(166, 55, 151, 0.05);
}
.v-v1 .essential-topics-grid-bottom-left-circle::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #5944c6;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-bottom-left-circle::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-bottom-right-circle {
  grid-column: 5/6;
  grid-row: 4/5;
  background-color: rgba(14, 171, 169, 0.05);
}
.v-v1 .essential-topics-grid-bottom-right-circle::before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #42389e;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-bottom-right-circle::before {
    display: none;
  }
}
.v-v1 .essential-topics-grid-container {
  grid-column: 2/6;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-container {
    padding: 48px 40px;
  }
}
.v-v1 .essential-topics-grid-top-left-container {
  grid-row: 8/9;
  margin-top: 40px;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-top-left-container {
    background-color: rgba(14, 171, 169, 0.05);
    margin-top: 0;
    grid-column: 3/4;
    grid-row: 2/3;
  }
}
.v-v1 .essential-topics-grid-top-right-container {
  grid-row: 9/10;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-top-right-container {
    background-color: rgba(14, 171, 169, 0.05);
    grid-column: 5/6;
    grid-row: 2/3;
  }
}
.v-v1 .essential-topics-grid-bottom-left-container {
  grid-row: 10/11;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-bottom-left-container {
    background-color: rgba(166, 55, 151, 0.05);
    grid-column: 3/4;
    grid-row: 4/5;
  }
}
.v-v1 .essential-topics-grid-bottom-right-container {
  grid-row: 11/12;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-bottom-right-container {
    background-color: rgba(14, 171, 169, 0.05);
    grid-column: 5/6;
    grid-row: 4/5;
  }
}
.v-v1 .essential-topics-grid-legend {
  grid-row: 7/8;
  grid-column: 2/6;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-legend {
    grid-column: 1/6;
  }
}
.v-v1 .essential-topics-grid-legend-item {
  font-size: 16px;
  margin-right: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .essential-topics-grid-legend-item {
    font-size: inherit;
  }
}
.v-v1 .essential-topics-grid-legend-item::before {
  content: "";
  display: block;
  width: 32px;
  height: 10px;
  margin-right: 16px;
}
.v-v1 .essential-topics-grid-legend-item:first-child::before {
  background-color: rgb(166, 55, 151);
}
.v-v1 .essential-topics-grid-legend-item:last-child::before {
  background-color: rgb(14, 171, 169);
}
.v-v1 .vertical-spacer-grid {
  display: grid;
  grid-template-rows: auto;
  gap: 32px;
  margin-bottom: 64px;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .vertical-spacer-grid {
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px 64px;
  }
  .v-v1 .vertical-spacer-grid > .vertical-spacer-grid__item:nth-child(n)::before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #d6d6d6;
    margin-left: calc(-1 * 32px);
  }
  .v-v1 .vertical-spacer-grid > .vertical-spacer-grid__item:nth-child(n)::before {
    display: none;
  }
  .v-v1 .vertical-spacer-grid > .vertical-spacer-grid__item:nth-child(even)::before {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .v-v1 .vertical-spacer-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 48px 64px;
  }
  .v-v1 .vertical-spacer-grid > .vertical-spacer-grid__item:nth-child(n)::before {
    display: block;
  }
  .v-v1 .vertical-spacer-grid > .vertical-spacer-grid__item:nth-child(3n+1)::before {
    display: none;
  }
}
.v-v1 .vertical-spacer-grid__item {
  position: relative;
}
.v-v1 .vertical-spacer-grid .swisscom-kennzahl-lead {
  margin-bottom: 8px !important;
  font-weight: bold !important;
}
.v-v1 .vertical-spacer-grid .swisscom-kennzahl-title {
  font-size: 32px;
  line-height: 32px;
  font-weight: bold !important;
  color: #086adb !important;
  margin-bottom: 0px !important;
}
@media screen and (min-width: 768px) {
  .v-v1 .vertical-spacer-grid .swisscom-kennzahl-title {
    font-size: calc(
            32px +
              16 *
              ((100vw - 768px) / (1152))
          );
    line-height: calc(
            32px +
              16 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .vertical-spacer-grid .swisscom-kennzahl-title {
    font-size: 48px;
    line-height: 48px;
  }
}
.v-v1 .vertical-spacer-grid .swisscom-kennzahl-body {
  margin-bottom: 0 !important;
}
.v-v1 .quote-container {
  margin-bottom: 32px;
  margin-top: 32px;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container {
    margin-bottom: calc(
            32px +
              16 *
              ((100vw - 768px) / (1152))
          );
    margin-top: calc(
            32px +
              16 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container {
    margin-bottom: 48px;
    margin-top: 48px;
  }
}
.v-v1 .quote-container.image {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .quote-container.image {
    flex-direction: row;
  }
  .v-v1 .quote-container.image .quote-lead {
    padding-left: 56px;
    padding-top: 48px;
    margin-bottom: 0 !important;
  }
}
.v-v1 .quote-container.image .quote-lead {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  position: relative;
  margin-bottom: 0 !important;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container.image .quote-lead {
    font-size: calc(
            18px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container.image .quote-lead {
    font-size: 26px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container.image .quote-lead {
    line-height: calc(
            24px +
              10 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container.image .quote-lead {
    line-height: 34px;
  }
}
.v-v1 .quote-container.image .quote-start,
.v-v1 .quote-container.image .quote-end {
  font-size: 56px;
  line-height: 24px;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container.image .quote-start,
  .v-v1 .quote-container.image .quote-end {
    font-size: calc(
            56px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container.image .quote-start,
  .v-v1 .quote-container.image .quote-end {
    font-size: 64px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container.image .quote-start,
  .v-v1 .quote-container.image .quote-end {
    line-height: calc(
            24px +
              10 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container.image .quote-start,
  .v-v1 .quote-container.image .quote-end {
    line-height: 34px;
  }
}
.v-v1 .quote-container.image .quote-start {
  position: absolute;
  left: -32px;
  top: 0;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .quote-container.image .quote-start {
    left: 12px;
    top: unset;
  }
}
.v-v1 .quote-container.image .quote-end {
  position: absolute;
  bottom: 0px;
  margin-left: 8px;
}
.v-v1 .report-2022.report-lang-en .quote-container .quote-start {
  position: absolute;
  left: unset;
  top: 0;
  margin-left: -20px;
  margin-top: 10px;
}
@media only screen and (min-width: 1024px) {
  .v-v1 .report-2022.report-lang-en .quote-container .quote-start {
    top: unset;
  }
}
.v-v1 .report-2022.report-lang-en .quote-container .quote-end {
  position: absolute;
  bottom: 0;
  margin-left: 8px;
  margin-top: unset;
  top: unset;
  bottom: unset;
  bottom: -12px;
}

.v-v1 .quote-container__image {
  aspect-ratio: 1/1;
  object-fit: cover;
}
@media only screen and (min-width: 540px) {
  .v-v1 .quote-container__image {
    margin: 0 auto 32px auto;
    max-width: 33.3333%;
  }
}
@media only screen and (min-width: 1024px) {
  .v-v1 .quote-container__image {
    margin: unset;
    margin-bottom: 0;
  }
}
.v-v1 .quote-container__header, .v-v1 .quote-container__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.v-v1 .quote-container__header::before, .v-v1 .quote-container__footer::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #333333;
}
.v-v1 .quote-container__header {
  flex-direction: row-reverse;
  align-items: flex-end;
  padding-bottom: 32px;
  margin-top: 32px;
}
.v-v1 .quote-container__header .icon {
  max-width: 32px;
  margin: 0;
  margin-right: 16px;
}
.v-v1 .quote-container__footer {
  padding-top: 32px;
  margin-bottom: 32px;
}
.v-v1 .quote-container .quote-big {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container .quote-big {
    padding-left: calc(
            24px +
              24 *
              ((100vw - 768px) / (1152))
          );
    padding-right: calc(
            24px +
              24 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container .quote-big {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.v-v1 .quote-container .quote-big p {
  margin-bottom: 0;
}
.v-v1 .quote-container .quote-big .title {
  font-size: 24px;
  line-height: 24px;
  color: #086adb;
  font-weight: normal;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container .quote-big .title {
    font-size: calc(
            24px +
              48 *
              ((100vw - 768px) / (1152))
          );
    line-height: calc(
            24px +
              48 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container .quote-big .title {
    font-size: 72px;
    line-height: 72px;
  }
}
.v-v1 .quote-container .quote-lead {
  font-size: 16px;
  margin-bottom: 0 !important;
}
@media screen and (min-width: 768px) {
  .v-v1 .quote-container .quote-lead {
    font-size: calc(
            16px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .quote-container .quote-lead {
    font-size: 24px;
  }
}
.v-v1 .center, .v-v1 .center-xxl, .v-v1 .center-xl {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
}
.v-v1 .center-xl {
  max-width: 640px;
}
.v-v1 .center-xxl {
  max-width: 800px;
}
.v-v1 .max-width {
  max-width: 480px;
}
.v-v1 .max-width-xl {
  max-width: 640px;
}
.v-v1 .max-width-xxl {
  max-width: 800px;
}
.v-v1 .kpi-table {
  width: 100%;
}
.v-v1 .kpi-table.with-title tbody > tr:first-child {
  border-bottom: 0 !important;
}
.v-v1 .kpi-table.with-title tbody > tr:first-child td {
  border-bottom: 0 !important;
  padding-bottom: 16px;
}
.v-v1 .kpi-table.with-title tbody > tr:first-child:hover td {
  background-color: unset !important;
}
.v-v1 .kpi-table.with-title tbody > tr:first-child > td {
  color: #086adb !important;
}
.v-v1 .kpi-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
.v-v1 .kpi-table-row .label, .v-v1 .kpi-table-row .value {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .v-v1 .kpi-table-row .label, .v-v1 .kpi-table-row .value {
    font-size: calc(
            16px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .kpi-table-row .label, .v-v1 .kpi-table-row .value {
    font-size: 24px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .kpi-table-row .label, .v-v1 .kpi-table-row .value {
    line-height: calc(
            18px +
              8 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .kpi-table-row .label, .v-v1 .kpi-table-row .value {
    line-height: 26px;
  }
}
.v-v1 .kpi-table-row .value {
  font-weight: bold;
  padding-left: 16px;
}
.v-v1 .kpi-table-row:last-child {
  margin-bottom: 0;
}
.v-v1 .icon-container {
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;
}
.v-v1 .icon-container .icon {
  padding-top: 6px;
  min-width: 0;
}
.v-v1 .BulletUmweltStark::before, .v-v1 .BulletUmweltMittel::before, .v-v1 .BulletUmweltGering::before {
  content: "";
  border-radius: 100%;
  width: 8px;
  height: 8px;
}
.v-v1 .BulletUmweltGering::before {
  background-color: rgb(204, 239, 252);
}
.v-v1 .BulletUmweltMittel::before {
  background-color: rgb(128, 214, 247);
}
.v-v1 .BulletUmweltStark::before {
  background-color: #1781e3;
}
.v-v1 td.BulletUmweltGering::before,
.v-v1 td.BulletUmweltMittel::before,
.v-v1 td.BulletUmweltStark::before {
  display: block;
  margin: 0 auto;
}
.v-v1 span.BulletUmweltGering::before,
.v-v1 span.BulletUmweltMittel::before,
.v-v1 span.BulletUmweltStark::before {
  display: inline-block;
  margin-left: 8px;
}
.v-v1 .swisscom-marginal.swisscom-marginal--internal,
.v-v1 .swisscom-marginal.swisscom-marginal--internal span {
  color: #086adb !important;
}
.v-v1 .rating .swisscom-marginal.swisscom-marginal--external a, .v-v1 .rating .swisscom-marginal.swisscom-marginal--external *[data-shortlinkid],
.v-v1 .rating .swisscom-marginal.swisscom-marginal--external *[data-shortlinkurl] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.v-v1 .reader__body .swisscom-corporate-governance-name {
  color: #086adb;
}
.v-v1 .reader__body .swisscom-corporate-governance-nation {
  font-style: italic;
}
.v-v1 .line-title {
  border-bottom: 2px solid #999999;
}
.v-v1 .swisscom-about-header-blue,
.v-v1 .swisscom-about-value-blue,
.v-v1 .swisscom-about-unit,
.v-v1 .swisscom-about-box-fact,
.v-v1 .swisscom-about-box-fact-title {
  display: none;
}
.v-v1 .swisscom-about-box-fact-title {
  margin-bottom: 0 !important;
}
.v-v1 .swisscom-about-header-navy,
.v-v1 .swisscom-about-header-grey,
.v-v1 .swisscom-about-box-fact-title,
.v-v1 .swisscom-about-header {
  font-weight: bold !important;
  margin-bottom: 0 !important;
}
.v-v1 .swisscom-about-value-navy-36pt,
.v-v1 .swisscom-about-value-navy-30pt,
.v-v1 .swisscom-about-value-grey-36pt,
.v-v1 .swisscom-about-value-grey-30pt,
.v-v1 .swisscom-about-value-grey-29pt,
.v-v1 .swisscom-about-value-navy-25pt,
.v-v1 .swisscom-about-value-grey-25pt,
.v-v1 .swisscom-about-value-navy-21pt,
.v-v1 .swisscom-about-value-navy-30pt_small,
.v-v1 .swisscom-about-value {
  margin-bottom: 0 !important;
}
.v-v1 .swisscom-about-footnote,
.v-v1 .swisscom-about-footnote *,
.v-v1 .esg-body-text-footnote,
.v-v1 .esg-body-text-footnote * {
  font-size: 16px !important;
}
.v-v1 .swisscom-about-text-enum {
  margin-bottom: 0 !important;
}
.v-v1 .swisscom-about-text-enum > .blue {
  margin-right: 8px;
}
.v-v1 .swisscom-about-text-enum + .swisscom-about-header-grey {
  margin-top: 30px;
}
.v-v1 .swisscom-about-text + .swisscom-about-text-enum {
  margin-top: -20px;
}
.v-v1 .swisscom-corporate-governance-name {
  color: #086adb !important;
  font-weight: bold !important;
  margin-bottom: 0 !important;
}
.v-v1 .swisscom-corporate-governance-function,
.v-v1 .swisscom-corporate-governance-subtitle {
  font-weight: bold !important;
}
.v-v1 .swisscom-about-box-title-1zeile,
.v-v1 .swisscom-about-box-title-2zeilen {
  margin: 0 0 0.8rem 0 !important;
}
.v-v1 .swisscom-about-box-title-1zeile + .swisscom-about-box-subtitle,
.v-v1 .swisscom-about-box-title-2zeilen + .swisscom-about-box-subtitle {
  margin-top: 2rem;
}
.v-v1 td.indicator-goal-red,
.v-v1 td.indicator-goal-green {
  position: relative;
  text-align: center;
}
.v-v1 td.indicator-goal-red::before,
.v-v1 td.indicator-goal-green::before {
  margin-right: 0.2rem;
}
.v-v1 td.indicator-goal-red sup,
.v-v1 td.indicator-goal-green sup {
  margin-right: -0.8rem;
}
.v-v1 td.indicator-goal-red::before,
.v-v1 .indicator-footnote-goal-red::before {
  content: " ";
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  border-width: 1.4rem;
  background-color: #dd1122;
  margin-top: 0.2rem;
}
.v-v1 td.indicator-goal-green::before,
.v-v1 .indicator-footnote-goal-green::before {
  content: " ";
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  border-width: 1.4rem;
  background-color: #759126;
  margin-top: 0.2rem;
}
.v-v1 .indicator-footnote-goal-green,
.v-v1 .indicator-footnote-goal-red {
  display: inline-block;
  padding-top: 0.2rem;
  margin: 0;
  margin-right: 0.4rem;
  padding: 0;
}
.v-v1 .indicator-footnote-goal-green::before,
.v-v1 .indicator-footnote-goal-red::before {
  margin: 0;
  display: block;
}
.v-v1 .footnote > .table-legend sup {
  margin-right: 0.4rem;
}
.v-v1 .indicator-footnote-goal-red {
  margin-left: 0.9rem;
}
.v-v1 .tablesaw-cell-content img[alt*=SDG] {
  margin-top: 0.35rem;
}
.v-v1 .sdg-marginal {
  display: block;
  margin-bottom: 3rem;
}
.v-v1 .sdg-marginal img {
  margin: 0;
  width: 3.8rem;
  height: 3.8rem;
}
.v-v1 .sdg-marginal > a, .v-v1 .sdg-marginal > *[data-shortlinkid],
.v-v1 .sdg-marginal > *[data-shortlinkurl] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .v-v1 .sdg-marginal > a, .v-v1 .sdg-marginal > *[data-shortlinkid],
  .v-v1 .sdg-marginal > *[data-shortlinkurl] {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.v-v1 .sdg-marginal > a, .v-v1 .sdg-marginal > *[data-shortlinkid],
.v-v1 .sdg-marginal > *[data-shortlinkurl], .v-v1 .sdg-marginal > a *, .v-v1 .sdg-marginal > *[data-shortlinkid] *,
.v-v1 .sdg-marginal > *[data-shortlinkurl] * {
  font-weight: normal;
  color: #000000;
  line-height: 24px;
  text-decoration: none;
}
.v-v1 .sdg-marginal > a span, .v-v1 .sdg-marginal > *[data-shortlinkid] span,
.v-v1 .sdg-marginal > *[data-shortlinkurl] span {
  padding-bottom: 0.3rem;
  border-bottom: 0.1rem solid #086adb !important;
}
.v-v1 .sdg-marginal > a span, .v-v1 .sdg-marginal > *[data-shortlinkid] span,
.v-v1 .sdg-marginal > *[data-shortlinkurl] span, .v-v1 .sdg-marginal > a span b, .v-v1 .sdg-marginal > *[data-shortlinkid] span b,
.v-v1 .sdg-marginal > *[data-shortlinkurl] span b {
  line-height: 1;
}
.v-v1 .sdg-marginal > a span:first-of-type, .v-v1 .sdg-marginal > *[data-shortlinkid] span:first-of-type,
.v-v1 .sdg-marginal > *[data-shortlinkurl] span:first-of-type {
  border-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .v-v1 .sdg-marginal > a span:first-of-type, .v-v1 .sdg-marginal > *[data-shortlinkid] span:first-of-type,
  .v-v1 .sdg-marginal > *[data-shortlinkurl] span:first-of-type {
    border-bottom: 0.1rem solid #086adb !important;
    margin-left: 1.2rem;
  }
}
@media only screen and (min-width: 768px) {
  .v-v1 .sdg-marginal > a span:last-of-type, .v-v1 .sdg-marginal > *[data-shortlinkid] span:last-of-type,
  .v-v1 .sdg-marginal > *[data-shortlinkurl] span:last-of-type {
    margin-left: 0.4rem;
  }
}
.v-v1 .no-indicator,
.v-v1 .noBullet {
  list-style: none !important;
  background: 0 !important;
}
.v-v1 .report--1 .level.level--2 .arrow {
  color: #1a171b;
}
.v-v1 .swisscom-marginal {
  display: block;
}
.v-v1 .swisscom-marginal > .link .external-link {
  text-decoration: none !important;
}
.v-v1 .reader__navigation__menu .level--3 .text,
.v-v1 .reader__navigation__menu .level--4 .text,
.v-v1 .reader__navigation__menu .level--5 .text {
  transition: all 0.25s ease-out;
}
.v-v1 .reader__navigation__menu .level--3.selected .text,
.v-v1 .reader__navigation__menu .level--4.selected .text,
.v-v1 .reader__navigation__menu .level--5.selected .text {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__navigation__menu .level--3.selected .text,
  .v-v1 .reader__navigation__menu .level--4.selected .text,
  .v-v1 .reader__navigation__menu .level--5.selected .text {
    padding-top: calc(
            16px +
              3 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__navigation__menu .level--3.selected .text,
  .v-v1 .reader__navigation__menu .level--4.selected .text,
  .v-v1 .reader__navigation__menu .level--5.selected .text {
    padding-top: 19px;
  }
}
@media screen and (min-width: 768px) {
  .v-v1 .reader__navigation__menu .level--3.selected .text,
  .v-v1 .reader__navigation__menu .level--4.selected .text,
  .v-v1 .reader__navigation__menu .level--5.selected .text {
    padding-bottom: calc(
            16px +
              3 *
              ((100vw - 768px) / (1152))
          );
  }
}
@media screen and (min-width: 1920px) {
  .v-v1 .reader__navigation__menu .level--3.selected .text,
  .v-v1 .reader__navigation__menu .level--4.selected .text,
  .v-v1 .reader__navigation__menu .level--5.selected .text {
    padding-bottom: 19px;
  }
}
.v-v1 .reader__overlay {
  margin: 0 !important;
}
.v-v1 .reader__overlay .overlay__container {
  width: 100%;
  max-width: 1400px;
}
.v-v1 .reader__overlay .reader__content {
  width: 100%;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 300;
}
.v-v1 .reader__overlay .reader__content .container, .v-v1 .reader__overlay .reader__content .order-form .content, .v-v1 .order-form .reader__overlay .reader__content .content {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.v-v1 .swisscom-person {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 24px;
}
.v-v1 .swisscom-person img {
  max-width: 100% !important;
  margin-top: 0;
  width: auto;
  height: 80px;
}
.v-v1 .swisscom-person > * {
  width: auto;
}
.v-v1 .gb-table :not(.highlight):has(+ .highlight) + .highlight {
  padding-left: 10px !important;
}
@media screen and (max-width: 767px) {
  .v-v1 .suppressMobile {
    display: none;
  }
}
.v-v1 span[data-attachment] {
  font-weight: inherit !important;
  color: inherit;
  font-size: inherit !important;
}
.v-v1 span[data-glossary] {
  font-weight: inherit !important;
  color: inherit;
  font-size: inherit !important;
}
.v-v1 .stackable + .stackable,
.v-v1 h3.marginTop,
.v-v1 .marginTop.h3,
.v-v1 .marginTop.swisscom-about-value-navy-36pt,
.v-v1 .marginTop.swisscom-about-value-navy-30pt,
.v-v1 .marginTop.swisscom-about-value-grey-36pt,
.v-v1 .marginTop.swisscom-about-value-grey-30pt,
.v-v1 .marginTop.swisscom-about-value-grey-29pt,
.v-v1 .marginTop.swisscom-about-value-navy-25pt,
.v-v1 .marginTop.swisscom-about-value-grey-25pt,
.v-v1 .marginTop.swisscom-about-value-navy-21pt,
.v-v1 .marginTop.swisscom-about-value-navy-30pt_small,
.v-v1 .marginTop.swisscom-about-value,
.v-v1 .marginTop.swisscom-about-box-title-1zeile,
.v-v1 .marginTop.swisscom-about-box-title-2zeilen {
  margin-top: 72px;
}