// create helper classes for bottom / top margins
@for $i from 1 through 10 {
  $value: $i * 4px;

  .m-#{$i} {
    margin: #{$value} !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$value} !important;
  }
  .mt-#{$i} {
    margin-top: #{$value} !important;
  }
  .mr-#{$i} {
    margin-right: #{$value} !important;
  }
  .ml-#{$i} {
    margin-left: #{$value} !important;
  }

  .p-#{$i} {
    padding: #{$value} !important;
  }
  .pl-#{$i} {
    padding-left: #{$value} !important;
  }
  .pr-#{$i} {
    padding-right: #{$value} !important;
  }
  .pt-#{$i} {
    padding-top: #{$value} !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$value} !important;
  }
}
