@import '../color-palette';

.override.reader__search__menu {
  .input__group--search {
    box-shadow: inset 1px 0 0 $grey-another-one-13;
  }

  input {
    font-family: 'TheSans', sans-serif;
    padding: 12px;
  }

  .search-highlight {
    padding: 0 1px;
    font-style: italic;
  }
}
