.tabcontrol {
  display: flex;
  flex-direction: column;
  background-color: color-for('sidebar-tabcontrol-background', $theme-colors);

  height: 100%;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;

    flex: 0 0 auto;

    list-style: none;
    margin-bottom: 0;

    background-color: color-for('sidebar-tabcontrol-header-background', $theme-colors);

    border-bottom: 1px solid color-for('sidebar-tabcontrol-header-border-bottom', $theme-colors);

    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    
    padding-top: 0;
    background-color: color-for('sidebar-tabcontrol-content-background', $theme-colors);
  }

  .tab {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    flex: 1;

    flex-direction: row;
    justify-content: center;
    align-items: center;

    .tab__icon {
      margin-right: 8px;
    }

    .tab__title {
      margin-top: 0;
    }

    border: 1px solid color-for('sidebar-tabcontrol-tab-border', $theme-colors);
    border-bottom: 1px solid color-for('sidebar-tabcontrol-tab-border-bottom', $theme-colors);
    border-left: 0;

    padding: 10px 0;
    margin-bottom: 0;

    background: color-for('sidebar-tabcontrol-tab-background', $theme-colors);

    & .tab__title,
    & .tab__icon {
      font-weight: inherit;
    }

    .tab__icon__title__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }

    &__icon {
      color: color-for('sidebar-tabcontrol-tab-icon-color', $theme-colors);
      font-size: 19px;
      transition: color 0.25s ease-in-out;
    }

    &__title {
      color: color-for('sidebar-tabcontrol-tab-title-color', $theme-colors);
      font-size: 14px;
      margin-top: 0;
      transition: color 0.25s ease-in-out;
    }

    &--active {
      border: 1px solid color-for('sidebar-tabcontrol-tab-active-border', $theme-colors);
      border-bottom: 1px solid color-for('sidebar-tabcontrol-tab-active-border-bottom', $theme-colors);
      background-color: color-for('sidebar-tabcontrol-tab-active-background', $theme-colors);

      .tab__title {
        color: color-for('sidebar-tabcontrol-tab-active-title-color', $theme-colors);
        font-weight: normal;
      }

      .tab__icon {
        color: color-for('sidebar-tabcontrol-tab-active-icon-color', $theme-colors);
        font-weight: normal;
      }

      &:hover {
        .tab__title {
          color: color-for('sidebar-tabcontrol-tab-active-title-color', $theme-colors);
        }

        .tab__icon {
          color: color-for('sidebar-tabcontrol-tab-active-icon-color', $theme-colors);
        }
      }
    }
  }

  .tab-content {
    display: none;

    &--active {
      display: block;
    }
  }
}
