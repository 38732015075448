.language__overlay {
  display: none;

  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;

  background-color: color-for('language-overlay-background', $theme-colors);

  z-index: 150;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      display: block;
      width: 100%;

      padding: 12px;

      color: color-for('language-overlay-link', $theme-colors);
      text-decoration: none;
      text-align: center;

      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;

      &.selected {
        font-weight: bold;
        color: color-for('language-overlay-link-selected', $theme-colors);
        background-color: color-for('language-overlay-link-selected-background', $theme-colors);
      }
    }
  }

  .icon--close {
    position: absolute;
    color: color-for('language-overlay-link', $theme-colors);
    font-size: 16px;

    top: 30px;
    right: 30px;

    width: 24px;
    height: 24px;

    cursor: pointer;
  }
}

@at-root .language-overlay-shown {
  .language__overlay {
    display: flex !important;

    @include bp-md {
      display: none !important;
    }
  }
}
