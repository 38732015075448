@use "sass:map";

// icon font config

$icon-font-font-path: '../fonts/' !default;

$icon-font-share: '\38' !default;
$icon-font-download: '\64' !default;
$icon-font-content: '\62' !default;
$icon-font-navigation: '\6e' !default;
$icon-font-home: '\68' !default;
$icon-font-navigation-close: '\77' !default;
$icon-font-content-close: '\7a' !default;
$icon-font-close: '\78' !default;
$icon-font-calendar: '\63' !default;
$icon-font-checkmark: '\61' !default;
$icon-font-dropdown: '\6a' !default;
$icon-font-language: '\6b' !default;
$icon-font-mail: '\6d' !default;
$icon-font-play: '\70' !default;
$icon-font-previous-chevron: '\3c' !default;
$icon-font-next-chevron: '\3e' !default;
$icon-font-left: '\6f' !default;
$icon-font-right: '\71' !default;
$icon-font-expand: '\72' !default;
$icon-font-collapse: '\75' !default;
$icon-font-search: '\73' !default;
$icon-font-search-line: '\65' !default;
$icon-font-twitter: '\74' !default;
$icon-font-xing: '\76' !default;
$icon-font-youtube: '\79' !default;
$icon-font-instagram: '\69' !default;
$icon-font-linkedin: '\6c' !default;
$icon-font-facebook: '\66' !default;
$icon-font-googleplus: '\67' !default;
$icon-font-document: '\2d' !default;
$icon-font-add: '\2b' !default;
$icon-font-link-ext: '\31' !default;
$icon-font-link-int: '\32' !default;

$icon-font: 'icon-font' !default;
$icon-font-name: 'icon-font' !default;

@at-root {
  @font-face {
    font-family: '#{$icon-font}';
    src: url('#{$icon-font-font-path}/#{$icon-font-name}.woff2?1b1q3y') format('woff2'),
      url('#{$icon-font-font-path}/#{$icon-font-name}.ttf?1b1q3y') format('truetype'),
      url('#{$icon-font-font-path}/#{$icon-font-name}.woff?1b1q3y') format('woff'),
      url('#{$icon-font-font-path}/#{$icon-font-name}.svg?1b1q3y#icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@at-root {
  .icon-font-home,
  .icon-font-content-close,
  .icon-font-content {
    font-family: '#{$icon-font}';
  }

  .icon-font-home {
    &:before {
      content: $icon-font-home;
    }
  }
  .icon-font-content-close {
    &:before {
      content: $icon-font-content-close;
    }
  }
  .icon-font-content {
    &:before {
      content: $icon-font-content;
    }
  }

  .icon-font-share {
    &:before {
      content: $icon-font-share;
    }
  }
  .icon-font-download {
    &:before {
      content: $icon-font-download;
    }
  }
  .icon-font-add {
    &:before {
      content: $icon-font-add;
    }
  }
  .icon-font-navigation {
    &:before {
      content: $icon-font-navigation;
    }
  }
  .icon-font-navigation-close {
    &:before {
      content: $icon-font-navigation-close;
    }
  }
  .icon-font-close {
    &:before {
      content: $icon-font-close;
    }
  }
  .icon-font-calendar {
    &:before {
      content: $icon-font-calendar;
    }
  }
  .icon-font-checkmark {
    &:before {
      content: $icon-font-checkmark;
    }
  }
  .icon-font-dropdown {
    &:before {
      content: $icon-font-dropdown;
    }
  }
  .icon-font-language {
    &:before {
      content: $icon-font-language;
    }
  }
  .icon-font-mail {
    &:before {
      content: $icon-font-mail;
    }
  }
  .icon-font-play {
    &:before {
      content: $icon-font-play;
    }
  }
  .icon-font-previous-chevron {
    &:before {
      content: $icon-font-previous-chevron;
    }
  }
  .icon-font-next-chevron {
    &:before {
      content: $icon-font-next-chevron;
    }
  }
  .icon-font-left {
    &:before {
      content: $icon-font-left;
    }
  }
  .icon-font-right {
    &:before {
      content: $icon-font-right;
    }
  }
  .icon-font-expand {
    &:before {
      content: $icon-font-expand;
    }
  }
  .icon-font-collapse {
    &:before {
      content: $icon-font-collapse;
    }
  }
  .icon-font-search {
    &:before {
      content: $icon-font-search;
    }
  }
  .icon-font-search-line {
    &:before {
      content: $icon-font-search-line;
    }
  }
  .icon-font-twitter {
    &:before {
      content: $icon-font-twitter;
    }
  }
  .icon-font-xing {
    &:before {
      content: $icon-font-xing;
    }
  }
  .icon-font-youtube {
    &:before {
      content: $icon-font-youtube;
    }
  }
  .icon-font-instagram {
    &:before {
      content: $icon-font-instagram;
    }
  }
  .icon-font-linkedin {
    &:before {
      content: $icon-font-linkedin;
    }
  }
  .icon-font-facebook {
    &:before {
      content: $icon-font-facebook;
    }
  }
  .icon-font-googleplus {
    &:before {
      content: $icon-font-googleplus;
    }
  }
  .icon-font-document {
    &:before {
      content: $icon-font-document;
    }
  }
  .icon-font-link-ext {
    &:before {
      content: $icon-font-link-ext;
    }
  }
  .icon-font-link-int {
    &:before {
      content: $icon-font-link-int;
    }
  }
}
