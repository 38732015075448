.reader__body {
  .overlay {
    &.order-form {
      &--shown {
        display: block;
      }
    }
  }
}

$grid-paddings-widths: (
  xs: 24px,
  md: 0
) !default;

.order-form {
  .content {
    @extend .container !optional;

    width: 100%;
    margin: 0 auto;
    @include multi-bp(padding-left, $grid-paddings-widths);
    @include multi-bp(padding-right, $grid-paddings-widths);
    color: color-for('order-form', $theme-colors);

    @at-root .navigation-shown #{&} {
      padding-left: 40px;
      padding-right: 40px;
    }

    form {
      box-shadow: 0 1px 4px 0 rgba($black, 0.4);
      margin-bottom: 30px;
      padding: 25px 10px;
      @include bp-md {
        padding: 48px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    padding: 20px 0;

    input[type='submit'] {
      margin-left: 40px;
    }

    a {
      font-size: 16px;
      text-decoration: none;

      &,
      &:active,
      &:visited {
        color: color-for('order-form-link', $theme-colors);
      }
    }

    .spinner__loader {
      display: inline-block;
      margin: 0 0 0 10px;
      align-self: 10px;
    }
  }

  label {
    display: block;

    width: 100%;
    margin-bottom: 5px;

    color: color-for('order-form-label', $theme-colors);
  }

  input {
    display: block;

    width: 100%;
    padding: 8px 15px;

    box-shadow: none;
    border: 2px solid color-for('order-form-input-border', $theme-colors);
    border-radius: 6px;

    background-color: color-for('order-form-input-background', $theme-colors);

    color: color-for('order-form-input', $theme-colors);
    transition: border 160ms linear;

    &:focus,
    &:active {
      outline: none;

      border: 2px solid color-for('order-form-input-active-border', $theme-colors);
    }

    &::-ms-expand {
      display: none;
    }
  }

  input[type='submit'] {
    max-width: 240px;
    min-width: 240px;
    border: 0;
    width: auto;
    height: auto;
    display: inline-block;
    padding: 11px 22px;
    background: color-for('order-form-submit-background', $theme-colors);
    border-radius: 5px;
    color: color-for('order-form-submit', $theme-colors);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    text-decoration: none;

    font-size: 16px;
    @include bp-md {
      font-size: 18px;
    }

    &:hover {
      background: color-for('order-form-submit-hover-background', $theme-colors);
    }
  }

  .form {
    margin-top: 50px;
  }

  .form-group {
    margin-bottom: 15px;

    &__error {
      label {
        color: color-for('order-form-error-label', $theme-colors);
      }

      input {
        background-color: color-for('order-form-error-input-background', $theme-colors);
        border-color: color-for('order-form-error-input-border', $theme-colors);
      }

      & > .form-group__error-message {
        margin-top: 8px;
        color: color-for('order-form-error-message', $theme-colors);
        font-weight: 300;
      }
    }

    &--radio-buttons {
      .radio-buttons {
        display: flex;
        flex-direction: row;

        margin-bottom: 10px;

        & > div {
          flex: 0 1 auto;

          margin-right: 20px;
        }
      }

      input[type='radio'] {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }

    &__submit {
      margin-top: 50px;
    }
  }

  .form__copies {
    margin-bottom: 50px;
    max-width: 300px;
  }
}
