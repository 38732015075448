.reader__content__wrapper {
  *[data-glossary],
  *[data-open-html-element-id-in-overlay],
  *[data-attachment],
  *[data-attachment-shortlinkid] {
    position: relative;

    cursor: pointer;
    user-select: text;

    border-bottom: 1px dotted color-for('glossary', $theme-colors);
    display: inline;

    &:before {
      content: ' ';
      display: inline-block;
    }
  }

  .gb-table {
    span[data-glossary],
    span[data-attachment] {
      font-weight: inherit;

      user-select: none;
    }

    tr {
      td:first-child span[data-glossary],
      td:first-child span[data-attachment] {
        font-weight: 300;
      }
    }
  }

  .external-link {
    position: relative;
    cursor: pointer;
    text-decoration: none !important;
    color: color-for('link-external', $theme-colors);
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    padding-bottom: 1px;
    border-bottom: 1px solid color-for('link-external', $theme-colors);

    &::after {
      content: '\33';
      font-family: '#{$icon-font}.v-#{$design-version}';
      display: inline-block;
      margin-left: 8px;
    }
  }

  *[data-internallink],
  *[data-shortlinkid],
  *[data-shortlinkurl],
  .internal-link {
    &,
    &:not([href]) {
      position: relative;

      cursor: pointer;
      user-select: text;

      color: color-for('link-internal', $theme-colors);
      font-weight: inherit;
      font-family: inherit;
      font-size: inherit;
      text-decoration: none !important;

      &::after {
        content: '';
        font-family: '#{$icon-font}.v-#{$design-version}';
        display: inline-block;
        margin-left: 8px;
      }
    }
  }

  p + a,
  p + *[data-internallink] {
    display: block;
    margin-top: -16px !important;
    margin-bottom: 16px;

    @include bp-md {
      margin-top: -24px;
      margin-bottom: 24px;
    }
  }

  *[data-videourl] {
    cursor: pointer;
  }
}
