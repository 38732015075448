$bp-tablesaw-stacked: map-get($grid-breakpoints, md);

.tablesaw {
  width: 100%;
  max-width: 100%;

  padding: 0;

  border: 0;
  border-collapse: collapse;

  empty-cells: show;

  th,
  td {
    box-sizing: border-box;
  }

  thead {
    background: 0;
  }

  thead tr:first-child th {
    &:first-child {
      display: table-cell;
    }
  }

  thead th[colspan] {
    text-align: right;
  }
}

.tablesaw-stack {
  td,
  th {
    .tablesaw-cell-label {
      display: none;
    }
  }
}

.tablesaw-stack {
  th,
  td {
    display: block;

    .tablesaw-cell-label {
      display: inline-block;
      padding: 0 6px 0 0;

      width: 50%;
    }

    .tablesaw-cell-label-top {
      display: block;

      margin: 4px 0;
    }

    .tablesaw-cell-content {
      width: 50%;
    }
  }

  tr {
    clear: both;
    display: table-row;
  }

  tbody th.group {
    margin-top: -1px;
  }

  th.group b.tablesaw-cell-label {
    display: none !important;
  }
}

.tablesaw-cell-label {
  display: block;
}

@media (max-width: $bp-tablesaw-stacked - 1px) {
  .tablesaw-stack {
    thead {
      td,
      th {
        display: none;
        clear: left;
        float: left;
        width: 100%;
      }
    }

    td:empty,
    th:empty {
      display: none;
    }
  }

  .tablesaw-cell-label {
    vertical-align: top;
  }

  .tablesaw-cell-content {
    display: inline-block;
  }
}

@media (min-width: $bp-tablesaw-stacked) {
  .tablesaw-stack {
    tr {
      display: table-row;
    }

    td,
    th,
    thead td,
    thead th {
      display: table-cell;

      margin: 0;
    }

    td .tablesaw-cell-label,
    th .tablesaw-cell-label {
      display: none !important;
    }
  }
}

@media (max-width: $bp-tablesaw-stacked - 0.1px) {
  .tablesaw-cell-hidden {
    display: none !important;
  }
}
