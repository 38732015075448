@import '../color-palette';

@mixin generate-swisscom-lead() {
  .swisscom-lead {
    @extend .reader-lead;
    color: $blue;
    border-left: 1px solid $blue;
  }

  .esg-body-lead:has(+ :not(.esg-body-lead)) {
    margin: 0 0 40px 0 !important;
    @include media-breakpoint-up(md) {
      margin: 0 0 40px 0 !important;
    }
  }
}
