img,
.reader-image {
  display: block;

  width: 100%;
  max-width: 100%;
  min-height: 1px;
  height: auto;

  margin: 0 0 30px 0;
}

.reader-image--full {
  @include bp-md {
    max-width: 100%;
  }
}

.reader-image--half {
  max-width: 50%;
}
