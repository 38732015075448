@at-root .reader__app {
  &.overlay-shown {
    overflow: hidden;

    .reader__overlay {
      display: flex;
    }
  }
}

$navigation--phone-max-width: 320px !default;
$modal-header-height: 60px !default;
$modal--footer-height: 50px !default;

// ====== Reader Body - Navigation ======

.sidebar {
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr;

  top: $header-height;

  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  opacity: 0;
  visibility: hidden;

  overflow-y: auto;

  &.small-header {
    top: $header--small-height;

    @include bp-md {
      top: $header-height;
    }
  }
}

.reader__body {
  padding-top: $header-height;

  &.navigation-shown {
    .sidebar {
      opacity: 1;
      visibility: visible;
    }
  }

  &.small-header {
    padding-top: $header--small-height;
  }
}

@include bp-md {
  .sidebar {
    position: fixed;

    width: $navigation--phone-max-width;

    // Overwrite menu behavior because of different way the navigation is shown on large devices. This resets the CSS instructions from the basic class definition.
    opacity: 1;
    visibility: visible;
  }

  .sidebar {
    left: -($navigation--phone-max-width + 8px);
    right: auto;
  }

  .reader__body {
    &.navigation-shown {
      .sidebar {
        left: 0;
        overflow: visible; // lipp: 06.05.2020 safari fix ... -_- macOS only
      }
    }
    &.small-header {
      padding-top: $header-height;
    }
  }
}

// ====== Reader Body - Content ======

.reader__content__wrapper {
  display: none !important;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.active {
    display: block !important;
  }

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 80px;
  }
}

.reader__content {
  position: relative;

  // max-width: $content-max-width;

  // padding: $content-padding-small;
  margin: 0 auto;

  z-index: 90;
}

@include bp-md {
  .reader__body {
    &.navigation-shown {
      .reader__content__wrapper {
        display: block;

        margin-left: $navigation--phone-max-width;
      }
    }
  }
}

// ====== Reader Overlay ======

.reader__overlay {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  @media only screen and (max-width: 767px) {
    &.reader__body {
      padding-top: 0px;
    }
  }

  background-color: color-for('modal-overlay-backdrop', $theme-colors);

  z-index: 200;

  .overlay__container {
    display: flex;
    flex-direction: column;

    height: 100%;
    max-width: 100%;
    margin-bottom: 0 !important;

    background-color: color-for('modal-background', $theme-colors);
  }

  .header {
    position: relative;

    flex: 0 0 $modal-header-height;
    height: $modal-header-height;

    .icon-font {
      position: absolute;
      color: color-for('modal-close-icon', $theme-colors);
      top: 24px;
      right: 24px;
      font-size: 24px;
      width: 24px;
      height: 24px;

      cursor: pointer;
    }
  }

  .reader__content {
    flex: 0 1 auto;

    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;

    overflow: auto;
  }

  .footer {
    flex: 0 0 $modal--footer-height;
    height: $modal--footer-height;
  }

  .reader__body {
    &.navigation-shown {
      .reader__content__wrapper {
        margin-left: 0;
      }
    }
  }
}

@include bp-md {
  .reader__overlay {
    justify-content: center;
    align-items: center;

    padding: 40px;

    .overlay__container {
      flex: 0 0 auto;
      height: auto;

      .reader__content {
        max-height: calc(80vh - 110px);
      }

      .header {
        flex: 0 0 (1.5 * $modal-header-height);
      }

      .footer {
        flex: 0 0 (1.5 * $modal--footer-height);
      }
    }
  }
}

@include bp-xl {
  .reader__overlay {
    .overlay__container {
      max-width: max-width-xl();
    }
  }
}
