@at-root .reader-body {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
    z-index: 94;

    margin-top: $header-height;
    overflow: auto;
    background: white;

    &--shown {
      display: block;
    }
  }

  @include bp-md {
    &.navigation-shown {
      .overlay {
        margin-left: $navigation--phone-max-width;
      }
    }
  }
}
