@import '../../../../base/_mixins/breakpoints';

$base--readerbody--p-margin-bottom: 30px !default;
$base--readerbody--ul-margin-bottom: $base--readerbody--p-margin-bottom !default;

$marginal-enabled: false;
$marginal-color: $blue-accessible !important;
$marginal-totheside-enabled: false;
$marginal--external-background: url('../images/globe-icon-2x.png') no-repeat left center;
$marginal-height: 24px;
$marginal--external-background-size: $marginal-height $marginal-height;
$marginal--internal-background: url('../images/document-icon-2x.png') no-repeat left center;
$marginal--internal-background-size: $marginal-height $marginal-height;
$content-padding-side-large: 140px;

@mixin generate-swisscom-marginal() {
  ul + .swisscom-marginal {
    margin-top: -#{$base--readerbody--ul-margin-bottom - 8px};
  }

  $className: '.swisscom-marginal';

  #{$className} + #{$className} {
    margin-top: -16px;
  }

  #{$className} {
    margin-bottom: 30px;
    display: inline-block;
    height: auto;

    .text,
    .external-link {
      font-weight: normal;
      color: $marginal-color;
      line-height: 24px;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .link a,
    .link b,
    .external-link,
    *[data-internallink],
    *[data-shortlinkid] {
      text-decoration: none;
      color: $marginal-color;
      cursor: pointer;
      font-weight: bold !important;

      border-bottom: 0 !important;

      &:visited {
        color: $marginal-color;
      }
      &:hover {
        text-decoration: underline !important;
      }
    }

    .link b {
      cursor: unset;
      &:hover {
        text-decoration: none !important;
      }
    }

    *[data-shortlinkid] {
      font-weight: 600 !important;
    }

    &.swisscom-marginal--internal,
    &.swisscom-marginal--external {
      padding-left: 40px;
    }

    &.swisscom-marginal--internal {
      background: $marginal--internal-background;
      background-size: $marginal--internal-background-size;
    }

    &.swisscom-marginal--external {
      background: $marginal--external-background;
      background-size: $marginal--external-background-size;
    }
  }

  .swisscom-body--followed-by-marginal {
    margin-bottom: 15px !important;
  }
}
