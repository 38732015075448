@use 'sass:math';

@mixin spacer($offset) {
  &::before {
    content: '';
    display: block;
    position: absolute;

    width: 2px;
    height: 100%;
    background-color: $grey-another-one-3;

    margin-left: calc(-1 * #{$offset});
  }
}

.vertical-spacer-grid {
  display: grid;
  grid-template-rows: auto;
  gap: 32px;

  margin-bottom: 64px;

  @include bp(tablet_landscape) {
    $horizontal-gap: 64px;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px $horizontal-gap;

    & > .vertical-spacer-grid__item:nth-child(n) {
      @include spacer(math.div($horizontal-gap, 2));

      &::before {
        display: none;
      }
    }

    & > .vertical-spacer-grid__item:nth-child(even) {
      &::before {
        display: block;
      }
    }
  }

  @include bp(desktop) {
    grid-template-columns: repeat(3, 1fr);
    gap: 48px 64px;

    & > .vertical-spacer-grid__item:nth-child(n) {
      &::before {
        display: block;
      }
    }

    & > .vertical-spacer-grid__item:nth-child(3n + 1) {
      &::before {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
  }

  .swisscom-kennzahl-lead {
    margin-bottom: 8px !important;
    font-weight: bold !important;
  }

  .swisscom-kennzahl-title {
    @include fluid((font-size, line-height), 32px, 48px);
    font-weight: bold !important;
    color: $blue-accessible !important;
    margin-bottom: 0px !important;
  }

  .swisscom-kennzahl-body {
    margin-bottom: 0 !important;
  }
}

.quote-container {
  @include fluid((margin-bottom, margin-top), 32px, 48px);

  &.image {
    display: flex;
    flex-direction: column;

    @include bp(tablet_landscape) {
      flex-direction: row;

      .quote-lead {
        padding-left: 56px;
        padding-top: 48px;
        margin-bottom: 0 !important;
      }
    }

    .quote-lead {
      @include fluid((font-size), 18px, 26px);
      @include fluid((line-height), 24px, 34px);
      font-weight: bold;
      position: relative;
      margin-bottom: 0 !important;
    }

    .quote-start,
    .quote-end {
      @include fluid((font-size), 56px, 64px);
      @include fluid((line-height), 24px, 34px);
      display: inline-block;
    }

    .quote-start {
      position: absolute;
      left: -32px;
      top: 0;

      @include bp(tablet_landscape) {
        left: 12px;
        top: unset;
      }
    }

    .quote-end {
      position: absolute;
      bottom: 0px;
      margin-left: 8px;
    }

    @at-root .v-#{$design-version} .report-2022.report-lang-en .quote-container {
      .quote-start {
        position: absolute;
        left: unset;
        top: 0;
        margin-left: -20px;
        margin-top: 10px;

        @include bp(tablet_landscape) {
          top: unset;
        }
      }

      .quote-end {
        position: absolute;
        bottom: 0;
        margin-left: 8px;
        margin-top: unset;
        top: unset;
        bottom: unset;
        bottom: -12px;
      }
    }
  }

  &__image {
    aspect-ratio: 1/1;
    object-fit: cover;

    @include bp(540px) {
      margin: 0 auto 32px auto;
      max-width: 33.3333%;
    }

    @include bp(tablet_landscape) {
      margin: unset;
      margin-bottom: 0;
    }
  }

  &__header,
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: $grey;
    }
  }

  &__header {
    flex-direction: row-reverse;
    align-items: flex-end;
    padding-bottom: 32px;
    margin-top: 32px;

    .icon {
      max-width: 32px;
      margin: 0;
      margin-right: 16px;
    }
  }

  &__footer {
    padding-top: 32px;
    margin-bottom: 32px;
  }

  .quote-big {
    @include fluid((padding-left, padding-right), 24px, 48px);

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    .title {
      @include fluid((font-size, line-height), 24px, 72px);
      color: $blue-accessible;
      font-weight: normal;
      margin-bottom: 16px;
    }
  }

  .quote-lead {
    @include fluid((font-size), 16px, 24px);
    margin-bottom: 0 !important;
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
}

.center-xl {
  @extend .center;
  max-width: 640px;
}

.center-xxl {
  @extend .center;
  max-width: 800px;
}

.max-width {
  max-width: 480px;
}

.max-width-xl {
  max-width: 640px;
}

.max-width-xxl {
  max-width: 800px;
}

.kpi-table {
  width: 100%;

  &.with-title {
    tbody > tr:first-child {
      border-bottom: 0 !important;

      td {
        border-bottom: 0 !important;
        padding-bottom: 16px;
      }

      &:hover td {
        background-color: unset !important;
      }

      & > td {
        color: $blue-accessible !important;
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid $grey;

    & .label,
    & .value {
      @include fluid((font-size), 16px, 24px);
      @include fluid((line-height), 18px, 26px);
      margin-bottom: 0;
    }

    & .value {
      font-weight: bold;
      padding-left: 16px;
    }

    padding-bottom: 8px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.icon-container {
  margin-bottom: 64px;

  display: grid;
  grid-template-columns: 16px auto;
  gap: 8px;

  .icon {
    padding-top: 6px;
    min-width: 0;
  }
}

%BulletUmwelt {
  &::before {
    content: '';
    border-radius: 100%;
    width: 8px;
    height: 8px;
  }
}

.BulletUmweltGering {
  @extend %BulletUmwelt;

  &::before {
    background-color: rgb(204, 239, 252);
  }
}

.BulletUmweltMittel {
  @extend %BulletUmwelt;

  &::before {
    background-color: rgb(128, 214, 247);
  }
}

.BulletUmweltStark {
  @extend %BulletUmwelt;

  &::before {
    background-color: #1781e3;
  }
}

td.BulletUmweltGering,
td.BulletUmweltMittel,
td.BulletUmweltStark {
  &::before {
    display: block;
    margin: 0 auto;
  }
}

span.BulletUmweltGering,
span.BulletUmweltMittel,
span.BulletUmweltStark {
  &::before {
    display: inline-block;
    margin-left: 8px;
  }
}

.swisscom-marginal.swisscom-marginal--internal {
  &,
  span {
    color: $blue-accessible !important;
  }
}
