.reader__body {
  .sidebar {
    overflow: hidden;
    background-color: color-for('sidebar-background', $theme-colors);
    border-right: 1px solid color-for('sidebar-border-right', $theme-colors);

    z-index: 99;

    & > *:nth-child(2) {
      flex: 0 0 auto;
      height: 100%;
    }

    .tabcontrol {
      min-height: 0;
    }
  }
}
