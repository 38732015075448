@mixin generate-swisscom-person() {
  $person-margin: 0 !default;
  $person--img-margin: 30px 0 10px 0 !default;

  .reader-person {
    margin: $person-margin;

    img {
      margin: $person--img-margin;
    }
  }

  .swisscom-person {
    @extend .reader-person;
  }
}
