.spinner__loader {
  position: relative;

  width: 32px;
  height: 32px;
  margin: 10px auto 10px;

  font-size: 10px;
  text-indent: -9999em;

  border-radius: 50%;
  background: color-for('spinner-background-1', $theme-colors);
  background: linear-gradient(to right, color-for('spinner-background-1', $theme-colors) 10%, rgba(color-for('spinner-background-2', $theme-colors), 0) 42%);

  &.animate {
    animation: load_spinner_loader_keyframes 0.8s infinite linear;
    transform: translateZ(0);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 50%;

    background: color-for('spinner-background', $theme-colors);
    border-radius: 100% 0 0 0;

    content: '';
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 75%;
    height: 75%;
    margin: auto;

    background: color-for('spinner-after-background', $theme-colors);
    border-radius: 50%;

    content: '';
  }
}

@keyframes load_spinner_loader_keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
